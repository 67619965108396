import React, { Component } from "react";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import axios from "axios";
import '../../editor/css/materialicon.css';
import ServiceURL from '../../../../constants/constants';
import $ from 'jquery';
import editorjs from '../js/cdtdom';
import Comments from '../js/comments';
import Attachments from '../js/attachments';

class Anodes extends Component {	
	
	constructor(props) {
		super(props);
		this.state = {
			Heading: this.props.nodename,
			showTypeahead: false,
			nodes:this.props.subnodes === '0' ? [] : this.props.subnodes,
			users: this.props.users ? this.props.users.map((item, i) => {
				return item.email
			}) : [],
			childsDisplayed:this.props.childsDisplayed?this.props.childsDisplayed:1,
			contributors: [],
			nname:'',
			ncontent:'',
			username: 'guest',
			nrole:0,
			userid:'0',
			comments:[],
			attachmentsCount:0,
			replyComment:0,
			replyorComment:"comment",
			commentscounter:0,
		}
		this.counter = 1;
		var self = this;
		$(function() {
			$(".node-name[data-id='" + self.props.nodeid + "']").html(self.props.nodename);
			$(".node-description[data-id='" + self.props.nodeid + "']").html(self.props.ncontent);
		});
		this.previd =0;
	}
	
	handleHeading = (event, name) => {
		if (event.type !== 'click') {
			if (event.keyCode === 27 || event.key === "Escape") {
				event.target.innerText = this.props.nodename === "" ? "Edit this Heading" : this.props.nodename;
			}
			this.setState({
				Heading: event.target.innerText
			});
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps){
		if(nextProps.subnodes !== this.props.subnodes){
			this.setState({
				nodes:[]
			})
			this.setState({
				nodes:nextProps.subnodes
			})
		}
		if(nextProps.contributors !== this.props.contributors){
			this.setState({
				contributors:[]
			})
			this.setState({
				contributors:nextProps.contributors
			})
		}
	}

	componentDidMount = () => {

		if(this.state.nodes.length === 0){
			if( !isNaN(parseInt(this.props.nodeid))){
				var CDTURL = ServiceURL + "nodes_api/subNodesAll"; 
				var self = this;
				var nodes ={
					parent:this.props.nodeid,
					userkey:this.props.userkey
				}
				axios.post(CDTURL, nodes).then(response => {
					if(response.data.nodes!=='0'){
						self.setState({
							nodes:response.data.nodes
						})
						self.props.getSubnodesA(self.props.position,response.data.nodes);
					}
				});
			}
			if( !isNaN(parseInt(this.props.nodeid))){			
				CDTURL = ServiceURL + "N_rights_api/node_user_roles?nodeid="+this.props.nodeid;
				axios.get(CDTURL, nodes).then(response => {
					if(response.data.status === false || response.data.status === "false"){

						//console.log("comes here")
					}
					else{
						self.setState({ contributors: response.data.response});
					}
				});
			}
		}
		nodes ={
					node_id:this.props.nodeid,
					userkey:this.props.userkey
				}
		CDTURL = ServiceURL + "content_api/getAssignedNodeContent";
		
		axios.post(CDTURL, nodes).then(response => {
			if(response.data.nodes!== null){
				if(response.data.nodes.role_id!== undefined){
					this.setState({
						nrole:parseInt(response.data.nodes.role_id)
					})				
				}
			}

		});

		nodes={
			nodeid:this.props.nodeid,			
			parent:this.props.parent
		}
		
		
		var nodeid = this.props.nodeid
		$(function(){
			$(".commentsArea[data-id='"+nodeid +"']").hide();
    		$(".column2Area[data-id='"+nodeid +"']").hide();
		})
    		$(".node-info").hide();


/*
		document.addEventListener('keypress', this.props.keyevent);
        editorjs.editableEventsByClassSet(editorjs.userNameClass);
        editorjs.editableEventsByClassSet(editorjs.nodeNameClass);
        editorjs.editableEventsByClassSet(editorjs.nodeDescriptionClass);
		editorjs.foldChildsEventsByClassSet(editorjs.nodeFolderClass);
		editorjs.foldDescriptionEventsByClassSet(editorjs.nodeStateClass);
		editorjs.nodeControlEventsByClassSet(editorjs.nodeControlClass);
		editorjs.nodeMenuEventsByClassSet(editorjs.nodeMenuClass);*/
    }

    toggleDisplay = () => {
    	var newVisible = this.state.childsDisplayed ==="1"?"0":"1";
    	var	nodes = {
    		nodeid:this.props.nodeid,
    		childsDisplayed:newVisible
    	}

    	this.setState({
    		childsDisplayed:newVisible
    	})
    	var CDTURL = ServiceURL + "nodes_api/nodeDisplay";
    	axios.post(CDTURL, nodes);

    }
    componentDidUpdate = () => {
    	document.addEventListener('keypress', this.props.keyevent);
        editorjs.editableEventsByClassSet(editorjs.userNameClass);
        editorjs.editableEventsByClassSet(editorjs.nodeNameClass);
       // editorjs.editableEventsByClassSet(editorjs.nodeDescriptionClass);
		editorjs.foldChildsEventsByClassSet(editorjs.nodeFolderClass);
		editorjs.foldDescriptionEventsByClassSet(editorjs.nodeStateClass);
		editorjs.nodeControlEventsByClassSet(editorjs.nodeControlClass);
		editorjs.nodeMenuEventsByClassSet(editorjs.nodeMenuClass);
    }

    getSubnodesA = (pos,nodes) => {
    	var allnodes = this.state.nodes;
    	allnodes[pos].subnodes = nodes;
    	this.setState({
    		nodes:allnodes
    	})    	
    }

    keyCheckevent = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            return false;
        } else if (event.keyCode === 38) {
            if (this.props.prevSibling !== "0") {
                $(".node-name[data-prevsibling=" + this.props.prevSibling + "]").focus();
            }

        }
        return true;
    }
    doFocusName = (event) => {
    	var newid = $(event.target).attr("data-id");
		$(function() {
			$(".node-name[data-id=" + newid + "]").focus();
		});
	}


    showSubnodes = ()=>{
    	return (<div>this is subnode</div>);
    }
     countAttachments = (count)=>{
    	this.setState({
    		attachmentsCount:count
    	});
    }

    showAttachements= (event)=>{
    	var nodeid = this.props.nodeid;
    	$(".column2Area[data-id='"+nodeid +"']").toggle();
    }
    showComments= (event)=>{
    	var nodeid = this.props.nodeid;
    	$(".commentsArea[data-id='"+nodeid +"']").toggle();
    }
    countAttachments = (count)=>{
    	this.setState({
    		attachmentsCount:count
    	});
    }
   postComment=(event)=>{
    	var commentid = "#comment" + this.props.nodeid;
    	var content=$(commentid).val();
    	var CDTURL = ServiceURL + "comments_api/addComment";
		var nodes ={
			nodeid:this.props.nodeid,
			userkey:this.props.userkey,
			comment:content,
			parent:this.state.replyComment,
			mode:"anodes"
		}
		var commentscounter = parseInt(this.state.commentscounter) + 1;
		axios.post(CDTURL,nodes).then(response=>{
			this.setState({
				comments:response.data.comments,
				replyComment:0,
				replyorComment:"comment",
				commentscounter : commentscounter + 1
			})
		});
		$(".commentsArea[data-id='"+this.props.nodeid +"']").show();
    	$(commentid).val("");
    }
    getparent=(parent)=>{
    	this.setState({
    		replyComment:parent,
    		replyorComment:"reply"
    	});
    	var commentid = "#comment" + this.props.nodeid;
    	
		$(commentid).focus();
    }
    replyComment=(event)=>{
    	var commentId = $(event.target).attr("data-commentId");
    	this.setState({
    		replyComment:commentId
    	});
    	var commentid = "#comment" + this.props.nodeid;
    	$(commentid).focus();
    }

    makeitComment=(event)=>{
    	this.setState({
    		replyComment:0,
    		replyorComment:"comment"
    	});
    	var commentid = "#comment"+ this.props.nodeid;
    	$(commentid).focus();
    }
    insertImage=(event)=>{
    	var val = $(event.target).attr("data-link");
    	document.execCommand("insertImage", false, val);
    }
    nullFunction = (event) =>{
    	return true;
    }
   createLink=(event)=>{
    	var val = $(event.target).attr("data-link");
    	document.execCommand("createLink", false, val);
    }
     activateIcons = (event) =>{
    	var nodeid = this.props.nodeid;
    	$(".node-info").hide();
    	$(".node-info[data-id='"+ nodeid +"']").show();
    }
    render() {
        return (
			<div className="node" id={this.props.nodeid}>
				<div className="node-info noselect" data-id={this.props.nodeid}>
					<span className={ this.props.subnodes === "0" ? "node-folder empty" : "node-folder"} onClick={this.toggleDisplay}>
						<span className="fold-nodes noselect material-icons">
							{this.props.subnodes === "0" ? "expand_more" : this.state.childsDisplayed === "1" ? "expand_more" : "chevron_right"}
						</span>
					</span>
					<span className="node-id">
						<Link id={"node"+this.props.nodeid} to={generatePath("/home/:id/:entity(nodes|comments)", {id:this.props.nodeid,entity:"nodes"})}>#0.0.0.{this.props.nodeid}</Link>
					</span>
					<span className="involveds">
						{ 
							this.state.contributors.length > 0 
								? this.state.contributors.map((roles,i)=>{
									return(
										<span className="involved" key={i}>
											<span className="involved-role" id={roles.role.id}>{roles.role.name}:</span>
											{
												roles.users.map((user,j)=>{
													return (<span className="involved-user" id={user.id} key={j}>{user.name} { j===roles.users.length - 1?"":";" } </span>)
												})
											}
										</span>
									)})
								: ''
						}
						<span className="involved">
							<span className="involved-add">@</span>
						</span>
					</span>
					<span className="action-link action-icons" data-id={this.props.nodeid} onClick={this.showAttachements} unselectable="on"><i className="material-icons"  unselectable="on">attachment</i>
						<span className="badge">{this.state.attachmentsCount}</span>
					</span>
					<span className="action-link action-icons" data-id={this.props.nodeid} onClick={this.showComments} unselectable="on"><i className="material-icons">insert_comment</i>
					</span>
				</div>
				<div className="node-base">
					<span className="node-control">
						<span className="node-menu noselect material-icons">menu</span>
					</span>
					<span className="node-wrapper">
						<div className="node-header">
							<span className="node-body">
								<div className="node-content">
									<span className="node-number numaric numCounter" data-position={this.props.position} data-childsdisplayed={this.state.childsDisplayed} data-nextsibling={this.props.nextSibling} data-id={this.props.nodeid}  data-prevsibling={this.props.prevSibling} data-parent={this.props.parent} onClick={this.doFocusName}></span>
									<span className="node-data">
										<div className="node-name" contentEditable={true} readOnly={this.state.nrole !== 3 ? true : false } placeholder="Enter node name here ..." data-position={this.props.position} data-childsdisplayed={this.state.childsDisplayed} data-nextsibling={this.props.nextSibling} data-id={this.props.nodeid} data-prevsibling={this.props.prevSibling} onBlur={this.props.savename}  data-parent={this.props.parent} suppressContentEditableWarning={true} spellCheck={true} tabIndex={"10"+this.props.nodeid}>
										</div>
										<div className="node-description" data-childsdisplayed={this.state.childsDisplayed} readOnly={this.state.nrole !== 3?true:false }  contentEditable={true} placeholder="Add node description here ..." data-position={this.props.position} data-nextsibling={this.props.nextSibling} data-id={this.props.nodeid}  data-prevsibling={this.props.prevSibling} onBlur={this.props.savedesc} suppressContentEditableWarning={true} data-parent={this.props.parent} spellCheck={true} tabIndex={"10"+this.props.nodeid} >
										</div>
									</span>
								</div>
								<div className="column2Area" data-id={this.props.nodeid}>
							<div className="row center attachmentHeading"> <Link id={"node"+this.props.nodeid} to={generatePath("/home/:id/:entity(nodes|comments)", {id:this.props.nodeid,entity:"nodes"})}>#0.0.0.{this.props.nodeid} </Link> Attachments </div>
							<Attachments nodeid={this.props.nodeid} userkey={this.props.userkey} createLink={this.createLink} insertImage={this.insertImage} countAttachments={this.countAttachments} /><br />
							
						</div>
						<div className="commentsArea" data-id={this.props.nodeid}>
							<div className="row center commentHeading"> <Link id={"node"+this.props.nodeid} to={generatePath("/home/:id/:entity(nodes|comments)", {id:this.props.nodeid,entity:"nodes"})}>#0.0.0.{this.props.nodeid} </Link> Comments 
							</div>

							<Comments nodeid={this.props.nodeid} getparent={this.getparent} commentscounter={this.state.commentscounter}  parent="0" />
							<div className="row center">
								<div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
									<span className="replyorComment" onClick={this.state.replyorComment==="reply"?this.makeitComment:this.nullFunction}> {this.state.replyorComment==="reply"?"This is reply to comment, if you want to post main comment then please click on this msg":""}  </span>
									<textarea className="form-control inputComment" id={"comment" + this.props.nodeid}  defaultValue="" placeholder="comment few words here" /><br />
								</div>
								<div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
									<button className="btn btn-success btn-lg" onClick={this.postComment}>Post comment </button>
								</div>
							</div>
						</div>
							</span>
							<span className={this.props.ncontent === (""||null) ? "node-state empty" : "node-state"}>
								<span className="fold-description noselect material-icons">{this.props.childsDisplayed === (""||null) ? "more_vert" : "more_horiz"}</span>
							</span>
						</div>
						<div className={ parseInt(this.state.childsDisplayed)=== 1 ? "nodes show" : "nodes hide"}>
						{
						this.state.nodes !=='0' ? this.state.nodes.map((item,i)=>{
							this.previd = i===0?0:this.previd;
							var nextSibling = this.state.nodes[i+1] !== undefined ? this.state.nodes[i+1].id : "0";
							var nodeComp = 
								<Anodes 
									key={item.id}
									position={i}
									id={i}
									nodeid={item.id}
									showcontent={item.showcontent}
									users={this.props.users}
									childsDisplayed={item.childsDisplayed}
									savename={this.props.savename}
									savedesc={this.props.savedesc}
									keycheckContent={this.props.keycheckContent}
									keycheck={this.props.keycheck}
									nextSibling={nextSibling}
									showNode={this.props.showNode}
									getSubnodesA={this.getSubnodesA}
									subnodes={item.subnodes}
									parent={item.parent}
									prevSibling={this.previd}
									contributors= {item.contributors? item.contributors : "No data found"}
									nodename={item.node_name}
									ncontent={item.content}
									userkey={this.props.userkey} />;
							this.previd = item.id;
							return nodeComp;
						}) : ''
					}
					</div>
					</span>
				</div>
			</div>
        );
    }
}
export default Anodes;