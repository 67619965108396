import React,{Component} from "react";
import '../landing/landing-footer.css';

class LandingFooter extends Component{ 
render(){
		return (
			<footer>
				<div id="footer"   className="footer-main">
					<div className="container">
						<div className="textwidget">
							<div className="row">
								<div className="col-md-3 col-sm-3 col-xs-12">
									<h4 className="footer-title">[mn]medianet</h4>
									<div className="footer-text-sty">
										<address>
											<strong>Inh. Margit Hermsen</strong><br/>
												Am Taubhaus 29<br/>
												D 63303 Dreieich<br/><br/>
											<span className="glyphicon glyphicon-phone-alt">&nbsp;</span>: +49 (0) 6103/6977 84<br/><br/>
											<span className="glyphicon glyphicon-print">&nbsp;</span>: +49 (0) 6103/6977 85<br/><br/>
											<span className="glyphicon glyphicon-envelope">&nbsp;</span>: <a href="mailto:info@medianet-home.de" rel="nofollow">info@medianet-home.de</a>
											</address>
										</div>
									</div>
									<div className="col-md-3 col-sm-3 col-xs-12">
										<h4 className="footer-title">[mn] SCLAN</h4>
										<div className="footer-text-sty">High End House Automation - Intelligent Home Control Systems with Multimedia Solutions<br/>&nbsp;
										<ul>
											<li>
												<a href="http://www.sclan.de/controller-module" target="_blank" rel="noopener noreferrer" title="Controller-Baustein UCC">SCLAN Controler UCC</a>
											</li>
											<li>
												<a href="http://www.sclan.de/relais-module-4REL" target="_blank" rel="noopener noreferrer" title="Relais-Baustein 4REL">SCLAN Relay Module 4REL</a>
											</li>
											<li>
												<a href="http://www.sclan.de/dim-module-4DIM" target="_blank" rel="noopener noreferrer" title="Dimmer-Baustein 4DIM">SCLAN Dimmer Module 4DIM</a>
											</li>
											<li>
												<a href="http://www.sclan.de/output-module-4DAC" target="_blank" rel="noopener noreferrer" title="Steuer-Baustein 4DAC">SCLAN Digital Analog Converter 4DAC</a>
											</li>
											<li>
												<a href="http://www.sclan.de/input-module-4UDI" target="_blank" rel="noopener noreferrer" title="Sensor-Baustein 4UDI">SCLAN Digital Interface 4UDI</a>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-md-3 col-sm-3 col-xs-12">
									<h4 className="footer-title">[mn] VOICEPOINT</h4>
									<div className="footer-text-sty">Noticably audible. Experience the Difference.
										<ul>
											<li>
												<a href="http://www.voicepoint.de/vp-160-a" target="_blank" rel="noopener noreferrer" title="VP160 A">Column-Speakers-Design</a>
											</li>
											<li>
												<a href="http://www.voicepoint.de/Cinema-Speaker" target="_blank" rel="noopener noreferrer" title="Cinema Loudspeaker">High-End Theater Speakers</a>
											</li>
											<li>
												<a href="http://www.voicepoint.de/high-end-preamp" target="_blank" rel="noopener noreferrer" title="High-End Preamp mit DSP">High-End Preamp with DSP</a>
											</li>
											<li>
												<a href="http://www.voicepoint.de/Measurement-Software" target="_blank" rel="noopener noreferrer" title="Audio-System">Audio-Measurement-Software </a>
											</li>
											<li>
												<a href="http://www.lounge-speaker.de" target="_blank" rel="noopener noreferrer" title="Lounge speakers">Lounge Speaker</a>
											</li>
											<li><span className="cls-white">MediaNet Audio Pro Solutions</span></li>
										</ul>
									</div>
								</div>
								<div className="col-md-3 col-sm-3 col-xs-12">
									<h4 className="footer-title">[mn] Solutions</h4>
									<div className="footer-text-sty">Software Development. CMS, WebShop, PMS..
										<ul>
											<li>
												<a href="http://www.medianet-solutions.de/en/cms-content-management-system" target="_blank" rel="noopener noreferrer" title="CMS System">[mn] CMS</a>
											</li>
											<li>
												<a href="http://www.medianet-solutions.de/en/multi-web-shop-b2b" target="_blank" rel="noopener noreferrer" title="Web-Shop System from medianet">[mn] Web-Shop</a>
											</li>
											<li>
												<a href="http://www.medianet-solutions.de/en/seo-search-engine-optimisation" target="_blank" rel="noopener noreferrer" title="SEO from medianet">[mn] SEOlysis</a>
											</li>
											<li>
												<a href="http://www.medianet-solutions.de/en/pms-projectmanagement-software" target="_blank" rel="noopener noreferrer" title="[mn] PMS / CRM  Project management made easy">[mn] PMS</a>
											</li>
											<li>
												<a href="http://www.medianet-solutions.de/en/catalog-generator-online" target="_blank" rel="noopener noreferrer" title="Der [mn] Online-catalog Generator">[mn] Catalog-Generator</a>
											</li>
											<li>
												<a href="http://www.medianet-solutions.de/en/databank-qr-code" target="_blank" rel="noopener noreferrer" title="user-friendly database of [mn]">[mn] Databank</a>
											</li>
											<li>
												<a href="http://www.medianet-solutions.de/qr-code-kostenlos-generator/" target="_blank" rel="noopener noreferrer" title="QR-Code Generator">[mn] QR-Code-Generator</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
					<div className="footer-strip">
						<div className="container">
							<div className="row">
								<div className="col-md-12 col-sm-12 col-xs-12">
									<div className="footer-info">
										<a title="[mn]Solutions" href="http://www.medianet-solutions.de">Designed by [mn]Solutions</a>&nbsp;&nbsp;
										<a href="https://www.medianet-home.de/imprint">Impressum</a>&nbsp;|&nbsp;
										<a href="https://www.medianet-home.de/data-privacy">Privacy Policy</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</footer>
		);
	}
}
export default LandingFooter;