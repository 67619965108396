import React, {Component} from "react";
//import { connect } from 'react-redux'
import cookie from 'react-cookies'
import queryString from 'query-string'
import { connect } from 'react-redux'
import axios from "axios";
import {Link} from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import InputMask from 'react-input-mask';
import {loginUser} from '../../../redux'
import {NotificationContainer,NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ServiceURL from '../../../constants/constants';

const mapStateToProps = (state) => {
  return {
     loading : state.user.loading,
     loginKeyGen:state.user.loginKeyGen,
     loginKey:state.user.loginKey,
     loggedUser:state.user.loggedUser,
     loginEmailError:state.user.loginEmailError,
     loginkeyError:state.user.loginkeyError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (user) => dispatch(loginUser(user))
  }
}

class Loginkey extends Component {
  constructor(props) {
    super(props);
    var  parsed = queryString.parse(this.props.location.search);

    this.state = {
      email: this.props.location.state?this.props.location.state:parsed.destiny?parsed.destiny:'',
      key: parsed.fires?parsed.fires:'',
      emailError: true,
      emailErrorMsg: "",
      keyError: true,
      keyErrorMsg: "",
      successmsg: true,

    }
    NotificationManager.success("Please check mail for key", "Mail sent Successfully", 15000);
  }
  componentDidMount = () =>{
    var  parsed = queryString.parse(this.props.location.search);
    if(parsed.fires){
      this.validateLogin();
    }
    
  }
  componentDidUpdate = (prevProps, prevState, snapshot)=> {
    //console.log("hello");
    var useremail = this.state.email;
    var userdetails = useremail.split("@");
    var nickname = userdetails[0];
    var total_name = nickname.split(/[-._]/);
    var name = total_name[0];
    if(total_name[1]){
      name = name+" "+total_name[1];
    }
    //console.log(cookie);
    if(this.props.loginkeyError!==prevProps.loginkeyError&&this.props.loginkeyError!==""){
      this.setState({
        loginkeyError:this.props.loginkeyError
      })
    }
    if(this.props.loggedUser !== undefined && this.props.loggedUser.token !== undefined){
      cookie.save('userAcc', this.props.loggedUser.token, { path: '/' });
      cookie.save('userEmail', this.state.email, { path: '/' });
      cookie.save('Name', name, { path: '/' });
      cookie.save('nickname', nickname, { path: '/' });
      this.renderRedirect();
    }
    else{
      console.log("notoken generated")
    }

  }

  doServiceActions = ()=>{

  }

  responseFacebook = (response) => {
    console.log(response);
  }
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  validateLogin =()=>{
    const user = {
      email: this.state.email,
      key: this.state.key
    };
    let keyValidation = false;
    let emailValid = false;

    if(this.state.email === ""){
      emailValid = false;
    }
    else if(this.state.email === undefined){
      emailValid = false;
    }
    else{
      var emailRegx = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
      emailValid = emailRegx.test(this.state.email);
    }

    if (this.state.key === undefined) {
      keyValidation = false;
    } else {
      keyValidation = (this.state.key.replace("_","").length === 14) ? true : false;
    }
    let fieldValidationErrors = emailValid ? true : false;
    if (fieldValidationErrors === false) {
      this.setState({
        emailError: fieldValidationErrors,
        emailErrorMsg: <span id="invalidEmail" className="cls-danger">Please point your actual email address.</span>
      });
      return false;
    }

    if (keyValidation === false) {
      this.setState({
        keyError: keyValidation,
        keyErrorMsg: <span id="invalidCode" className="cls-danger">Incorrect code. Please check your email box.</span>,
        sendCode:<div id="sendCode"><span className="send-code-text">Lost letter with code?</span><button  type="button" className="button-link wantBtn send-code-link" onClick={this.sendCodeAgain}>Send me code again.</button></div>,
      })
      return false;
    } else {
      this.setState({
        keyError: keyValidation,
        keyErrorMsg: " ",
        sendCode:" ",
      })
    //  this.serviceCall(user);
    this.props.loginUser(user);
    }
  }


  handleSubmit = (event) => {
    event.preventDefault();
    this.validateLogin();
    return false;
  }


  sendCodeAgain = async() =>{
   var self = this;
   var user = {
      email: this.state.email
    };
    await axios.post(ServiceURL+`users_api/loginKeyGen` , { user }).then(function (response) {
      if(response.data.status ==="fail"){
        self.setState({
          emailError:false,
          inputClassName:'form-control input-error',
          emailErrorMsg:<span className="cls-danger"> This email doesn't have account <button onClick={self.gosignup} className='button-link cls-cdt'>want to signup?</button> </span>
        });
      }
      else{
          self.setState({
        sendCode:" ",
        loginkeyError:"",
      });
          self.renderRedirectSame();
      }
    });

  }
renderRedirectSame = () => {
    this.props.history.push(
    {
      pathname:'/loginkey',
      state:this.state.email
    });
  }

  serviceCall = async(user) => {
    var self = this;
    await axios.post(ServiceURL+`users_api/loginKeyVerify`, {user}).then(function(response) {
      console.log(response);
      if (response.data.status === "fail") {
        self.setState({
          emailError: false,
          keyErrorMsg: <span id="invalidCode" className="cls-danger">Incorrect code. Please check your email box.</span>,


        });
      } else {
        self.renderRedirect();
      }
    });
  }
  renderRedirect = () => {
    this.props.history.push({
      pathname: '/home/all/nodes',
      state: this.state.email,
      typeLogin: 'Normal'
    });
  }

  responseGoogle = (googleUser) => {
    var profile = googleUser.getBasicProfile();
    console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
    console.log('Name: ' + profile.getName());
    console.log('Image URL: ' + profile.getImageUrl());
    console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
  }

  createNotification = (type, msg) => {
    return () => {
      switch (type) {
        case 'info':
          NotificationManager.info(msg);
          break;
        case 'success':
          NotificationManager.success(msg, 'Title here');
          break;
        case 'warning':
          NotificationManager.warning(msg, 'Close after 3000ms', 3000);
          break;
        case 'error':
          NotificationManager.error(msg, 'Click me!', 5000, () => {
            alert('callback');
          });
          break;
        default:
          NotificationManager.info(msg);
          break;
      }
    };
  };
  render() {
    return (
      <div>
        <div className="landing-header">
          <div className="pull-left">
            <div id="landing" className="lh-left">[<span>mn</span>] <span className="visible-cdt hidden-xs">Collaborative Documentation Tool</span> <span className="visible-cdt visible-xs">CDT</span>
            </div>
          </div>
          <div className="pull-right">
            <Link to="/signup"  id="signUp" className="btn btn-warning btn-bold"> Sign up </Link>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="container-fluid">
          <div className="login-wrapper">
            <div id="logInPage" className="login-box login-box-shadow">
              <p className="land-big-text"> Log in to <strong>[<span className="cls-cdt">mn</span>]&nbsp;CDT</strong> </p>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <input type="text" name="email" id="email" onChange={this.handleChange} value={this.state.email} className="form-control" placeholder="Enter your email address"/>
                </div>
                <div className="cls-danger land-small-text"> {this.state.emailErrorMsg} </div>
                <div className="form-group">
                  <div className="sub-form-text center">We sent a one-time verification code to your email.<br/> Please type or paste the code below</div>
                    {/*<button id="sendCode" type="button" onClick={this.sendCodeAgain} className="button-link wantBtn">Send me code again.</button>*/}

                </div>
                <div className="form-group">
                    <InputMask id="code" mask="9999-9999-9999" style={{'textAlign':'center'}} maskChar={'_'} placeholder="____-____-____" name="key" value={this.state.key} onChange={this.handleChange}  size='14' className="form-control otp-form" />
                    <div className="cls-danger land-small-text"> {this.state.keyErrorMsg}{this.state.loginkeyError && this.state.loginkeyError!==""?<div><span id="invalidCode" className="cls-danger">Incorrect code. Please check your email box.</span><div id="sendCode"><span className="send-code-text">Lost letter with code?</span><button  type="button" className="button-link wantBtn send-code-link" onClick={this.sendCodeAgain}>Send me code again.</button></div></div>:" "} </div>
                    {this.state.sendCode}
                  </div>
                  <div className="form-group">
                            <button type="submit" id="continue" className="btn btn-block btn-primary">Get Started</button>
                          </div>
                            <div className="form-group">
                            <p className="land-small-text">or continue with</p>
                            <div className="social-btns">
                            <div className="btn-group">
                              <button type="button" onClick={this.facebookButtonAlert} className="btn facebook-btn">
                                <img src="images/facebook_new.png" alt="fb" width="16"/>
                              </button>
                              <button type="button"  id="facebook" className="btn facebook-btn" onClick={this.facebookButtonAlert}>Facebook</button>
                            </div>
                            <div className="btn-group btn-group-margin">
                              <button type="button" onClick={this.googleButtonAlert} className="btn google-btn-G">
                                <img src='images/google_new.png' alt="google" width="16"/>
                              </button>
                              <button type="button"  id="google" onClick={this.googleButtonAlert} className="btn google-btn">Google</button>
                            </div>
                            <div className="btn-group">
                              <button type="button" onClick={this.twitterButtonAlert} className="btn twitter-btn">
                                <img src="images/twitter-logo.png" alt="twitter" width="16"/>
                              </button>
                              <button type="button" id="twitter" onClick={this.twitterButtonAlert} className="btn twitter-btn">Twitter</button>
                            </div>
                          </div>
        </div>
                          <div className="hide">
                          <FacebookLogin appId="1017343528596936" autoLoad={false} fields="name,email,picture" callback={this.responseFacebook}
    cssClass="btn facebook-btn" />
                          </div>
                      </form>
                      <NotificationContainer/>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Loginkey);