import React,{Component} from "react";
import {Link} from 'react-router-dom';
import '../landing/landing-header.css';
class LandingHeader extends Component{
	render(){
		return (
			<div id="landingPage">
				<div className="landing-header header-fixed">
					<div id="logo">
						[<span className="logo-mn">mn</span>]&nbsp;<span className="hidden-xs">Collaborative Documenting Tool</span><span className="visible-xs">CDT</span>
					</div>
					<div> 
						<div className="lr-links">
							<Link to="/pricing" id="pricing">
								<span className="button-link cls-cdt">Pricing</span>
							</Link>
							<Link to="/help" id="help">  
								<span className="button-link cls-cdt">Help & Support</span>
							</Link> 
							<Link  to="/home/all/nodes"> 
								<span className="btn btn-demo" id="tryDemo">TRY DEMO!</span>
							</Link>    
						</div>
					</div>
				</div>
			</div>				
		);
	}
}

export default LandingHeader;