import React, { Component } from "react";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import axios from "axios";
import '../../editor/css/materialicon.css';
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, MenuItem, Form, FormGroup, InputGroup, Popover} from 'react-bootstrap';
*/
import ServiceURL from '../../../../constants/constants';
import editorjs from '../js/cdtdom';
import Comments from '../js/comments';
import Attachments from '../js/attachments';
import Toggle from 'react-bootstrap-toggle';
import $ from 'jquery';
import "../../../../css/bootstrap2-toggle.css";

class Nodes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Heading: this.props.nodename,
			showTypeahead: false,
			nodes:this.props.subnodes === '0' ? [] : this.props.subnodes,
			users: this.props.users ? this.props.users.map((item, i) => {
				return item.email
			}) : [],
			userContents:[],
			childsDisplayed:this.props.childsDisplayed?this.props.childsDisplayed:1,
			contributors: [],
			toggleParticipation:false,
			showNodeInfo: false,
			attachments:[],
			comments:[],
			attachmentsCount:0,
			replyComment:0,
			replyorComment:"comment",
			commentscounter:0,
			notificationSetting:true,
		}
		this.counter = 1;
		var self = this;

		$(function() {
			var ncontent = self.props.ncontent;
			if(ncontent !== null){
				ncontent = ncontent.replace(/&nbsp;/g, '').replace(/&amp;/g, '&').replace(/&gt;/g, '>').replace(/&lt;/g, '<')
			}
			$(".node-name[data-id='" + self.props.nodeid + "']").html(self.props.nodename);
			$(".node-description[data-id='" + self.props.nodeid + "']").html(ncontent);
		});
		this.previd =0;
		this.createnode = this.props.createnode;
		this.Deletenode = this.props.Deletenode;
		this.createsubnode = this.props.createsubnode;
		this.movenodedown = this.props.movenodedown;
		this.movenodeup = this.props.movenodeup;
		/* Component did mount code**/
		if(this.state.nodes.length === 0){
			//var CDTURL, nodes;
			if( !isNaN(parseInt(this.props.nodeid))){
				this.getNodeData();
			}
		}
		var nodeid = this.props.nodeid
		$(function(){
			$(".commentsArea[data-id='"+nodeid +"']").hide();
    		$(".column2Area[data-id='"+nodeid +"']").hide();
    		$(".node-info").hide();
		})
/* End component did mount code**/

	}
	
	handleHeading = (event, name) => {
		if (event.type !== 'click') {
			if (event.keyCode === 27 || event.key === "Escape") {
				event.target.innerText = this.props.nodename === "" ? "Edit this Heading" : this.props.nodename;
			}
			this.setState({
				Heading: event.target.innerText
			});
		}
	}

	getNodeData = () =>{
		var CDTURL = ServiceURL + "nodes_api/getNodeBasics";
		var nodes ={
			nodeid:this.props.nodeid,
			userkey:this.props.userkey
		}
		axios.post(CDTURL, nodes).then(response => {

			var NNstatus = response.data.settingStatus === 1 ? true:false;
			if(response.data.nodes!=='0'){
				this.setState({
					nodes:response.data.nodes,
					contributors:response.data.contributors,
					userContents:response.data.userContents,
					comments:response.data.comments,
					attachments:response.data.attachments.attachments,
					attachmentsCount:response.data.attachments.count,
					notificationSetting:NNstatus
				})
				this.props.getSubnodes(this.props.position,response.data.nodes);
			}
		});
		
    }

	UNSAFE_componentWillReceiveProps(nextProps){
		if(nextProps.subnodes !== this.props.subnodes){
			this.setState({
				nodes:[]
			})
			this.setState({
				nodes:nextProps.subnodes
			})
		}
		if(nextProps.contributors !== this.props.contributors){
			this.setState({
				contributors:[]
			})
			this.setState({
				contributors:nextProps.contributors
			})
		}
	}

/*	componentDidMount = () => {   }*/

    toggleDisplay = () => {
    	var newVisible = this.state.childsDisplayed ==="1"?"0":"1";
    	var	nodes = {
    		nodeid:this.props.nodeid,
    		childsDisplayed:newVisible
    	}
    	this.setState({
    		childsDisplayed:newVisible
    	})
    	var CDTURL = ServiceURL + "nodes_api/nodeDisplay";
    	axios.post(CDTURL, nodes);

    }




    componentDidUpdate = () => {
    	document.addEventListener('keypress', this.props.keyevent);
        editorjs.editableEventsByClassSet(editorjs.userNameClass);
        editorjs.editableEventsByClassSet(editorjs.nodeNameClass);
        editorjs.editableEventsByClassSet(editorjs.nodeDescriptionClass);
		editorjs.foldChildsEventsByClassSet(editorjs.nodeFolderClass);
		editorjs.foldDescriptionEventsByClassSet(editorjs.nodeStateClass);
		editorjs.nodeControlEventsByClassSet(editorjs.nodeControlClass);
		editorjs.nodeMenuEventsByClassSet(editorjs.nodeMenuClass);
	}

    toggleParticipation = () => {
    	this.setState({
    		toggleParticipation:!this.state.toggleParticipation
    	})
    }
    getSubnodes = (pos,nodes) => {
    	var allnodes = this.state.nodes;
    	allnodes[pos].subnodes = nodes;
    	this.setState({
    		nodes:allnodes
    	})    	
    }

    keyCheckevent = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            return false;
        } else if (event.keyCode === 38) {
            if (this.props.prevSibling !== "0") {
                $(".node-name[data-prevsibling=" + this.props.prevSibling + "]").focus();
            }

        }
        return true;
    }
    doFocusName = (event) => {
    	var newid = $(event.target).attr("data-id");
		$(function() {
			$(".node-name[data-id=" + newid + "]").focus();
		});
	}


    showSubnodes = ()=>{
    	return (<div>this is subnode</div>);
    }

    replaceContent = (event)=>{
    	var self= this;
    	var userid = $(event.target).attr("data-userid");
    	var nodeid = $(event.target).attr("data-nodeid");
    	var nodename = $(".user-node-name[data-nodeid='"+ nodeid +"'][data-userid='"+ userid +"']").text();    	
    	var nodedescription = $(".user-node-description[data-nodeid='"+ nodeid +"'][data-userid='"+ userid +"']").html();
    	$(function(){
    		$(".node-name[data-id='" + self.props.nodeid + "']").html(nodename);
    		$(".node-name[data-id='" + self.props.nodeid + "']").focus();
    		$(".node-name[data-id='" + self.props.nodeid + "']").blur();
    		$(".node-description[data-id='" + self.props.nodeid + "']").html(nodedescription);
    		$(".node-description[data-id='" + self.props.nodeid + "']").focus();
    		$(".node-description[data-id='" + self.props.nodeid + "']").blur();
    	})
    }

    activateNode = (event)=>{
    	var nodeid = $(event.target).attr("data-id");
    	$(".node-infos").hide();
    	$(".node-infos[data-id='"+nodeid +"']").show();
    }
    showAttachements= (event)=>{
    	var nodeid = this.props.nodeid;
    	$(".column2Area[data-id='"+nodeid +"']").toggle();
    }
    showComments= (event)=>{
    	var nodeid = this.props.nodeid;
    	$(".commentsArea[data-id='"+nodeid +"']").toggle();
    }

    countAttachments = (count)=>{
    	this.setState({
    		attachmentsCount:count
    	});
    }
    
    postComment=(event)=>{

    	var commentid = "#comment" + this.props.nodeid;
    	var content=$(commentid).val();
    	var CDTURL = ServiceURL + "comments_api/addComment";
		var nodes ={
			nodeid:this.props.nodeid,
			userkey:this.props.userkey,
			comment:content,
			parent:this.state.replyComment,
			mode:"nodes"
		}
		var commentscounter = parseInt(this.state.commentscounter) + 1;
		axios.post(CDTURL,nodes).then(response=>{
			this.setState({
				comments:response.data.comments,
				replyComment:0,
				replyorComment:"comment",
				commentscounter: commentscounter
			})
			$(".commentsArea[data-id='"+this.props.nodeid +"']").show();

		});
    	this.state.replyComment === 0 ? this.createNotification(this.props.userkey,this.props.nodeid,8,'',content) : this.createNotification(this.props.userkey,this.props.nodeid,9,'',content)

    	$(commentid).val("");
    }
    getparent=(parent)=>{
    	var commentid = "#comment"+ this.props.nodeid;
    	this.setState({
    		replyComment:parent,
    		replyorComment:"reply"
    	});
		$(commentid).focus();
    }
    replyComment=(event)=>{
    	var commentId = $(event.target).attr("data-commentId");
    	this.setState({
    		replyComment:commentId
    	});
    	var commentid = "#comment"+ this.props.nodeid;
    	$(commentid).focus();
    }
    createLink=(event)=>{
    	var val = $(event.target).attr("data-link");
    	document.execCommand("createLink", false, val);
    }
    makeitComment=(event)=>{
    	this.setState({
    		replyComment:0,
    		replyorComment:"comment"
    	});
    	var commentid = "#comment"+ this.props.nodeid;
    	$(commentid).focus();
    }
    insertImage=(event)=>{

    	var val = $(event.target).attr("data-link");
    	document.execCommand("insertImage", false, val);
    }
    nullFunction = (event) =>{
    	return true;
    }
    activateIcons = (event) =>{
    	var nodeid = this.props.nodeid;
    	$(".node-info").hide();
    	$(".node-info[data-id='"+ nodeid +"']").show();
    }
    createNotification = (userkey,nodeid,notificationType,file="",commentTxt="") =>{
		
		var nodes = {
			userkey : userkey,
			nodeid :nodeid,
			type: notificationType,
			filename: file,
			comment:commentTxt
		}

		var CDTURL = ServiceURL + "notifications_api/createNotification";
		axios.post(CDTURL, nodes).then(response => {
			this.setState({
				notifications: response.data.notifications
			})
		});

	}
	toggleNNsettings = ()=>{
		var nodes ={
			nodeid:this.props.nodeid,
			userkey:this.props.userkey,
			status: !this.state.notificationSetting
		}
			this.setState({
				notificationSetting: !this.state.notificationSetting,
				
			})
			
		var CDTURL = ServiceURL + "notifications_api/setnusettings";
		axios.post(CDTURL, nodes).then(response => {
			
		});

	}

    render() {
        return (
			<div className="node" id={this.props.nodeid} onFocus={this.activateNode}>
				<div className="node-info noselect" data-id={this.props.nodeid}>
					<span className={ this.props.subnodes === "0" ? "node-folder empty" : "node-folder"} onClick={this.toggleDisplay}>
						<span className={this.props.subnodes === "0"?"fold-nodes noselect material-icons empty":"fold-nodes material-icons"}>
							{this.props.subnodes === "0" ? "expand_more" : this.state.childsDisplayed === "1" ? "expand_more" : "chevron_right"}
						</span>
					</span>
					<span className="node-id">
					{ 
						this.props.nodeid !=="N0"?
						<Link id={"node"+this.props.nodeid} to={generatePath("/home/:id/:entity(nodes|comments)", {id:this.props.nodeid,entity:"nodes"})}>#0.0.0.{this.props.nodeid} </Link> : "#0.0.0.0 "
					}						
					</span>
					&nbsp;
					<div className="node-info" data-id={this.props.nodeid}>
						<span className="involveds">
							{ 
								this.state.contributors.length > 0 
									? this.state.contributors.map((roles,i)=>{
										return(
											<span className="involved" key={i}>
												<span className="involved-role" id={roles.role.id} >{roles.role.name}:</span>
												{
													roles.users.map((user,j)=>{
														return (<span className="involved-user" id={user.id} key={j}>{user.name};</span>)
													})
												}
											</span>
										)})
									: ''
							}
							<span className="involved">
								<span className="involved-add" data-id={this.props.nodeid} onClick={this.props.openuserPopup}>@</span>
							</span>
						</span>
					</div>
					<span className={ parseInt(this.props.parent) !== 0  ? "action-link action-icons":"  action-icons hide"}> <i className="material-icons" onClick={this.movenodeup} data-position={this.props.position} data-childsdisplayed={this.state.childsDisplayed} data-nextsibling={this.props.nextSibling} data-id={this.props.nodeid}  data-prevsibling={this.props.prevSibling} data-parent={this.props.parent} title="Move to parent level">format_indent_decrease</i>
					</span>
					<span className="action-link  action-icons"><i className="material-icons"  onClick={this.props.createnode} data-position={this.props.position} data-childsdisplayed={this.state.childsDisplayed} data-nextsibling={this.props.nextSibling} data-id={this.props.nodeid}  data-prevsibling={this.props.prevSibling} data-parent={this.props.parent}  title="Add Node">add_circle_outline</i>
					</span>
					<span className="action-link action-icons" title="Add sub Node">
						<img src="images/subnode.svg" hight="24" width="24" alt="subnodes" className="material-icons"  onClick={this.props.createsubnode} data-position={this.props.position} data-childsdisplayed={this.state.childsDisplayed} data-nextsibling={this.props.nextSibling} data-id={this.props.nodeid}  data-prevsibling={this.props.prevSibling} data-parent={this.props.parent} />
					</span>
					<span className={ parseInt(this.props.position) !== 0 ? "action-link  action-icons":"action-icons hide"} >
						<i className="material-icons" onClick={this.movenodedown} data-position={this.props.position} data-childsdisplayed={this.state.childsDisplayed} data-nextsibling={this.props.nextSibling} data-id={this.props.nodeid}  data-prevsibling={this.props.prevSibling} data-parent={this.props.parent} title="Move to sub level">format_indent_increase</i>
					</span>
					<span className="action-link  action-icons" data-id={this.props.nodeid} onClick={this.showAttachements} unselectable="on"><i className="material-icons"  unselectable="on">attachment</i>
						<span className="badge">{this.state.attachmentsCount}</span>
					</span>

					<span className="action-link action-icons" data-id={this.props.nodeid} onClick={this.showComments} unselectable="on"><i className="material-icons">insert_comment</i></span>
					{/*<span className="action-link action-icons" data-id={this.props.nodeid} onClick={this.showNotifications} unselectable="on"><i className="material-icons">notifications_active</i> </span>*/}
					<div> <Toggle on={"Notifications on"} off={"Notifications off"}  onClick={this.toggleNNsettings} size="xs" recalculateOnResize={true} offstyle="info" active={this.state.notificationSetting} /></div> &nbsp; &nbsp; &nbsp; &nbsp;
					<div>
						<span className="btn btn-info btn-xs"  onClick={this.Deletenode}  data-position={this.props.position} data-childsdisplayed={this.state.childsDisplayed} data-nextsibling={this.props.nextSibling} data-id={this.props.nodeid}  data-prevsibling={this.props.prevSibling} data-parent={this.props.parent}  title="Delete this node"> Delete this Node</span>
					</div>

				</div>
				<div className="node-base">
					<span className="node-control">
						<span className={this.props.nodeid!=="N0"?"node-menu noselect material-icons":"node-menu noselect material-icons hide"}>menu</span>
					</span>
					<span className="node-wrapper">
						<div className="node-header">
							<span className="node-body">
								<div className="node-content">
									<span className="node-number numaric numCounter" data-position={this.props.position} data-childsdisplayed={this.state.childsDisplayed} data-nextsibling={this.props.nextSibling} data-id={this.props.nodeid}  data-prevsibling={this.props.prevSibling} data-parent={this.props.parent} onClick={this.doFocusName}></span>
									<span className="node-data">
										<div className="node-name" contentEditable={true} placeholder="Enter node name here ..." data-position={this.props.position} data-childsdisplayed={this.state.childsDisplayed} data-nextsibling={this.props.nextSibling} data-id={this.props.nodeid} data-prevsibling={this.props.prevSibling} data-parent={this.props.parent} suppressContentEditableWarning={true} onKeyDown={this.props.keycheck} onFocus={this.activateIcons} onBlur={this.props.savename} spellCheck={true} tabIndex={"10"+this.props.nodeid}>
										</div>

										<div className="node-description" id={"node-desc"+this.props.nodeid} data-childsdisplayed={this.state.childsDisplayed} contentEditable={true} placeholder="Add node description here ..." data-position={this.props.position} data-nextsibling={this.props.nextSibling} data-id={this.props.nodeid}  data-prevsibling={this.props.prevSibling} suppressContentEditableWarning={true} data-parent={this.props.parent} onKeyDown={this.props.keycheckContent} onFocus={this.activateIcons} onBlur={this.props.savedesc} spellCheck={true} tabIndex={"10"+this.props.nodeid} ></div>

									</span>
								</div>
								<div className={this.state.contributors.length > 0?"contributors":"contributors hide"}> <span className="toggleParticipation" onClick={this.toggleParticipation}> Click here to {this.state.toggleParticipation?"hide":"show"} user participation </span>
								{ 
									this.state.userContents.length > 0 ? this.state.userContents.map((item,i) =>{
										var userDesc= 
									<div className={this.state.toggleParticipation?"node-users-content":"node-users-content hide"} key={i}>
										<div  className="node-user-info noselect"> 
										@{item.username} contribution
										</div>
										<div className={"user-node-content content" + (i+1) }>
											<div className="user-node-name" data-userid={item.user_id} data-nodeid={item.node_id}>
											{item.name}
											</div>
											<div className="user-node-description"  data-nodeid={item.node_id} data-userid={item.user_id}>										
											</div>
										</div>
										<div className={parseInt(item.role_id) ===3? "action-link  action-icons" : "action-link  action-icons hide "} data-userid={item.user_id}  data-nodeid={item.node_id} onClick={this.replaceContent}> Click here to Replace node with {item.username}'s content </div>
									</div>;
									$(".user-node-description[data-userid='"+ item.user_id +"']").html(" " + item.content);
									return userDesc;
								}) : <div className="node-users-content">No user Participation available </div>
								}
									</div>
							</span>
							
							<span className={this.props.ncontent === (""|| null) ? "node-state empty" : "node-state"}>
								<span className="fold-description noselect material-icons">{this.props.childsDisplayed === 1 ? "more_horiz" : "more_vert"}</span>
							</span>
						</div>				
						
						<div className="column2Area" data-id={this.props.nodeid}>
							<div className="row center attachmentHeading"> <Link id={"node"+this.props.nodeid} to={generatePath("/home/:id/:entity(nodes|comments)", {id:this.props.nodeid,entity:"nodes"})}>#0.0.0.{this.props.nodeid} </Link> Attachments </div>
							<Attachments nodeid={this.props.nodeid} createNotification={this.createNotification} userkey={this.props.userkey} attachmentsCount={this.state.attachmentsCount} createLink={this.createLink} insertImage={this.insertImage} countAttachments={this.countAttachments} /> <br />						
						</div>
						<div className="commentsArea" data-id={this.props.nodeid}>
							<div className="row center commentHeading"> <Link id={"node"+this.props.nodeid} to={generatePath("/home/:id/:entity(nodes|comments)", {id:this.props.nodeid,entity:"nodes"})}>#0.0.0.{this.props.nodeid} </Link> Comments 
							</div>

							<Comments nodeid={this.props.nodeid} getparent={this.getparent} commentscounter={this.state.commentscounter} comments={this.state.comments} parent="0" />

							<div className="row center">
								<div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
									<span className="replyorComment" onClick={this.state.replyorComment==="reply"?this.makeitComment:this.nullFunction}> {this.state.replyorComment==="reply"?"This is reply to comment, if you want to post main comment then please click on this msg":""}  </span>
									<textarea className="form-control inputComment" id={"comment"+this.props.nodeid} defaultValue="" placeholder="comment here" /><br />
								</div>
								<div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
									<button className="btn btn-success btn-lg" onClick={this.postComment}>Post comment </button>
								</div>
							</div>
						</div>
						<div className={ (parseInt(this.state.childsDisplayed)=== 1 && this.state.nodes.length !==0) ? "nodes show" : "nodes hide"}>
						{
						this.state.nodes !=='0' ? this.state.nodes.map((item,i)=>{
							this.previd = i===0?0:this.previd;
							var nextSibling = this.state.nodes[i+1] !== undefined ? this.state.nodes[i+1].id : "0";
							var nodeComp = 
								<Nodes 
									key={item.id}
									position={i}
									id={i}
									nodeid={item.id}
									showcontent={item.showcontent}
									users={this.props.users}
									childsDisplayed={item.childsDisplayed}
									savename={this.props.savename}
									savedesc={this.props.savedesc}
									keycheckContent={this.props.keycheckContent}
									keycheck={this.props.keycheck}
									nextSibling={nextSibling}
									showNode={this.props.showNode}
									getSubnodes={this.getSubnodes}
									subnodes={item.subnodes}
									parent={item.parent}
									prevSibling={this.previd}
									contributors= {item.contributors? item.contributors : "No data found"}
									nodename={item.node_name}
									ncontent={item.content}
									userkey={this.props.userkey}
									createnode = {this.createnode}
									Deletenode = {this.Deletenode}
									createsubnode = {this.createsubnode}
									movenodedown = {this.movenodedown}
									movenodeup = {this.movenodeup}
									 />;
							this.previd = item.id;
							return nodeComp;
						}) : ''
					}
					</div>
					</span>
				</div>
			</div>
        );
    }
}
export default Nodes;