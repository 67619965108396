import {
	LOGIN_KEY_REQUEST,
	LOGIN_KEY_SUCCESS,
	LOGIN_KEY_FAIL,
	LOGIN_USER_REQUEST,
	LOGIN_USER_SUCCESS,
	LOGIN_USER_FAIL,
	SIGNUP_KEY_REQUEST,
	SIGNUP_KEY_SUCCESS,
	SIGNUP_KEY_FAIL,
	SIGNUP_USER_REQUEST,
	SIGNUP_USER_SUCCESS,
	SIGNUP_USER_FAIL,
	LOGOUT,
}  from './userTypes'

const initialState = {
	loading : false,
	isLoggedIn: false,
	loginKeyGen: false,
	loginKey:'',
	loggedUser:[],
	loginEmailError:'',
	loginkeyError:'',
	signUpKeyGen: false,
	signUpkey:'',
	signUpEmailError:'',
	signUpkeyError:''
}

const userReducer = (state = initialState,action) =>{
	switch(action.type){
		case LOGIN_KEY_REQUEST:
			return{
				...state,
				loading:true,
				loginKey: '',
				loginEmailError:'',
			}
		case LOGIN_KEY_SUCCESS:
			return{
				loading:false,
				loginKeyGen: true,
				loginKey: action.payload,
				loginEmailError:'',
			}
		case LOGIN_KEY_FAIL:
			return{
				loading:false,
				loginKeyGen: false,
				loginKey:'',
				loginEmailError:action.payload,
			}
		case LOGIN_USER_REQUEST:
			return{
				...state,
				loading:true,
			}
		case LOGIN_USER_SUCCESS:
			return{
				loading:false,
				isLoggedIn:true,
				loggedUser: action.payload,
				loginkeyError:'',
			}
		case LOGIN_USER_FAIL:
			return{
				loading:false,
				isLoggedIn:false,
				loginkeyError:action.payload,
			}
		case SIGNUP_KEY_REQUEST:
			return{
				...state,
				loading:true,
			}
		case SIGNUP_KEY_SUCCESS:
			return{
				loading:false,
				signUpKeyGen: true,
				signUpKey: action.payload,
				signUpEmailError:'',
			}
		case SIGNUP_KEY_FAIL:
			return{
				loading:false,
				signUpKeyGen: false,
				signUpKey:'',
				signUpEmailError:action.payload,
			}
		case SIGNUP_USER_REQUEST:
			return{
				...state,
				loading:true,
			}
		case SIGNUP_USER_SUCCESS:
			return{
				loading:false,
				isLoggedIn:true,
				loginUser: action.payload,
				signUpkeyError:'',
			}
		case SIGNUP_USER_FAIL:
			return{
				loading:false,
				isLoggedIn:false,
				signUpkeyError:action.payload,
			}
		case LOGOUT:
			return{
				isLoggedIn:true,
				loggedUser:action.payload,
			}
		default: return state
	}
}

export default userReducer