import React,{Component} from "react";
//import cookie from 'react-cookies'
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import axios from "axios";
import { Link} from "react-router-dom";
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import {loginKeyGenAction} from '../../../redux';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from 'react-spinners/RingLoader';
import '../landing/landing-header.css';
import '../landing/landing-footer.css';
import '../landing/landingstyle.css';
import ServiceURL from '../../../constants/constants';

const mapStateToProps = (state) => {
  return {
     loading : state.user.loading,
     loginKeyGen:state.user.loginKeyGen,
     loginKey:state.user.loginKey,
     loginEmailError:state.user.loginEmailError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loginKeyGenAction: (user) => dispatch(loginKeyGenAction(user))
  }
}


class Login extends Component{
	constructor(props){
		super(props);
		this.state = {
			email: this.props.location.state?this.props.location.state:'',
			emailError:'',
			emailErrorMsg:'',
			inputClassName:'form-control',
		}
		console.log(props);
	}


	componentDidUpdate = (prevProps, prevState, snapshot)=> {
		if((this.state.email !== prevState.email) || this.state.email ==="" || this.state.emailError === false){
			console.log("return true");
			return true;
		}
		else{
			console.log(this.props.loginKey);
			this.doServiceActions();
		}

		console.log("this.props.loginKey")
		console.log(this.props.loginKey);
	}
	componentDidMount(){
    document.title = "CDT - Log in"
  }
	componentWillMount = ()=>{
		if(cookie.load('userAcc')){
			this.props.history.push({
				pathname: '/home/all/nodes',
				state: this.state.email,
				typeLogin: 'Normal'
			});
		}

	}
	doServiceActions = ()=>{
		var self = this;
		if(this.props.loginKey === ""){
			console.log("empty message")
		}
		else if(this.props.loginKey.status === "fail"){
			if(this.state.inputClassName ==="form-control"){
				this.setState({
					inputClassName:'form-control input-error'
				});
			}

			if(this.state.emailErrorMsg === ""){
				this.setState({
					emailErrorMsg:<div><span id="notRegisteredEmail" className="cls-danger"> This email doesn't have an account. </span><button onClick={self.gosignup} id="wantSignup" className='button-link cls-cdt'>Want to sign up?</button> </div>
				});
			}
		}
		else if(this.props.loginKey.status=== "success"){
			this.renderRedirect();
		}
		else{
			return true;
		}

	}
	renderRedirect = (path,state) => {
		this.props.history.push(
		{
			pathname:'/loginkey',
			state:this.state.email
		});
	}

	handleChange = event => {
		this.setState({
			[event.target.name]:event.target.value
		});
	}
	handleSubmit = (event)=> {
		console.log("handleSubmit() :'" + this.state.email +"'");
		console.log(this.state);
		event.preventDefault();
		var date = new Date();
		var timestamp = date.getFullYear() + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + (date.getDate() < 10 ? '0' : '') + date.getDate() + (date.getHours() < 10 ? '0' : '') + date.getHours() + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds() + Math.ceil(Math.random() * 1000);


		const user = {
			email: this.state.email,
			timekey: timestamp
		};


		let emailValid;

		if(this.state.email === ""){
			emailValid = false;
		}
		else if(this.state.email === undefined){
			emailValid = false;
		}
		else{
			var emailRegx = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
			emailValid = emailRegx.test(this.state.email);
		}

		let fieldValidationErrors = (emailValid) ? true : false;

		if(fieldValidationErrors===false){
			this.setState({
				emailError: false,
				emailErrorMsg: <span id="invalidEmail" class="cls-danger">Please point your actual email address.</span>,
				inputClassName:'form-control input-error'
			})
		}
		else
		{
			this.setState({
				emailError: true,
				emailErrorMsg: '',
				inputClassName:'form-control'
			})

			console.log("here in else");
			this.props.loginKeyGenAction({user});
		}
		return false;
	}
	gosignup = ()=>{
		var self = this;
		var date = new Date();
		var timestamp = date.getFullYear() + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + (date.getDate() < 10 ? '0' : '') + date.getDate() + (date.getHours() < 10 ? '0' : '') + date.getHours() + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds() + Math.ceil(Math.random() * 1000);


		const user = {
			email: this.state.email,
			timekey: timestamp
		};
		axios.post(ServiceURL+`users_api/genKey`, { user }).then(function(response){

		self.props.history.push(
		{
			pathname:'/signupkey',
			state:self.state.email
		});
		});
	}
	googleServiceCall = async(cdtURL,user,optiondirect=false)=>{
		var self = this;
		await axios.post(cdtURL , { user }).then(function (response) {
			if(response.data.status ==="fail"){
				self.setState({
					emailError:false,
					emailErrorMsg:<span  id="notRegisteredEmail" className="cls-danger"> This email doesn't have account <button onClick={self.gosignup} id="wantSignup" className='button-link cls-cdt'>want to signup?</button> </span>
				});
			}
			else{
				if(optiondirect===true){
					self.renderRedirect();
				}
			}
		});
	}

	responseFacebook = (response) => {
		console.log(response);
		this.props.history.push(
		{
			pathname:'/header',
			state:response.email,
			typeLogin:'facebook',
			username:response.name
		});
	}
	responseGoogleFail = (response) =>{
		console.log(response);
	}
	responseGoogle = (response) =>{
		const user = {
			typelogin : 'google',
			email : response.profileObj.email,
			data : JSON.stringify(response)
		}
		var cdtURL = ServiceURL+`users_api/socialLogin`;
		if(this.googleServiceCall(cdtURL,user,false))
		{
			this.props.history.push(
			{
				pathname:'/header',
				state:response.profileObj.email,
				typeLogin:'Google',
			});
		}
	}
	landingRedirect =()=>{
    this.props.history.push({
      pathname:'/landing'
    })
  }
	facebookButtonAlert = ()=>{
		alert("this functionality will come soon");
	}
	googleButtonAlert = ()=>{
		alert("this functionality will come soon");
	}
	twitterButtonAlert = ()=>{
		alert("this functionality will come soon");
	}
	render(){
		return(
			<div id="logInPage">
				<LoadingOverlay active={this.props.loading} spinner={<RingLoader color={'#ff0000'} size="150" />}>
				<div className="landing-header">
					<div id="logo" onClick={this.landingRedirect}>
						<div id="logo">[<span className="logo-mn">mn</span>] &nbsp;<span className="visible-cdt hidden-xs">Collaborative Documenting Tool</span>
						<span className="visible-cdt visible-xs">CDT</span>
						</div>
					</div>
					<div className="button">
						<Link to="/signup" id="signUp" className="btn btn-warning btn-bold"> Sign up </Link>
					</div>
				</div>
				<div className="container-fluid">
					<div className="login-wrapper">
						<div id="logInPage" className="login-box login-box-shadow">
							<p className="land-big-text">Log in to <strong>[<span className="cls-cdt">mn</span>]&nbsp;CDT</strong></p>
							<form onSubmit={this.handleSubmit}>
								<div className="form-group">
									<input id="email" type="text" name="email" onChange={this.handleChange} value={this.state.email} className={this.state.inputClassName} placeholder="Enter your email address"/>
									{this.state.emailErrorMsg}
								</div>
								<div className="form-group">
									<button type="submit" id="continue" className="btn btn-block btn-primary btn-textlc">Continue with email</button>
								</div>
								<div className="form-group">
									<p className="land-small-text">or continue with</p>
									<div className="social-btns">
								<div id="facebook" className="btn-group">
									<button type="button" onClick={this.facebookButtonAlert} className="btn facebook-btn">
										<img src="images/facebook_new.png" alt="fb" width="16"/>
									</button>
									<button type="button" className="btn facebook-btn" onClick={this.facebookButtonAlert}>Facebook</button>
								</div>
								<div id="google" className="btn-group btn-group-margin">
									<button type="button" onClick={this.googleButtonAlert} className="btn google-btn-G">
										<img src='images/google_new.png' alt="google" width="16"/>
									</button>
									<button type="button" onClick={this.googleButtonAlert} className="btn google-btn">Google</button>
								</div>
								<div id="twitter" className="btn-group">
									<button type="button" onClick={this.twitterButtonAlert} className="btn twitter-btn">
										<img src="images/twitter-logo.png" alt="twitter" width="16"/>
									</button>
									<button type="button" onClick={this.twitterButtonAlert} className="btn twitter-btn">Twitter</button>
								</div>
							</div>
								</div>
								<div className="hide">
									<FacebookLogin appId="1017343528596936" autoLoad={false} fields="name,email,picture" callback={this.responseFacebook} cssClass="btn facebook-btn" />
									<GoogleLogin clientId="736285747293-7d5djq1brd919bbfemqo4ojgho42rq8o.apps.googleusercontent.com" render={renderProps => ( <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn google-btn">Login with google</button>    )} onSuccess={this.responseGoogle}  onFailure={this.responseGoogleFail} cookiePolicy={'single_host_origin'} />
								</div>
							</form>
						</div>
					</div>
				</div>
				</LoadingOverlay>
			</div>
			)
		}
	}
export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);