import React,{Component} from "react";
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import '../src/notFound.css';

class NotFound extends Component{
	constructor(props) {
		super(props);
		this.state={
			isLogged:cookie.load('userAcc') ? true : false,
		}
	}
	componentDidMount(){
		document.title = "CDT - Page not found (404)"
	}
	render(){
		return(
			<div id="page404">
				<div className="landing-header">
					<Link id="logo" to="/landing">
						[<span className="logo-mn">mn</span>]&nbsp;<span className="hidden-xs">Collaborative Documentation Tool</span><span className="visible-xs">CDT</span>
					</Link>
					<div className="buttons">
						{
							this.state.isLogged ? 
							""
							:
							<Link to="/login">
								<button id="logIn" className="btn">Log in</button>
							</Link>
						}
						{
							this.state.isLogged ?
							""
							:
							<Link to="/signUp">
								<button id="signUp" className="btn">Sign up</button>
							</Link>
						}
						<Link to="/landing">
							<button id="back" className="btn">BACK</button>
						</Link>	
					</div>
				</div>
				<div id="text-center">
					<div className="content">
						<span className="error-message">
							Oops! The page you're looking for doesn't exist.
						</span>
						<div id="helpLine">
							Go to <Link to="/" id="home">home page</Link> or contact our <Link to="/help" id="support">support</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default NotFound;
