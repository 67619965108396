import React, { Component } from "react";
/*import { Link } from "react-router-dom";
import { generatePath } from "react-router";*/
import axios from "axios";
import '../../editor/css/materialicon.css';
import ServiceURL from '../../../../constants/constants';
import $ from 'jquery';

class Comments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			comments:this.props.comments,
			replyComment:0,
			commentscounter:0
		}
		//this.createNotification=this.createNotification;
		//this.props.getparent = this.props.getparent;
	}
	
	componentDidMount = () => {
		/*var nodes={
			nodeid:this.props.nodeid,			
			parent:this.props.parent
		}
		var CDTURL = ServiceURL + "comments_api/getComments?test="+this.props.parent;
		axios.post(CDTURL,nodes).then(response=>{
			this.setState({
				comments:response.data.comments
			})
		})*/
		
		var nodeid = this.props.nodeid
		$(function(){
			$(".commentsArea[data-id='"+nodeid +"']").hide();
		})
    }

    componentDidUpdate = (prevProps) =>{
		if(parseInt(prevProps.commentscounter) !== parseInt(this.props.commentscounter)){

			var nodes={
			nodeid:this.props.nodeid,			
			parent:this.props.parent
			}	
    		var CDTURL = ServiceURL + "comments_api/getComments?tested="+this.props.parent;
    		axios.post(CDTURL,nodes).then(response=>{
    			var commentscounter = this.state.commentscounter + 1 
    			this.setState({
    				comments:response.data.comments,
    				commentscounter : commentscounter
    			})
    			this.showThiscommentsArea();
    			$("#comments"+this.props.nodeid).focus();
    		})
    	}
    }
    showThiscommentsArea = ()=>{
    	$(".commentsArea[data-id='"+ this.props.nodeid +"']").show();
    }
    getparent=(parent)=>{
    	this.props.getparent(parent);
    }
    
    replyComment=(event)=>{
    	var commentId = $(event.target).attr("data-commentid");
    	this.props.getparent(commentId);
    }  

    render() {
        return (
        		<div>
        			{
        				this.state.comments!== undefined && this.state.comments.length > 0 ? this.state.comments.map((comment,i) =>{
        				var comments = <div className="commentsSection" key={i}>
        					<div className="commentInfo row">
        						<div className="commentUserName"> {comment.username}</div>
        						<div className="commentDateTime"> {comment.comment_time}</div>
        					</div>
							<div className="commentDesc row">{comment.comment}</div> <br />
							<div className="commentActions row" data-commentid={comment.id} onClick={this.replyComment}> Reply </div>
							<div className="replies row">
								<Comments nodeid={this.props.nodeid} getparent={this.props.getparent} commentscounter={this.state.commentscounter} parent={comment.id} />
							</div>
						</div>;
						return comments;
					}):""}
				</div>
		);
    }
}
export default Comments;