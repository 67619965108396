import React,{Component} from "react";
import axios from "axios";
import {Link} from 'react-router-dom';
import cookie from 'react-cookies'
import InputMask from 'react-input-mask';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import '../landing/landingstyle.css';
import '../landing/landing-header.css';
import '../landing/landing-footer.css';
import ServiceURL from '../../../constants/constants';


class Signupkey extends Component{
  constructor(props){
    super(props);
    this.state = {
      email: this.props.location.state?this.props.location.state:'',
      key: '',
      emailError:"",
      keyError:"",
      successmsg:true
    }
  }
  responseFacebook = (response) => {
    console.log(response);
  }
  handleChange = event => {
    this.setState({
      [event.target.name]:event.target.value
    });
  }
  handleSubmit = (event)=> {
    event.preventDefault();
    const user = {
      email: this.state.email,
      key: this.state.key
    };
    let emailValid;
    let keyValidation;
    if(this.state.email === undefined){
      emailValid = false;
    }
    else
    {
      emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    }
    if(this.state.key === undefined){
      keyValidation = false;
    }
    else
    {
      keyValidation = (this.state.key.length ===7 ) ? true : false ;
    }
    let fieldValidationErrors = emailValid ? true : false;
    if(fieldValidationErrors===false){
      this.setState({
        emailError: fieldValidationErrors,
        emailErrorMsg: <span id="invalidEmail" className="cls-danger">Please point your actual email address.</span>
      })
    }
    if(keyValidation===false){
      this.setState({
        keyError: keyValidation,
        keyErrorMsg: <span id="invalidCode" className="cls-danger">Incorrect code. Please check your email box.</span>,
        sendCode:<div id="sendCode"><span className="send-code-text">Lost letter with code?</span><button  type="button" className="button-link wantBtn send-code-link" onClick={this.resendSignupCode}>Send me code again.</button></div>,
      })
    }
    else
    {
      this.setState({
        keyError: keyValidation,
        keyErrorMsg: " ",
        sendCode:" ",
      })
     this.serviceCall(user);
     // this.props.loginUser(user);
    }
    return false;
  }
  resendSignupCode = async()=>{
     const user = {
      email: this.state.email
    };
     var self = this;
    await axios.post(ServiceURL+`users_api/signupResendKey`, { user }).then(function (response) {
      self.setState({
        sendCode:" ",
      })
      self.renderRedirectCodeAgain();
    });

  }
  renderRedirectCodeAgain = () => {
    this.props.history.push(
    {
      pathname:'/signupkey',
      state:this.state.email,


    });
  }
  serviceCall = async(user)=>{
    var self = this;
    await axios.post(ServiceURL+`users_api/signupKeyVerify`, { user }).then(function (response) {
      if(response.data.status ==="fail"){
        self.setState({
          emailError:false,
          keyErrorMsg:<span id="invalidCode" className="cls-danger">Incorrect code. Please check your email box.</span>,
          sendCode:<div id="sendCode"><span className="send-code-text">Lost letter with code?</span><button  type="button" className="button-link wantBtn send-code-link" onClick={self.resendSignupCode}>Send me code again.</button></div>,

        });
      }
      else{
        var useremail = self.state.email;
    var userdetails = useremail.split("@");
    var nickname = userdetails[0];
    var total_name = nickname.split(/[-._]/);
    var name = total_name[0];
    if(total_name[1]){
      name = name+" "+total_name[1];
    }
    //console.log(name);
        cookie.save('userAcc', response.data.token, { path: '/' });
        cookie.save('userEmail', self.state.email, { path: '/' });
         cookie.save('Name', name, { path: '/' });
      cookie.save('nickname', nickname, { path: '/' });
        NotificationManager.success("key verified successfully applicaiton comming soon!!", "Success",5000);
        self.renderRedirect();
      }
    });
  }
  renderRedirect = () => {
    this.props.history.push({
      pathname: '/home/all/nodes',
      state: this.state.email,
      typeLogin: 'Normal'
    });
  }
  responseGoogle = (googleUser) => {
    var profile = googleUser.getBasicProfile();
    console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
    console.log('Name: ' + profile.getName());
    console.log('Image URL: ' + profile.getImageUrl());
    console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
  }

  facebookButtonAlert = ()=>{
    alert("this functionality will coming soon");
  }
  googleButtonAlert = ()=>{

    alert("this functionality will coming soon");
  }
  facebookButtonAlert = ()=>{
    alert("this functionality will coming soon");

  }
  render(){
    return(
      <div>
       <div className="landing-header">
      <div id="logo" className="pull-left">
        <div className="lh-left">
          [<span className="logo-mn">mn</span>] <span className="visible-cdt hidden-xs">Collaborative Documentation Tool</span> <span className="visible-cdt visible-xs">CDT</span>
        </div>
      </div>
      <div className="pull-right">
         <Link to="/login" id="logIn" className="btn btn-sm btn-primary">
         Log in </Link>
      </div>
      <div className="clearfix"></div>
   </div>
       <div className="container-fluid">
         <div className="login-wrapper">
           <div id="signUpPage"  className="login-box login-box-shadow">
             <p className="land-big-text"> Sign up for <strong>[<span className="cls-cdt">mn</span>]&nbsp;CDT</strong> </p>
               <form onSubmit={this.handleSubmit}>
                 <div className="form-group">
                   <input type="text" id="email" name="email" onChange={this.handleChange} value={this.state.email} className="form-control" placeholder="Enter your email address"/>
                   {this.state.emailErrorMsg}
                 </div>
                 <div className="form-group">
                   <div className="sub-form-text">We sent a one-time verification code to your email.<br/> Please type or paste the code below.
                   </div>
                 </div>
                 <div className="form-group">
                   <InputMask id="code"  mask="999-999" style={{'textAlign':'center'}}  name="key" value={this.state.key} onChange={this.handleChange} className="form-control  otp-form" placeholder="___-___" size="7" />
                   <div className="cls-danger land-small-text"> {this.state.keyErrorMsg} </div>
                   {this.state.sendCode}
                 </div>
                 <div className="form-group">
                   <button type="submit" id="continue"  className="btn btn-block btn-warning">Get Started</button>
                 </div>
                 <div className="form-group">
                   <p className="land-small-text">or sign up with</p>
                   <div className="social-btns">
                     <div className="btn-group">
                       <button type="button" onClick={this.facebookButtonAlert} className="btn facebook-btn">
                         <img src="images/facebook_new.png" alt="fb" width="16"/>
                       </button>
                       <button type="button" id="facebook" className="btn facebook-btn" onClick={this.facebookButtonAlert}>Facebook</button>
                     </div>
                     <div className="btn-group btn-group-margin">
                       <button type="button" onClick={this.googleButtonAlert} className="btn google-btn-G">
                         <img src='images/google_new.png' alt="google" width="16"/>
                       </button>
                       <button type="button" id="google" onClick={this.googleButtonAlert} className="btn google-btn">Google</button>
                     </div>
                     <div className="btn-group">
                       <button type="button" onClick={this.twitterButtonAlert} className="btn twitter-btn">
                         <img src="images/twitter-logo.png" alt="twitter" width="16"/>
                       </button>
                       <button type="button" id="twitter" onClick={this.twitterButtonAlert} className="btn twitter-btn">Twitter</button>
                     </div>
                   </div>
                 </div>
                </form>
                <NotificationContainer/>
              </div>
            </div>
          </div>
        </div>
        )
  }
}
export default Signupkey;