import React,{Component} from "react";
import { Popover} from 'react-bootstrap';
/*import { Popover, OverlayTrigger, Button} from 'react-bootstrap';*/

class Test extends Component{
	state = {
	Swhich: "", 
    SkeyCode:"",
    SshiftKey:"",
    SaltKey:"",
    SctrlKey:"",
    SmetaKey:"",
    Skey:"",

	}

	
	popover =() => { 
	 	return (
  <Popover id="popover-basic">
    <Popover.Title as="h3">Popover right</Popover.Title>
    <Popover.Content>
      And here's some <strong>amazing</strong> content. It's very engaging.
      right?
    </Popover.Content>
  </Popover>
);
	}
	render(){
		return(
			<div>
				div tables testing
				<table border="1">
					<tr>
						<td> c1- 1 </td>
						<td> c1- 2 </td>
						<td> c1- 3 </td>
						<td> c1- 4 </td>
						<td> c1- 5 </td>
						<td> c1- 6 </td>
						<td> c1- 7 </td>
						<td> c1- 8 </td>
						<td> c1- 9 </td>
						<td> c1- 10 </td>
						<td> c1- 11 </td>
						<td> c1- 12 </td>
					</tr>

					<tr>
						<td> c2- 1 </td>
						<td> c2- 2 </td>
						<td> c2- 3 </td>
						<td> c2- 4 </td>
						<td> c2- 5 </td>
						<td> c2- 6 </td>
						<td> c2- 7 </td>
						<td> c2- 8 </td>
						<td> c2- 9 </td>
						<td> c2- 10 </td>
						<td> c2- 11 </td>
						<td> c2- 12 </td>
					</tr>

					<tr>
						<td> c3- 1 </td>
						<td> c3- 2 </td>
						<td> c3- 3 </td>
						<td> c3- 4 </td>
						<td> c3- 5 </td>
						<td> c3- 6 </td>
						<td> c3- 7 </td>
						<td> c3- 8 </td>
						<td> c3- 9 </td>
						<td> c3- 10 </td>
						<td> c3- 11 </td>
						<td> c3- 12 </td>
					</tr>

					<tr>
						<td> c4- 1 </td>
						<td> c4- 2 </td>
						<td> c4- 3 </td>
						<td> c4- 4 </td>
						<td> c4- 5 </td>
						<td> c4- 6 </td>
						<td> c4- 7 </td>
						<td> c4- 8 </td>
						<td> c4- 9 </td>
						<td> c4- 10 </td>
						<td> c4- 11 </td>
						<td> c4- 12 </td>
					</tr>

					<tr>
						<td> c5- 1 </td>
						<td> c5- 2 </td>
						<td> c5- 3 </td>
						<td> c5- 4 </td>
						<td> c5- 5 </td>
						<td> c5- 6 </td>
						<td> c5- 7 </td>
						<td> c5- 8 </td>
						<td> c5- 9 </td>
						<td> c5- 10 </td>
						<td> c5- 11 </td>
						<td> c5- 12 </td>
					</tr>

					<tr>
						<td> c6- 1 </td>
						<td> c6- 2 </td>
						<td> c6- 3 </td>
						<td> c6- 4 </td>
						<td> c6- 5 </td>
						<td> c6- 6 </td>
						<td> c6- 7 </td>
						<td> c6- 8 </td>
						<td> c6- 9 </td>
						<td> c6- 10 </td>
						<td> c6- 11 </td>
						<td> c6- 12 </td>
					</tr>

					<tr>
						<td> c7- 1 </td>
						<td> c7- 2 </td>
						<td> c7- 3 </td>
						<td> c7- 4 </td>
						<td> c7- 5 </td>
						<td> c7- 6 </td>
						<td> c7- 7 </td>
						<td> c7- 8 </td>
						<td> c7- 9 </td>
						<td> c7- 10 </td>
						<td> c7- 11 </td>
						<td> c7- 12 </td>
					</tr>

					<tr>
						<td> c8- 1 </td>
						<td> c8- 2 </td>
						<td> c8- 3 </td>
						<td> c8- 4 </td>
						<td> c8- 5 </td>
						<td> c8- 6 </td>
						<td> c8- 7 </td>
						<td> c8- 8 </td>
						<td> c8- 9 </td>
						<td> c8- 10 </td>
						<td> c8- 11 </td>
						<td> c8- 12 </td>
					</tr>

					<tr>
						<td> c1- 1 </td>
						<td> c1- 2 </td>
						<td> c1- 3 </td>
						<td> c1- 4 </td>
						<td> c1- 5 </td>
						<td> c1- 6 </td>
						<td> c1- 7 </td>
						<td> c1- 8 </td>
						<td> c1- 9 </td>
						<td> c1- 10 </td>
						<td> c1- 11 </td>
						<td> c1- 12 </td>
					</tr>

					<tr>
						<td> c1- 1 </td>
						<td> c1- 2 </td>
						<td> c1- 3 </td>
						<td> c1- 4 </td>
						<td> c1- 5 </td>
						<td> c1- 6 </td>
						<td> c1- 7 </td>
						<td> c1- 8 </td>
						<td> c1- 9 </td>
						<td> c1- 10 </td>
						<td> c1- 11 </td>
						<td> c1- 12 </td>
					</tr>

					<tr>
						<td> c1- 1 </td>
						<td> c1- 2 </td>
						<td> c1- 3 </td>
						<td> c1- 4 </td>
						<td> c1- 5 </td>
						<td> c1- 6 </td>
						<td> c1- 7 </td>
						<td> c1- 8 </td>
						<td> c1- 9 </td>
						<td> c1- 10 </td>
						<td> c1- 11 </td>
						<td> c1- 12 </td>
					</tr>

					<tr>
						<td> c1- 1 </td>
						<td> c1- 2 </td>
						<td> c1- 3 </td>
						<td> c1- 4 </td>
						<td> c1- 5 </td>
						<td> c1- 6 </td>
						<td> c1- 7 </td>
						<td> c1- 8 </td>
						<td> c1- 9 </td>
						<td> c1- 10 </td>
						<td> c1- 11 </td>
						<td> c1- 12 </td>
					</tr>

				</table>
			</div>
			);
	}
}

export default Test;