export const LOGIN_KEY_REQUEST = 'LOGIN_KEY_REQUEST'
export const LOGIN_KEY_SUCCESS = 'LOGIN_KEY_SUCCESS'
export const LOGIN_KEY_FAIL = 'LOGIN_KEY_FAIL'
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL'
export const SIGNUP_KEY_REQUEST = 'SIGNUP_KEY_REQUEST'
export const SIGNUP_KEY_SUCCESS = 'SIGNUP_KEY_SUCCESS'
export const SIGNUP_KEY_FAIL = 'SIGNUP_KEY_FAIL'
export const SIGNUP_USER_REQUEST = 'SIGNUP_USER_REQUEST'
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS'
export const SIGNUP_USER_FAIL = 'SIGNUP_USER_FAIL'
export const LOGOUT = 'LOGOUT'
