import React,{Component} from "react";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import '../../css/style.css';
import $ from 'jquery';

class CdtHeader extends Component{
	constructor(props){
		super(props);
		this.state={
			openState:'open',
			typeLogin: this.props.location.typeLogin,
			username: this.props.location.username
		}
		if(this.state.typeLogin==="normal"){
			NotificationManager.success("Welcome " + this.state.username + " you are successfully logged in with email key", "success",15000);
		}
		else if(this.state.typeLogin==="facebook"){
			NotificationManager.success("Welcome " + this.state.username + " you are successfully logged in with Facebook", "success",15000);
		}
		else if(this.state.typeLogin==="google"){
			NotificationManager.success("Welcome " + this.state.username + " you are successfully logged in with Google", "success",15000);
		}
		else if(this.state.typeLogin==="twitter"){
			NotificationManager.success("Welcome " + this.state.username + " you are successfully logged in with Twitter", "success",15000);
		}
	}
	
	navigation = (flagstring,k,e)=> {
		console.log(flagstring)
		if(flagstring === 'open'){
			console.log("open success")
			$("#cdtSidenav").css("width","250px");
			$("#cdt-wrapper").css("marginLeft","250px");
			$("#cdtSidenav").html($("#cnt"+k).html());
			$("#Nav"+k).addClass("active");
		}
		if(flagstring === 'close'){
			console.log("this is close Nav");
			$("#cdtSidenav").css("width","0px");
			$("#cdt-wrapper").css("marginLeft","0px");
		}
	}

	render(){
		return (
			<div>
				<div id="cdtSidenav" className="sidenav">&nbsp;</div>
				<div className="navdisplay">	
				
				<div id="cnt2" className="navdisplay">
					<div className="lm-nav-title">
				    	<h4>Test-2</h4>
				    	<button className="closebtn btn btn-success" onClick={()=>this.navigation('close',2)}>&times;</button>
				  	</div>
				  	<div className="lm-nv-content pad-all-8">
				    	<p>
				      2.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
				      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
				      quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
				      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
				      cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
				      proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
				    </p>
				  </div>
				</div>
				<div id="cnt3" className="navdisplay">
				  <div className="lm-nav-title">
				    <h4>Test-3</h4>
				   <button className="closebtn btn btn-success" onClick={()=>this.navigation('close',3)}>&times;</button>
				  </div>
				  <div className="lm-nv-content pad-all-8">
				     <div className="list-group lm-list-group">
				      <span className="list-group-item active">
				      	<span className="badge lg-badge">
				      	faTrash
				      	</span>
				      	3.1 Collaborative tools
				      </span>
				      <span className="list-group-item">4.1 Common Interface</span>
				    </div>
				  </div>
				</div>
				<div id="cnt4" className="navdisplay">
				  <div className="lm-nav-title">
				    <h4>Test-4</h4>
				    <button className="closebtn btn btn-success" onClick={()=>this.navigation('close',4)}>&times;</button>
				  </div>
				  <div className="lm-nv-content pad-all-8">
				    <p>
				      4.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
				      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
				      quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
				      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
				      cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
				      proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
				    </p>
				  </div>
				</div>
				<div id="cnt5" className="navdisplay">
				  <div className="lm-nav-title">
				    <h4>Test-5</h4>
				    <button className="closebtn btn btn-success" onClick={()=>this.navigation('close',5)}>&times;</button>
				  </div>
				  <div className="lm-nv-content pad-all-8">
				    <p>
				      5.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
				      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
				      quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
				      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
				      cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
				      proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
				    </p>
				  </div>
				</div>
				</div>

				<div className="left-side-bar">
					<ul className="links-icons">
						<li className="Nav1"> <span onClick={()=>this.navigation('open',1)}> </span></li>
						<li className="Nav2"> <span onClick={()=>this.navigation('open',2)}> </span></li>
						<li className="Nav3"> <span onClick={()=>this.navigation('open',3)}> </span></li>
						<li className="Nav4"> <span onClick={()=>this.navigation('open',4)}> </span></li>
						<li className="Nav5"> <span onClick={()=>this.navigation('open',5)}> </span></li>
						<li>
						<button className="closebtn btn btn-success" onClick={()=>this.navigation('close',2)}>&times;</button>
						</li>
				   </ul>
				</div>
				<div id="cdt-wrapper">

				
				</div>
				<NotificationContainer/>
				</div>
        )    
	}
}
export default CdtHeader;