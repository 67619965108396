import React, { Component } from "react";
import {Modal} from 'react-bootstrap';
import '../body/settingspopup.css';
import {ChromePicker} from 'react-color'


class settingsPopup extends Component{
	constructor(props) {
		super (props);
		this.state={
			displaycolor:false,
			displayPicker:false,
			dealPopupShown:false,
			displaycolorred:false,
			displayColorPicker:false,
			displaycolormeroon:false,
			changeColorpickermeroon:"#800000",
			changeColorpickerred:"#ff0000",
			changeColorpicker:"#008000",
			changepicker:"#0b4ba3",
			changeColor:"#808080",
			defaultColor:"#999",
			
			color:{
				r: "128",
				g: "128",
				b: "128",
				a: "1",
			},

			color2:{
				r: "11",
				g: "75",
				b: "163",
				a: "1",
			},

			color3:{
				r: "0",
				g: "120",
				b: "0",
				a: "1",
			},

			color4:{
				r: "255",
				g: "0",
				b: "0",
				a: "1",
			},

			color5:{
				r: "128",
				g: "0",
				b: "0",
				a: "1",
			},
		}
	}

	showDealPopup = () =>{
		this.setState({
			dealPopupShown: true
		})
	}

	closeDealPopup = () =>{
		this.setState({
			dealPopupShown: false
		})	
	}

	onHandleShowColorPicker = () => {
		this.setState({
			displayColorPicker:true
		})
	}

	onHandleCloseColorPicker = () => {
		this.setState({
			displayColorPicker:false
		})
	}

	onChangeColorPicker = color => {
		this.setState({ color: color.rgb , changeColor: color.hex})
	}

	ShowColorPicker = () => {
		this.setState({
			displayPicker:true
		})
	}

	CloseColorPicker = () => {
		this.setState({
			displayPicker:false
		})
	}

	onChangePicker = color => {
		this.setState({ color2: color.rgb , changepicker: color.hex})
	}

	ShowColor = () => {
		this.setState({
			displaycolor :true
		})
	}

	CloseColor = () => {
		this.setState({
			displaycolor :false
		})
	}

	onChangecolor = color => {
		this.setState({ color3: color.rgb , changeColorpicker: color.hex})
	}

	ShowColorred = () => {
		this.setState({
			displaycolorred :true
		})
	}

	CloseColorred= () => {
		this.setState({
			displaycolorred :false
		})
	}

	onChangecolorred = color => {
		this.setState({ color4: color.rgb , changeColorpickerred: color.hex})
	}

	ShowColormeroon = () => {
		this.setState({
			displaycolormeroon :true
		})
	}

	CloseColormerron= () => {
		this.setState({
			displaycolormeroon :false
		})
	}

	onChangecolormeroon = color => {
		this.setState({ color5: color.rgb , changeColorpickermeroon: color.hex})
	}


	render(){
		return(
			
			<div id="settingsPage">
				<button id="test" name="text" type="button" onClick={this.showDealPopup}>settings</button>
				<Modal id="settingsPopup" show={this.state.dealPopupShown} className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="dealPopupHeader">
					<button type="button" className="popup-close" onClick={this.closeDealPopup} aria-label="Close"><span className="cls-danger" aria-hidden="true">&times;</span></button>
						<div className="popup-title">
							Settings
						</div>
						<div className="modal-body settings-mbody">
							<div className="section">
								<div className="section-title">Account</div>
								<div className="parameter-group">
									<label className="parameter-label">Email</label>
									<input type="email"  name="email" class="parameter-input" placeholder="email@example.com" />
								</div>
								<div className="parameter-group">
									<label className="parameter-label">Full name</label>
									<input type="text"  name="name" class="parameter-input" placeholder="First and last name" />
								</div> 
								<div className="parameter-group">
									<label className="parameter-label">Nick name</label>
									<input type="Unique user name"  name="nick" class="parameter-input" placeholder="Unique user name" />
								</div> 
								<div className="parameter-group">
									<label className="parameter-label">Phone #</label>
									<input type="Unique user name"  name="phone" class="parameter-input" placeholder="Phone number" />
								</div>  
								<div className="profilr-edit-icon center">
									<div class="profile-img mar-top-zero">
										<img id="profileImg" src="/images/dima.jpg" alt="profile"/>
									</div>
									<div className id="uploadPhoto">
										<button type="button" className="btn btn-primary btn-upload">Upload photo</button>
									</div>
								</div> 
							</div>
							<div className="section">
								<div className="section-title">Languages and translation</div>
								<div className="parameter-group">
									<label className="parameter-label">Primary language</label>
									<select className="parameter-select" name="primaryLanguage">
											<option>English</option>
											<option>German</option>
											<option>Russian</option>
									</select>
								</div>
								<div className="parameter-group">
									<label className="parameter-label">Show suggestions</label>
									<div className="parameter-switch">
										<span class="switchToggle">
											<input id="showSuggestions" type="checkbox" name="showSuggestions"/>
											<label for="showSuggestions">Toggle</label>
										</span>
									</div>
								</div>
								<div className="parameter-group">
									<label className="parameter-label">Spell check</label>
									<div className="parameter-switch">
										<span class="switchToggle">
											<input id="spellChecks" type="checkbox" name="spellCheck"/>
											<label for="spellChecks">Toggle</label>
										</span>
									</div>
								</div>
								<div className="parameter-group">
									<label className="parameter-label">Bilingual view</label>
									<div className="parameter-switch">
										<span class="switchToggle">
											<input id="bilingualViews" type="checkbox" name="bilingualView" />
											<label for="bilingualViews">Toggle</label>
										</span>
									</div>
								</div>
								<div className="parameter-group">
									<label className="parameter-label">Secondary language</label>
									<select className="parameter-select" name="secondaryLanguage">
										<option>English</option>
										<option>German</option>
										<option>Russian</option>
									</select>
								</div>
								<div className="parameter-group">
									<label className="parameter-label">Translation engine</label>
									<select className="parameter-select" name="translationEngine">
										<option>Google Translate</option>
										<option>Microsoft Translation</option>
										<option>IBM Watson Language Translator</option>
									</select>
								</div>
							</div>    
							<div className="section">
								<div className="section-title">Appearance</div>
								<button id="restoreDefaults" type="button" className="btn btn-primary">Restore defaults</button>
								<div className="parameter-group">
									<label className="parameter-label">Theme</label>
									<div className="parameter">
										<select className="parameter-select" name="theme">
											<option>Day</option>
											<option>Night</option>
											<option>Classic</option>
										</select>
									</div>    
								</div>
								<div className="parameter-group">
									<label className="parameter-label">Font</label>
									<div className="parameter">
										<select className="parameter-select" name="font">
											<option>Sans-serif</option>
											<option>System</option>
											<option>Serif</option>
											<option>Typewriter</option>
										</select>
									</div>
								</div>
								<div className="parameter-group">
									<label className="parameter-label cls-gray">Normal text</label>
									<div className="parameter">
										<div className="color-picker-container">
											<div style={{ backgroundColor:this.state.changeColor}}
												className={"color-picker-color-background"}
												onClick ={ () => this.onHandleShowColorPicker ()}
											/>
											<div className={"color-text-with-popup"}> 
												<input 
													className={"parameter-input"}

													type="text"

													name="normalTextColor"

													value={this.state.changeColor}

													onClick ={ () => this.onHandleShowColorPicker ()}
												/>
												{this.state.displayColorPicker&& (
													<div className={"color-picker-palette"}>
														<div className={"color-picker-cover"}
															onClick ={ () => this.onHandleCloseColorPicker()}
														/>
														<ChromePicker
														color={this.state.color}
														onChange={ this.onChangeColorPicker} />
													</div>    
												)} 
											</div>
										</div>
									</div>    
								</div>
								<div className="parameter-group">
									<label className="parameter-label cls-blue">Reference</label>
									<div className="parameter">
										<div className="color-picker-container">
											<div style={{ backgroundColor:this.state.changepicker}}
												className={"color-picker-color-background"}
												onClick ={ () => this.ShowColorPicker ()}
											/>
											<div className={"color-text-with-popup"}> 
												<input 
													className={"parameter-input"}

													type="text"

													name="normalTextColor"

													value={this.state.changepicker}

													onClick ={ () => this.ShowColorPicker ()}
												/>
												{this.state.displayPicker && (
													<div className={"color-picker-palette"}>
														<div className={"color-picker-cover"}
															onClick ={ () => this.CloseColorPicker()}
														/>
														<ChromePicker
														color={this.state.color2}
														onChange={ this.onChangePicker} />
													</div>    
												)} 
											</div>
										</div>
									</div>    
								</div>
								<div className="parameter-group">
									<label className="parameter-label cls-green">Added text</label>
									<div className="parameter">
										<div className="color-picker-container">
											<div style={{ backgroundColor:this.state.changeColorpicker}}
												className={"color-picker-color-background"}
												onClick ={ () => this.ShowColor ()}
											/>
											<div className={"color-text-with-popup"}> 
												<input 
													className={"parameter-input"}

													type="text"

													name="normalTextColor"

													value={this.state.changeColorpicker}

													onClick ={ () => this.ShowColor ()}
												/>
												{this.state.displaycolor && (
													<div className={"color-picker-palette"}>
														<div className={"color-picker-cover"}
															onClick ={ () => this.CloseColor()}
														/>
														<ChromePicker
														color={this.state.color3}
														onChange={ this.onChangecolor} />
													</div>    
												)} 
											</div>
										</div>
									</div>    
								</div>
								<div className="parameter-group">
									<label className="parameter-label cls-red">Deleted text</label>
									<div className="parameter">
										<div className="color-picker-container">
											<div style={{ backgroundColor:this.state.changeColorpickerred}}
												className={"color-picker-color-background"}
												onClick ={ () => this.ShowColorred ()}
											/>
											<div className={"color-text-with-popup"}> 
												<input 
													className={"parameter-input"}

													type="text"

													name="normalTextColor"

													value={this.state.changeColorpickerred}

													onClick ={ () => this.ShowColorred ()}
												/>
												{this.state.displaycolorred && (
													<div className={"color-picker-palette"}>
														<div className={"color-picker-cover"}
															onClick ={ () => this.CloseColorred()}
														/>
														<ChromePicker
														color={this.state.color4}
														onChange={ this.onChangecolorred} />
													</div>    
												)} 
											</div>
										</div>
									</div>    
								</div>
								<div className="parameter-group">
									<label className="parameter-label cls-meroon">Font style changed</label>
									<div className="parameter">
											<div className="color-picker-container">
											<div style={{ backgroundColor:this.state.changeColorpickermeroon}}
												className={"color-picker-color-background"}
												onClick ={ () => this.ShowColormeroon ()}
											/>
											<div className={"color-text-with-popup"}> 
												<input 
													className={"parameter-input"}

													type="text"

													name="normalTextColor"

													value={this.state.changeColorpickermeroon}

													onClick ={ () => this.ShowColorred ()}
												/>
												{this.state.displaycolormeroon && (
													<div className={"color-picker-palette"}>
														<div className={"color-picker-cover"}
															onClick ={ () => this.CloseColormerron()}
														/>
														<ChromePicker
														color={this.state.color5}
														onChange={ this.onChangecolormeroon} />
													</div>    
												)} 
											</div>
										</div>
									</div>    
								</div>
							</div>
							<div className="section">
								<div className="section-title">Other</div>
								<div className="parameter-group">
									<p className="parameter-label">Help me to learn keyboard shortcuts</p>
									<div className="parameter-switch Other"> 
										<div className="switchToggle">
											<input id="keyboardsc" type="checkbox" name="shortcuts"/>
											<label for="keyboardsc">Toggle</label>
										</div>
									</div>
								</div>
							</div>
							<div className="section">
								<div className="section-title danger">
									Delete my account
								</div>
								<label className="delete-warning">
									Please be careful, deleting of your account is permanent and can't be rolled back.
								</label>
								<button id="deleteAccount" type="button" class="btn btn-danger">
									Delete my account and all my data
								</button>
							</div>
						</div>
				</Modal>
			</div>
		)
	}
}
export default settingsPopup;
