import React,{Component} from "react";
import { Link} from "react-router-dom";
import '../../css/style.css'
import '../../common.css';
import '../body/pricing.css';
import {Modal} from "react-bootstrap";

class Pricing extends Component{
	componentDidMount(){
		document.title = "CDT - Pricing"
	}
	constructor(props){
		super(props);
		this.state={
			useAnnuallyPrice: true,
			trialPopupShown: false,
			unlimitedPopupShown: false,
			dealPopupShown: false,
			users: 10,
			price: 7,
			weeks: 6
		}
	}

	// Monthy <-> Annually slider
	changePriceType = (event) =>{
		var price = document.getElementById('price');
		var users = document.getElementById('users');

		price.textContent = event.target.checked ? '$5' : '$8';
		users.textContent = event.target.checked ? 'USER PER MONTH' : 'PER USER';

		var annually = document.getElementById('annually');
		var monthly = document.getElementById('monthly');

		if (event.target.checked) {
			annually.classList.add('selected');
			monthly.classList.remove('selected');
				
			this.setState({
				useAnnuallyPrice: true
			})
		}
		else {
			monthly.classList.add('selected');
			annually.classList.remove('selected');
				
			this.setState({
				useAnnuallyPrice: false
			})
		}
	} 

	// popups show & close
	showTrialPopup = () => {
		this.setState({
			trialPopupShown: true
		})
	}

	closeTrialPopup = () =>{
		this.setState({
			trialPopupShown: false
		})	
	}

	showUnlimitedPopup = ()=>{
		this.setState({
			unlimitedPopupShown: true
		})
	}

	closeUnlimitedPopup = () =>{
		this.setState({
			unlimitedPopupShown: false
		})	
	}

	showDealPopup = ()=>{
		this.setState({
			dealPopupShown: true
		})
	}

	closeDealPopup = () =>{
		this.setState({
			dealPopupShown: false
		})	
	}

		// 'Users' input
	usersInc = () => {
		this.setState(prevState => {
			if (prevState.users < 100) {
				return {users: prevState.users + 5}
			}
			else {
				return null;
			}
		});
	}

	usersDec = () => {
		this.setState(prevState => {
			if(prevState.users > 5) {
				return {users: prevState.users - 5}
			} 
			else {
				return null;
			}
		});
	}
		
	usersChanged = (event) => {
		this.setState({users: event.target.value});
	}

	// 'Price' input
	priceInc = () => {
		this.setState(prevState => {
			if(prevState.price < 8) {
				return{price: prevState.price + 1}
			}
			else {
				return null;
			}
		});
	}

	priceDec = () => {
		this.setState(prevState => {
			if(prevState.price > 1) {
				return {price: prevState.price - 1}
			} 
			else {
				return null;
			}
		});
	}

	priceChanged = (event) => {
		this.setState({price: event.target.value});
	}

	   // 'Weeks' input
	weeksInc = () => {
		this.setState(prevState => {
			if(prevState.weeks < 12) {
				return{weeks: prevState.weeks + 1}
			}
			else {
				return null;
			}
		});
	}

	weeksDec = () => {
		this.setState(prevState => {
			if(prevState.weeks > 4) {
				return {weeks: prevState.weeks - 1}
			} 
			else{
				return null;
			}
		});
	}
	weeksChanged = (event) => {
		this.setState({weeks : event.target.value});
	}

	render(){
		return (
			<div id="pricingPage">
				<div id="landingHeader" className="landing-header">
					<Link id="logo" to="/landing">
						<div id="logo">
							[<span className="logo-mn">mn</span>]&nbsp;
							<span Link to="/landing" className="hidden-xs">Collaborative Documenting Tool</span> 
							<span className="visible-xs">CDT</span>
						</div>
					</Link>
					<div>
						<div className="lr-links">
							<Link id="help" to="/help" className="button-link cls-cdt">Help & Support</Link>
							<button id="unlimited" type="button" onClick={this.showUnlimitedPopup} className="btn">Try Unlimited</button>
						</div>
					</div>
				</div>
				<div className="pricing-section">
					<div className="container">
						<div id="pricingName"><span className="pricing-title">Pricing</span></div>
							<div className="row">
								<div className="col-md-offset-2 col-md-8 col-md-offset-2 col-md-offset-2 col-md-10 col-md-offset-2 col-xs-12">
									<div className="price-cols">
										<div className="row">
											<div className="col-md-6 col-sm-6 col-xs-12">
												<div id="planFree"className="price-box">
													<div className="plan-name">Free</div>
													<div className="price-cost mBtm20 center">
													<div className="plan-price">FREE</div>
													<div className="plan-term">FOREVER</div>
												</div>
												<div className="plan-limits">
													<div>
														<span className="limit-value">100 MB</span><span className="limit-name">storage</span>
													</div>
													<div>
														<span className="limit-value">Unlimited</span><span className="limit-name">number of nodes</span>
													</div>
													<div>
														<span className="limit-value">Unlimited</span><span className="limit-name">users</span>
													</div>
												</div>
												<div className="mar-tb-5">
													<Link to="/signUp" id="signUp" type="button" className="btn btn-primary btn-block btn-lg">Sign up now</Link>
												</div>
											</div>
										</div>
										<div className="col-md-6 col-sm-6 col-xs-12">
											<div id="planUnlimited" className="price-box">
												<div className="plan-name">Unlimited</div>
												<div className="price-cost mBtm20 center">
													<div id="price" className="plan-price">$5</div>
													<div id="users" className="plan-price-units">User per month</div>
												</div>
												<div className="switch-prices">
													<div className="switch">
														<span id="monthly" className="s-monthly noselect">Monthly</span>
														<label id="priceSwitch">
															<input id="priceType" type="checkbox" onChange={this.changePriceType} checked={this.state.useAnnuallyPrice}/>
															<span className="switchBtn noselect"></span>
														</label>
														<span id="annually" className="s-monthly noselect selected">Annually</span>
													</div>
												</div>
												<div className="plan-limits">
													<div>
														<span className="limit-value">Unlimited</span><span className="limit-name">storage</span>
													</div>
													<div>
														<span className="limit-value">Unlimited</span><span className="limit-name">templates</span>
													</div>
													<div>
														<span className="limit-value">Unlimited</span><span className="limit-name">views</span>
													</div>
													<div>
														<span className="limit-value">Enhanced</span><span className="limit-name">rights management</span>
													</div>
												</div>
												<div className="center">													
													<div className="trial-line">
														<span className="trial-line-text">4-weeks free trial. Need more?</span><button id="trial" className="trial-line-link" onClick={this.showTrialPopup}>Just ask</button>
													</div>
													<div className="mar-tb-5">
														<button id="tryForFree" type="button" className="btn btn-primary btn-block btn-lg" onClick={this.showUnlimitedPopup}>Try for free</button>
													</div>
													<div className="deal-line">
														<span className="deal-line-text">or let's</span><button id="deal" type="button" className="deal-line-link" onClick={this.showDealPopup}>make a deal</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal id="dealPopup" show={this.state.dealPopupShown} className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="dealPopupHeader">
					<Modal.Header className="modal-header">
						<button type="button" className="popup-close" onClick={this.closeDealPopup} aria-label="Close"><span className="cls-danger" aria-hidden="true">&times;</span></button>
						<div id="dealPopupHeader" className="popup-title">Make a deal</div>
					</Modal.Header>
					<Modal.Body className="modal-body">
						<form>
							<div className="deal-popup">
								<div className="row">
									<div className="col-md-6 col-sm-6 col-xs-12">
										<div className="numeric-group">
											<div className="group-numeric-label">How much you want to pay?</div>
											<div>
												<label className="group-numeric-units">&#36;</label>
												<input className="group-numeric-input" id="dealPrice" type="text" defaultValue="7" title="text" value={this.state.price} onChange={this.priceChanged}/>
											</div>
											<div className={this.state.price >= 8 ? "group-numeric-arrow-up inc-dis" : "group-numeric-arrow-up"} title="More money" onClick={this.priceInc}>
												<img src="images/command-icon/ch_up.png" alt="More money" />
											</div>
											<div className={this.state.price <= 1 ? "group-numeric-arrow-down dec-dis" : "group-numeric-arrow-down"} title="Less money" onClick={this.priceDec}>
												<img src="images/command-icon/ch_down.png" alt="Less money" />
											</div>
										</div>
									</div>
									<div className="col-md-6 col-sm-6 col-xs-12">
										<div className="numeric-group">
											<div className="group-numeric-label">How many users you have?</div>
											<div>
												<input id="dealUsers" className="group-numeric-input" type="text" defaultValue="10" title="text" value={this.state.users} onChange={this.usersChanged} />
											</div>
											<div className={this.state.users >= 100 ? "group-numeric-arrow-up inc-dis" : "group-numeric-arrow-up"} title="More users" onClick={this.usersInc}>
												<img src="images/command-icon/ch_up.png" alt="More users" />
											</div>
											<div className={this.state.users <= 5? "group-numeric-arrow-down dec-dis" :"group-numeric-arrow-down"} title="Less users" onClick={this.usersDec}>
												<img src="images/command-icon/ch_down.png" alt="Less users" />
											</div>
										</div> 
									</div>
								</div>
								<div>&nbsp;</div>
								<div className="text-group">
									<label className="group-text-label">What is your name?<span className="cls-danger"></span></label>
									<input id="dealName" className="group-text-input" type="text" title="text" name="name" placeholder="Your name or name of your company" />
									<span id="dealInvalidName" className="group-text-error">Please point your name.</span>
								</div>
								<div className="text-group">
									<label className="group-text-label">How to reach you?<span className="cls-danger"></span></label>
									<input id="dealEmail" type="text" className="group-text-input" name="email" placeholder="email@example.com" />
									<span id="dealInvalidEmail" className="group-text-error">Please point your actual email address.</span>
								</div>
								<div className="text-group">
									<label className="group-text-label">Tell us your story</label>
									<textarea id="dealStory" className="group-text-input" rows="3" name="story" placeholder="Leave your comments here"></textarea>
								</div>
								<div className="marTop30">
									<button id="dealSubmit" type="button" className="btn btn-primary btn-block btn-lg">Submit my offer</button>
								</div>
							</div>
						</form>
					</Modal.Body>
				</Modal>
				<Modal id="trialPopup" show={this.state.trialPopupShown} className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="trialPopupHeader">
					<Modal.Header className="modal-header">
						<button type="button" className="popup-close" onClick={this.closeTrialPopup} aria-label="Close"><span className="cls-danger" aria-hidden="true">&times;</span></button>
						<div id="trialPopupHeader" className="popup-title">Ask for longer trial period</div>
					</Modal.Header>
					<Modal.Body className="modal-body">
						<form>
							<div className="deal-popup">
								<div className="row">
									<div className="col-md-6 col-sm-6 col-xs-12">
										<div className="numeric-group">
											<div className="group-numeric-label">How many weeks you need?</div>
										<div>
											<input className="group-numeric-input" id="trialWeeks" type="text" title="text" value={this.state.weeks} onChange={this.weeksChanged} defaultValue="6" />
										</div>
										<div className={this.state.weeks >= 12 ? "group-numeric-arrow-up inc-dis" : "group-numeric-arrow-up"} title="More weeks" onClick={this.weeksInc}>
											<img src="images/command-icon/ch_up.png" alt="More weeks" />
										</div>
										<div className={this.state.weeks <= 4 ? "group-numeric-arrow-down dec-dis" : "group-numeric-arrow-down"} title="Less weeks" onClick= {this.weeksDec}>
											<img src="images/command-icon/ch_down.png" alt="Less weeks" />
										</div>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-xs-12">
									<div className="numeric-group">
										<div className="group-numeric-label">How many users you have?</div>
										<div>
											<input className="group-numeric-input" id="trialUsers" type="text" value={this.state.users} onChange={this.usersChanged} defaultValue="10" />
										</div>
										<div className={this.state.users >= 100 ? "group-numeric-arrow-up inc-dis" : "group-numeric-arrow-up"} title="More users" onClick={this.usersInc}> 
											<img src="images/command-icon/ch_up.png" alt="users" />
										</div>
										<div className={this.state.users <= 5 ? "group-numeric-arrow-down dec-dis" : "group-numeric-arrow-down"} title="Less users" onClick={this.usersDec}>
											<img src="images/command-icon/ch_down.png" alt="payment" /></div>
										</div>
									</div>
								</div>
								<div className="text-group">
									<label className="group-text-label">What is your name?<span className="cls-danger"></span></label>
									<input id="trialName" type="text" className="group-text-input"name="name" title="text" placeholder="Your name or name of your company" />
									<span id="trialInvalidName" className="group-text-error">Please point your name.</span>
								</div>
								<div className="text-group">
									<label className="group-text-label">How to reach you?<span className="cls-danger"></span></label>
									<input id="trialEmail" type="text" className="group-text-input" name="email" title="text" placeholder="email@example.com" />
									<span id="trialInvalidEmail" className="group-text-error">Please point your actual email address.</span>
								</div>
								<div className="text-group">
									<label className="group-text-label">Tell us your story</label>
									<textarea id="trialStory" className="group-text-input" title="text" name="story" rows="3" placeholder="Leave your comments here"></textarea>
								</div>
								<div className="marTop30">
									<button id="trialSend" type="button" className="btn btn-primary btn-block btn-lg">Send request</button>
								</div>
							</div>
						</form>
					</Modal.Body>
				</Modal>
				<Modal id="unlimitedPopup" show={this.state.unlimitedPopupShown} className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="unlimitedPopupHeader">
					<Modal.Header className="modal-header">
						<button type="button" className="popup-close" onClick={this.closeUnlimitedPopup} aria-label="Close"><span className="cls-danger" aria-hidden="true">&times;</span></button>
						<div id="unlimitedPopup" className="popup-title">Try unlimited version</div>
					</Modal.Header>
					<Modal.Body className="modal-body">
						<form>
							<div className="deal-popup">
								<div className="text-group">
									<label className="group-text-label">What is your name?<span className="cls-danger"></span></label>
									<input id="unlimitedName" type="text" className="group-text-input" name="name" title="text" placeholder="Your name or name of your company" />
									<span className="cls-danger">Please point your name.</span>
								</div>
								<div className="text-group">
									<label className="group-text-label">How to reach you?<span className="cls-danger"></span></label>
									<input id="unlimitedEmail" type="text" className="group-text-input" title="text" name="email" placeholder="email@example.com" />
									<span className="cls-danger">Please point your actual email address.</span>
								</div>
								<div className="marTop30">
									<button id="unlimitedStart" type="submit" className="btn btn-primary btn-block btn-lg">Start 4-weeks trial now</button>
								</div>
							</div>
						</form>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}
export default Pricing;