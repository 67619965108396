import React,{Component} from "react";
import { Link} from "react-router-dom";
import '../../common.css';
import '../body/help.css';

class Help extends Component{
	componentDidMount(){
		document.title = "CDT - Help & Support"
	}

	render(){
		return (
			<div id="helpPage">
				<div id="landingHeader" className="landing-header">
					<Link id="logo" to="/landing">
						<div className="pull-left">
							<div className="lh-left">
								[<span className="logo-mn">mn</span>] <span className="hidden-xs">Collaborative Documentation Tool</span>
								 <span className="visible-xs">CDT</span>
							</div>
						</div>
					</Link>	
					<div className="pull-right">
						<ul className="lr-links">
							<li><Link to="/pricing" id="pricing">Pricing</Link></li>
							<li><Link to="/home/all/nodes" id="tryDemo" className="btn btn-demo">Try demo!</Link></li>
						</ul>
					</div>
					<div className="clearfix"></div>
				</div>
				<div id="helpPage" className="container">
					<div id="helpName">Help & Support</div>
					<div id="about" className="help-chapter">
						<div className="help-chapter-name">What is [mn] Collaborative Documentation Tool?</div>
						<div className="help-chapter-text">
							<p className="help-paragraph"> 
								With <span className="mn-logo"><strong>[<span className="cls-cdt">mn</span>] CDT</strong></span> you can stay organized in your daily work and have every piece of valuable business information documented properly. Alone or together with the team, you can immediately get a perfect looking document without carrying about design and only focusing all work on the creation of real information.
							</p>
							<p className="help-paragraph"> 
								Create unlimited structures in <span className="mn-logo"><strong>[<span className="cls-cdt">mn</span>] CDT</strong></span> with the team. Collaborators can work simultaneously and every change is displayed in real-time! Everybody involved will be notified about changes in data. Build your knowledge base and share your experience with the team.
							</p>
							<p className="help-paragraph"> 
								Do everything right inside text via very few pressing of letters on the keyboard or your touchscreen. Create TODOs and invite people, schedule dates and set priorities, add own parameters and get summary right-in-place and right-in-time explore how easy it is with <span className="mn-logo"><strong>[<span className="cls-cdt">mn</span>] CDT</strong></span>.
							</p>
							<p className="help-paragraph">
								Get every your idea collected and processed in the right way in <span className="mn-logo">[<span className="cls-cdt">mn</span>] CDT</span>. No loss of valuable information or idea anymore! See on controllable growing your data and generate different business documents using a lot of available templates. Reuse your achievements, use the various models to improve your business with us.
							</p>
							<p className="help-paragraph"> 
								<span className="mn-logo">[<span className="cls-cdt">mn</span>] CDT</span> is a rich text editor. You can write documentation in any language. The text can be formatted as bold, <em>italic</em>. Links, images, and videos can be easily embedded.
							</p>
							<p className="help-paragraph">
								Access to <span className="mn-logo">[<span className="cls-cdt">mn</span>] CDT</span> is protected by code. All data exchanged is encrypted. Also, you can choose password protection and even set PIN code to prevent any leaks of business-sensitive information.
							</p>
							<p className="help-paragraph"> 
								Share any information with <span className="mn-logo"><strong>[<span className="cls-cdt">mn</span>] CDT</strong></span> in the simplest way, like sharing the link by email or in chat messenger. Assign roles to invited persons to control accessibility.
							</p>
						</div>
					</div>
					<div className="help-chapter">
						<div className="help-chapter-name">User manual</div>
						<div className="help-chapter-text">
							<div id="manualTOC">
								<span className="manual-toc-title">What we will describe</span>
								<ul>
									<li className="manual-toc-link"><a href="#tipsTutorials">Tips & Tutorials</a></li>
									<li className="manual-toc-link"><a href="#projectsManagement">Projects management</a></li>
									<li className="manual-toc-link"><a href="#templatesWorkflows">Templates and workflows</a></li>
								</ul>
							</div>
							<div id="tipsTutorials" className="manual-article">
								<div className="manual-article-name">
									Tips & Tutorials
								</div>
								<div className="manual-article-text"> 
									Want to use the only keyboard for input and need to know how to do that fast? Check out our video tutorial that shows how to use keyboard short cuts and built-in command line to increase the speed of your input in the most efficient way!
								</div>
								<div className="help-content-body center-img">
									<img src="images/tools_31.jpg" className="manual-article-image" alt="tutorials"/>
								</div>
							</div>
							<div id="projectsManagement" className="manual-article">
								<div className="manual-article-name">
									Projects management
								</div>
								<div className="manual-article-text">
									Have a complex projects and need to organize them properly? Want to involve people to work collaborative? See how easy that can be done with <span className="mn-logo">[<span className="cls-cdt">mn</span>] CDT</span>.
								</div>
								<div className="help-content-body center-img">
									<img src="images/administration.jpg" className="manual-article-image" alt="projects"/>
								</div>
							</div>
							<div id="templatesWorkflows" className="manual-article">
								<div className="manual-article-name">
									Templates & workflows
								</div>
								<div className="manual-article-text"> 
									Need to generate a nice and modern looking document by your data? Want to have the process of making a decision fully transparent and standardized? <span className="mn-logo">[<span className="cls-cdt">mn</span>] CDT</span> offers various templates to do all that.
								</div>
								<div className="help-content-body center-img">
									<img src="images/katalog.jpg" className="manual-article-image" alt="templates"/>
								</div>
							</div>
						</div>
					</div>
					<div className="help-chapter">
						<div className="help-chapter-name">
							Support
						</div>
						<div className="help-chapter-text">
							<div className="contacts-company">
								[<span className="cls-cdt">mn</span>]medianet
							</div>
							<div className="contacts-owner">
								Inh. Margit Hermsen
							</div>
							<div className="contacts-address">
								Am Taubhaus 29
							</div>
							<div className="contacts-city">
								D 63303 Dreieich
							</div>
							<div className="contacts-phone">
								<span className="glyphicon glyphicon-phone-alt"></span> +49 (0) 6103/6977 84
							</div>
							<div className="contacts-fax">	
								<span className="glyphicon glyphicon-print"></span> +49 (0) 6103/6977 85
							</div>
							<div className="contacts-email">	
								<span className="glyphicon glyphicon-envelope"></span> <a href="mailto:info@medianet-home.de" rel="nofollow">info@medianet-home.de</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default Help;