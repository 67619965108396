import axios from 'axios'
import ServiceURL from '../../constants/constants.js'
import {
	LOGIN_KEY_REQUEST,
	LOGIN_KEY_SUCCESS,
	LOGIN_KEY_FAIL,
	LOGIN_USER_REQUEST,
	LOGIN_USER_SUCCESS,
	LOGIN_USER_FAIL,
	SIGNUP_KEY_REQUEST,
	SIGNUP_KEY_SUCCESS,
	SIGNUP_KEY_FAIL,
	SIGNUP_USER_REQUEST,
	SIGNUP_USER_SUCCESS,
	SIGNUP_USER_FAIL,
} from './userTypes'

const CDTURL = ServiceURL+'users_api/'
const LOGINKEYGEN = 'loginKeyGen'
const LOGINVERIFY = 'loginKeyVerify'
const SIGNUPKEYGEN = 'genKey'
const SIGNUPVERIFY = 'signupKeyVerify'

export const  loginKeyGenAction = user =>{
	return ( dispatch) => {
		dispatch(loginKeyRequest());
	 	axios.post(CDTURL + LOGINKEYGEN,user)
		.then(response=>{
			const users = response.data
			dispatch(loginKeySuccess(users))
		})
		.catch(error=>{
			dispatch(loginKeyFail(error.message));
		})
	}
}

export const loginKeyRequest = () =>{
	return{
		type: LOGIN_KEY_REQUEST,
	}
}

export const loginKeySuccess = users =>{
	return{
		type: LOGIN_KEY_SUCCESS,
		payload: users
	}
}

export const loginKeyFail = error =>{
	return{
		type: LOGIN_KEY_FAIL,
		payload: error
	}
}

export const loginUser = (user) =>{
	return async(dispatch) => {
		dispatch(loginUserRequest())
		await axios.post(CDTURL + LOGINVERIFY,{user})
		.then(response=>{
			const users = response.data
			const error = response.data.message
			if(response.data.status==="fail"){
			dispatch(loginUserFail(error))
		}else{
			dispatch(loginUserSuccess(users))
		}
		})
		.catch(error=>{
			dispatch(loginUserFail(error))
		})
	}
}

export const loginUserRequest = () =>{
	return{
		type: LOGIN_USER_REQUEST
	}
}

export const loginUserSuccess = user =>{
	return {
		type: LOGIN_USER_SUCCESS,
		payload: user
	}
}
export const loginUserFail = error =>{
	return {
		type: LOGIN_USER_FAIL,
		payload: error
	}
}

export const signUpKeyGen = (user) =>{
	return (dispatch) => {
		dispatch(signUpKeyRequest());
		axios.post(CDTURL + SIGNUPKEYGEN,{user})
		.then(response=>{
			const users = response.data
			dispatch(signUpKeySuccess(users))
		})
		.catch(error=>{
			dispatch(signUpKeyFail(error.message));
		})
	}
}

export const signUpKeyRequest = () =>{
	return{
		type: SIGNUP_KEY_REQUEST,
	}
}

export const signUpKeySuccess = users =>{
	return{
		type: SIGNUP_KEY_SUCCESS,
		payload: users
	}
}

export const signUpKeyFail = error =>{
	return{
		type: SIGNUP_KEY_FAIL,
		payload: error
	}
}

export const signUpUser = (user) =>{
	return (dispatch) => {
		dispatch(signUpUserRequest())
		axios.post(CDTURL + SIGNUPVERIFY,{user})
		.then(response=>{
			const users = response.data
			dispatch(signUpUserSuccess(users))
		})
		.catch(error=>{
			dispatch(signUpUserFail(error))
		})
	}
}
export const signUpUserRequest = () =>{
	return{
		type: SIGNUP_USER_REQUEST
	}
}
export const signUpUserSuccess = user =>{
	return {
		type: SIGNUP_USER_SUCCESS,
		payload: user
	}
}
export const signUpUserFail = error =>{
	return {
		type: SIGNUP_USER_FAIL,
		payload: error
	}
}