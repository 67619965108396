import React,{Component} from "react";
import cookie from 'react-cookies';
import axios from "axios";
import {Link} from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import '../landing/landing-header.css';
import '../landing/landing-footer.css';
import '../landing/landingstyle.css';
import ServiceURL from '../../../constants/constants';



class Signup extends Component{
	constructor(props){
		super(props);
		this.state = {
			email: this.props.location.state?this.props.location.state:'',
			emailError:'',
			emailErrorMsg:'',
			inputClassName:'form-control',
		}
	}
	componentDidMount(){
		document.title = "CDT - Sign up"
	}
	handleChange = event => {
		this.setState({
			[event.target.name]:event.target.value
		});
	}
	componentWillMount = ()=>{
		if(cookie.load('userAcc')){
			this.props.history.push({
				pathname: '/home/all/nodes',
				state: this.state.email,
				typeLogin: 'Normal'
			});
		}

	}
	handleSubmit = (event)=> {
		event.preventDefault();
		var date = new Date();
		var timestamp = date.getFullYear() + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + (date.getDate() < 10 ? '0' : '') + date.getDate() + (date.getHours() < 10 ? '0' : '') + date.getHours() + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds() + Math.ceil(Math.random() * 1000);

		const user = {
			email: this.state.email,
			timekey: timestamp
		};
		 let emailValid;
		if(this.state.email === undefined){
			emailValid = false;
		}
		else
		{
		emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

		}
		let fieldValidationErrors = emailValid ? true : false;
		if(fieldValidationErrors===false){
			this.setState({
				emailError: false,
				inputClassName: 'form-control input-error',
				emailErrorMsg: <span id="invalidEmail" class="cls-danger">Please point your actual email address.</span>
			})
		}
		else
		{
			this.setState({
				emailError: true,
				emailErrorMsg: " ",
				inputClassName: 'form-control'
			})
			this.serviceCall(user);
		}
		return false;
	}
	resendSignupCode = async()=>{
		 const user = {
			email: this.state.email
		};
		 var self = this;
		await axios.post(ServiceURL+`users_api/signupResendKey`, { user }).then(function (response) {
			self.renderRedirect();
		});

	}


	serviceCall = async(user)=>{
		var self = this;
		await axios.post(ServiceURL+`users_api/genKey`, { user }).then(function (response) {
			if(response.data.status ==="fail"){
				if(response.data.message === "unverified"){
					self.setState({
						emailError:false,
						inputClassName: 'form-control input-error',
						emailErrorMsg:<span className="cls-danger">Lost letter with code?<button type="button"  id="sendCode" className="button-link wantBtn" onClick={self.resendSignupCode}>send me code again</button> </span>
					});
				}
				else if(response.data.message === "verified"){
					self.setState({
						emailError:false,
						inputClassName: 'form-control input-error',
						//<div><span id="alreadyRegisteredEmail" class="cls-danger">This email already have an account.</span><button id="wantLogin" class="button-link wantBtn">Want to log in?</button></div>
						emailErrorMsg:<div><span id="alreadyRegisteredEmail" className="cls-danger"> This email already have an account.</span> <button  id="wantLogin" className="button-link wantBtn" onClick={self.loginRedirect}>Want to log in?</button></div>
					});
				}
			}
			else{
				self.renderRedirect();
			}
		});
	}
	renderRedirect = () => {
		this.props.history.push(
		{
			pathname:'/signupkey',
			state:this.state.email
		});
	}

	loginRedirect = () =>{
		var date = new Date();
		var timestamp = date.getFullYear() + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + (date.getDate() < 10 ? '0' : '') + date.getDate() + (date.getHours() < 10 ? '0' : '') + date.getHours() + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds() + Math.ceil(Math.random() * 1000);

		const user = {
			email: this.state.email,
			timekey: timestamp
		};
		var self = this;
	 axios.post(ServiceURL+`users_api/loginKeyGen` , { user }).then(function (response) {
					self.props.history.push({
						pathname:'/loginkey',
						state:self.state.email
					});
				});
	}

	renderRedirectMain = () => {
		this.props.history.push(
		{
			pathname:'/home/all/nodes',
			state:this.state.email,
			typeLogin: 'Normal'
					});
	}
	landingRedirect =()=>{
		this.props.history.push({
			pathname:'/landing'
		})
	}
	responseGoogle = (googleUser) => {
		var profile = googleUser.getBasicProfile();
		console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
		console.log('Name: ' + profile.getName());
		console.log('Image URL: ' + profile.getImageUrl());
		console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
	}
	responseFacebook = (response) => {
		console.log(response);
		NotificationManager.success("key verified successfully applicaiton comming soon!!", "Success",5000);
		this.renderRedirectMain();
	}

	facebookButtonAlert = ()=>{
		alert("this functionality will coming soon");
	}
	googleButtonAlert = ()=>{

		alert("this functionality will coming soon");
	}
	facebookButtonAlert = ()=>{
		alert("this functionality will coming soon");

	}

	render(){
		return(<div id="signUpPage">
	 <div className="landing-header">
			<div id="logo" onClick={this.landingRedirect}>
					[<span className="logo-mn">mn</span>]&nbsp;<span className="visible-cdt hidden-xs">Collaborative Documenting Tool</span>
					<span className="visible-cdt visible-xs">CDT</span>
			</div>
			<div className="button">
				<Link to="/login" id="logIn" className="btn btn-sm btn-primary">Log in</Link>
			</div>
			<div className="clearfix"></div>
	 </div>
	 <div className="container-fluid">
			<div className="login-wrapper">
				 <div id="signUpPage" className="login-box login-box-shadow">
						<p className="land-big-text"> Sign up for <strong>[<span className="cls-cdt">mn</span>]&nbsp;CDT</strong> </p>
						<form onSubmit={this.handleSubmit}  className="land-input">
							 <div className="form-group">
									<input type="text" name="email" id="email" onChange={this.handleChange} value={this.state.email} className={this.state.inputClassName} placeholder="Enter your email address"/>
									<div className="cls-danger land-small-text"> {this.state.emailErrorMsg} </div>
							 </div>
							 <div className="form-group">
									<button type="submit" id="continue" className="btn btn-block btn-warning btn-textlc">Get started with email</button>
							 </div>
							 <p className="land-small-text">or sign up with</p>
							 <div className="hide">
									<FacebookLogin appId="1017343528596936" autoLoad={false} fields="name,email,picture" callback={this.responseFacebook}
										 cssClass="btn facebook-btn" />
							 </div>
							<div className="social-btns">
								<div id="facebook" className="btn-group">
									<button type="button" onClick={this.facebookButtonAlert} className="btn facebook-btn">
										<img src="images/facebook_new.png" alt="fb" width="16"/>
									</button>
									<button type="button" className="btn facebook-btn" onClick={this.facebookButtonAlert}>Facebook</button>
								</div>
								<div id="google" className="btn-group btn-group-margin">
									<button type="button" onClick={this.googleButtonAlert} className="btn google-btn-G">
										<img src='images/google_new.png' alt="google" width="16"/>
									</button>
									<button type="button" onClick={this.googleButtonAlert} className="btn google-btn">Google</button>
								</div>
								<div id="twitter" className="btn-group">
									<button type="button" onClick={this.twitterButtonAlert} className="btn twitter-btn">
										<img src="images/twitter-logo.png" alt="twitter" width="16"/>
									</button>
									<button type="button" onClick={this.twitterButtonAlert} className="btn twitter-btn">Twitter</button>
								</div>
							</div>
						</form>
						<NotificationContainer/>
				 </div>
			</div>
	 </div>
</div>
		)
	}
}
export default Signup;