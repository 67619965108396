import React, { Component } from "react";
import axios from "axios";
import styled from 'styled-components'
import '../body/Users.css';
import Select from 'react-select'; // see  https://react-select.com/
import CreatableSelect from 'react-select/creatable'; // see https://react-select.com/creatable
import ServiceURL from '../../constants/constants';

import {
	Form,
	FormGroup,
	InputGroup,
	Popover,
	Overlay
} from 'react-bootstrap';

const StyledPopover = styled(Popover)
`min-width: 600px;`

class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: [],
			selectedUsers: [],
			roles: []
		}
	}
	componentDidMount(){
		var CDTURL = ServiceURL+"users_api/getAllUsers";
		axios.post(CDTURL).then(response =>{
			var dblist = response.data.result;
			//console.log(dblist);
			this.setState({
				users: dblist
			});
		});
		var self =this
		 CDTURL = ServiceURL+"N_rights_api/roles";
        axios.get(CDTURL).then(response => {
            var allroles = response.data;
            self.setState({
                roles: allroles
            });
        });
	}

	isValidEmail = (email) => {
		var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		return re.test(String(email).toLowerCase());
	}

	usersSearch = (option, searchText) => {
		var user = option.data;

		var text = searchText.toLowerCase();

		if (user.email == null) {
			if (this.isValidEmail(searchText)) {

				var newUser = this.createNewUser(searchText);

				user.email = newUser.email;
				user.name = 'Invite: ' + newUser.name;
				user.nickname = newUser.nickname;

				return true;
			}
			else
				return false;
		}

		return ((user.name != null) && (user.name.toLowerCase().indexOf(text) !== -1))
			|| ((user.nickname != null) && (user.nickname.toLowerCase().indexOf(text) !== -1))
			|| ((user.email != null) && (user.email.toLowerCase().indexOf(text) !== -1));
	}

	closePopup = (event) => {
		this.setState({show: false});
	}

	popupShown = () => {
		var input = this.getUsersFilterInput();
		input.focus();

		/*TODO -oDM : Here should be showing of selected users inside input*/
		//this.setState({selectedUsers: []});
	}

	popupHide = (event) => {
		this.setState({show: false});
		this.state.target.focus();
	}

	getUsersFilterInput = () => {
		var container = document.getElementById("usersFilter");
		var input = container.getElementsByTagName('input')[0];
		return input;
	}

	userIsSelected = (userid) => {
		//return this.userGet(userid).selected;
		return false;
	}

	userGet = (userid) => {
		var users = this.state.users.filter(function(item){
			return ((item.userid === userid))
		});

		if (users.length > 0) {
			return users[0]
		}
		else {
			return null;
		}
	}

	/* TODO -oDima: this code should be moved into handler of key down inside editor */
	testKeyDown = (event) => {
		if ((event.shiftKey === true) && (event.keyCode === 50 /*@ key */)) {
			event.preventDefault();
			event.stopPropagation();
			this.setState({target: event.target, show: true});
		}

		if (this.state.show) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	getUserLabel = (item) => {
		return (
		<div className="user" id={item.userid}>
			<img className="user-avatar" title="Photo" alt="profilePic" src="images/user1.png"/>
			<div className="user-wrap">
				<div className="user-names-wrap">
					<span className="user-name">{item.name} </span>
					<span className="user-nick">({item.nickname})</span>
				</div>
				<div className="user-email">{item.email}</div>
			</div>
		</div>)
	}

	usersChanged = (users, actionMeta) => {
		if (users == null) {
			this.setState({
				selectedUsers: []
			});
		}
		else {
			this.setState({
				selectedUsers: users
			});
		}

		/* TODO -oDM : Here should be synchronization of selected users and input  */
		console.log(users);
	};

	createNewUser = (value) => {
		var email = value.toLowerCase();
		var nickname = email.split('@')[0];
		var name = nickname.charAt(0).toUpperCase() + nickname.slice(1);

		return {
			email: email,
			name: name,
			nickname: nickname
		}
	};

	userCreate = (inputValue) => {
		console.log(inputValue);
		console.log(this.state.selectedUsers);

		var newUser = this.createNewUser(inputValue);

		this.setState({
			users: [...this.state.users, newUser],
			selectedUsers: [...this.state.selectedUsers, newUser]
		});
	}

	render() {
		return (
			<div id="usersPage">
				<div className="test" suppressContentEditableWarning={true} placeholder="Type 1111@ symbol..." contentEditable={true} onKeyDown={this.testKeyDown} onChange={this.handleChange}> </div>
				<Overlay
					show={this.state.show}
					target={this.state.target}
					placement="bottom"
					container={this}
					rootClose={true}
					onEntered={this.popupShown}
					onHide={this.popupHide}
					>
					<StyledPopover id="usersPopup" className="dropdown-menu settings-list">
						<Form inline>
							<FormGroup className="users-popup-header">
								<Select
									id="usersRole"
									options={this.state.roles}
									defaultValue={this.state.roles[0]}
									getOptionLabel={(role)=>role.name}
									getOptionValue={(role)=>role.id}
									/>

								<InputGroup className="users-filter">
									<InputGroup.Addon className="users-at">@</InputGroup.Addon>

									<CreatableSelect
										closeMenuOnSelect={false}
										isClearable={false}
										filterOption={this.usersSearch}
										getOptionLabel={this.getUserLabel}
										getOptionValue={(user)=>user.email}
										id="usersFilter"
										isMulti={true}
										isSearchable={true}
										name="usersFilter"
										onChange={this.usersChanged}
										onCreateOption={this.userCreate}
										options={this.state.users}
										placeholder="Type user name or email here"
										value={this.state.selectedUsers}
									  />
								</InputGroup>

								<div id="usersPopupClose" onClick={this.closePopup} title="Close popup">
									<span className="glyphicon glyphicon-remove"></span>
								</div>
							</FormGroup>
						 </Form>
					</StyledPopover>
				</Overlay>
			</div>
		)
	}
}
export default Users;