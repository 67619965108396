import React from 'react';
import {Editor, EditorState, RichUtils} from 'draft-js';
import Immutable from 'immutable';
const blockRenderMap = Immutable.Map({
  'header-two': {
    element: 'h6'
  },
  'unstyled': {
    element: 'h6'
  }
});

class Draft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {editorState: EditorState.createEmpty()};
    this.onChange = editorState => this.setState({editorState});
    this.handleKeyCommand = this.handleKeyCommand.bind(this);

  }

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      console.log('handled');
      return 'handled';
    }
      console.log('not-handled');
    return 'not-handled';
  }
    _onBoldClick() {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'));
  }
  render() {
    return (
     <div>
        <button onClick={this._onBoldClick.bind(this)}>Bold</button>
        <Editor
          editorState={this.state.editorState}
          handleKeyCommand={this.handleKeyCommand}
          onChange={this.onChange}
          blockRenderMap={blockRenderMap}
        />
      </div>
    );
  }
}

export default Draft;

