import React,{Component} from "react";
import cookie from 'react-cookies'
import axios from "axios";
import {Link} from 'react-router-dom';
import LandingHeader from '../landing/landingheader';
import LandingFooter from '../landing/landingfooter';
import  '../../../css/style.css';
import  '../landing/landing-header.css';
import  '../landing/landingstyle.css';
import ServiceURL from '../../../constants/constants';

class Landingcdt extends Component{
	constructor(props){
		super(props);
		this.state = {
			email: '',
			emailError:true,
			emailErrorMsg:"",
			inputClassName:'form-control input-lg'
		}
	}

	handleChange = event => {
		this.setState({
			[event.target.name]:event.target.value.trim()
		});
	}
	UNSAFE_componentWillMount = ()=>{
		if(cookie.load('userAcc')){
			this.props.history.push({
				pathname: '/home/all/nodes',
				state: this.state.email,
				typeLogin: 'Normal'
			});
		}

	}

	handleSubmit = (event)=> {
		event.preventDefault();
		var date = new Date();
    var timestamp = date.getFullYear() + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + (date.getDate() < 10 ? '0' : '') + date.getDate() + (date.getHours() < 10 ? '0' : '') + date.getHours() + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds() + Math.ceil(Math.random() * 1000);
		const user = {
			email: this.state.email,
			timekey:timestamp
		};
		let emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
		let fieldValidationErrors = emailValid ? true : false;
		if(fieldValidationErrors===false){
			this.setState({
				emailError: false,
				emailErrorMsg: "Please point your actual email address",
				inputClassName: 'form-control input-lg input-error'
			})
		}
		else{
			this.setState({
				emailError: true,
				emailErrorMsg: " ",
				inputClassName: 'form-control input-lg'
			})
			this.serviceCall(user);
		}
		return false;
	}
	resendSignupCode = async()=>{
		const user = {
			email: this.state.email
		};
		var self = this;
		await axios.post(ServiceURL+`users_api/signupResendKey`, { user }).then(function (response) {
			self.renderRedirect();
		});
	}
	serviceCall = async(user)=>{
		var self = this;
		await axios.post(ServiceURL+`users_api/genKey`, { user }).then(function (response) {
			if(response.data.message === "verified"){
				self.setState({
					emailError:false,
					emailErrorMsg:<span className="cls-danger"> This E-mail already have an account <button type="button" className="button-link wantBtn" onClick={self.loginRedirect}>Want to log in?</button> </span>
				});
				axios.post(ServiceURL+`users_api/loginKeyGen` , { user }).then(function (response) {
					self.props.history.push({
						pathname:'/loginkey',
						state:self.state.email
					});
				});
			}
			else{
				if(response.data.status ==="fail"){
					if(response.data.message === "unverified"){
						self.setState({
							emailError:false,
							inputClassName: 'form-control input-error',
							emailErrorMsg:<span className="cls-danger"> Lost E-mail with code <button type="button" className="button-link wantBtn" onClick={self.resendSignupCode}>send me code again</button> </span>
						});
					}
				}
				else{
					self.renderRedirect();
				}
			}
		});
	}
	renderRedirect = () => {
		this.props.history.push({
			pathname:'/signupkey',
			state:this.state.email
		});
	}
	facebookButtonAlert = ()=>{
		alert("facebook login functionality comming soon");
	}
	googleButtonAlert = ()=>{
		alert("Google login functionality comming soon");
	}
	twitterButtonAlert = ()=>{
		alert("Twitter login functionality comming soon");
	}
	render(){
		return(
			<div id="landingPage">
				<LandingHeader />


				<div id="landingPage" className="cdt-new-land">

					<div id="promo" className="container">
						<div className="cdthome-content center">
							<p className="land-big-text">
								<span className="promo-question">Overloaded in unsafe chaos? </span>
								<span className="promo-answer">We can help!</span>
							</p>

							<span className="promo-text"> With <strong>[<span className="cls-cdt">mn</span>] CDT</strong> you can stay organized in your daily work and have every piece of valuable business information documented properly in safe and confidential environment. Alone or together with the team, you can immediately get a perfect looking document without carrying about design and only focusing all work on the creation of real information.&nbsp;</span>
							<Link to="/help" id="learnMore" className="promo-link">Learn more</Link>

							<div>&nbsp;</div>
							<form className="form-inline" onSubmit={this.handleSubmit}>
								<div className="form-group">
									<input type="text" name="email" id="email"  onChange={this.handleChange} value={this.state.email} className={this.state.inputClassName} placeholder="Enter your email address" size="40" />
								</div>
								&nbsp;<button id="continue" type="submit" className="btn btn-default btn-lg">Continue</button>
								<br/><span id="invalidEmail" className="cls-danger"> {this.state.emailErrorMsg}</span>
							</form>
							<div>&nbsp;</div>
							<span className="land-small-text">or continue with&nbsp;</span>
							<div>&nbsp;</div>
							<div className="social-btns">
								<div id="facebook" className="btn-group">
									<button type="button" onClick={this.facebookButtonAlert} className="btn facebook-btn">
										<img src="images/facebook_new.png" alt="fb" width="16"/>
									</button>
									<button type="button" className="btn facebook-btn" onClick={this.facebookButtonAlert}>Facebook</button>
								</div>
								<div id="google" className="btn-group btn-group-margin">
									<button type="button" onClick={this.googleButtonAlert} className="btn google-btn-G">
										<img src='images/google_new.png' alt="google" width="16"/>
									</button>
									<button type="button" onClick={this.googleButtonAlert} className="btn google-btn">Google</button>
								</div>
								<div id="twitter" className="btn-group">
									<button type="button" onClick={this.twitterButtonAlert} className="btn twitter-btn">
										<img src="images/twitter-logo.png" alt="twitter" width="16"/>
									</button>
									<button type="button" onClick={this.twitterButtonAlert} className="btn twitter-btn">Twitter</button>
								</div>
							</div>
							<div>&nbsp;</div>
							<div>&nbsp;</div>
							<span className="have-text">Already have an account?&nbsp;<Link to="/login" id="logInLink"  className="promo-link cls-cdt">Log in</Link></span>
						</div>
					</div>
				</div>
				<div id="infos"className="cdt-content-sub bg-land-fgrey">
					<div className="container">
						<div className="row">
							<div className="col-md-4 col-sm-4 col-xs-12">
								<div className="cdt-services">
									<div className="sblock-icon">
										<img src="images/c1.png" className="img-responsive" alt="c1"/>
									</div>
									<h2 className="info-name">Collaborative</h2>
									<p className="info-text">Create unlimited structures in <strong>[<span className="cls-cdt">mn</span>] CDT</strong> with the team. Collaborators can work simultaneously and every change is displayed in real-time! Everybody involved will be notified about changes in data. Build your knowledge base and share your experience with the team.
									</p>
								</div>
							</div>

							<div className="col-md-4 col-sm-4 col-xs-12">
								<div className="cdt-services">
									<div className="sblock-icon">
										<img src="images/natural_input.png" className="img-responsive" alt="natural_input" />
									</div>
									<h2 className="info-name">Natural input</h2>
									<p className="info-text">Do everything right inside text via very few pressing of letters on the keyboard or your touchscreen. Create TODOs and invite people, schedule dates and set priorities, add own parameters and get summary right-in-place and right-in-time explore how easy it is with <strong>[<span className="cls-cdt">mn</span>] CDT</strong>.
									</p>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-xs-12">
								<div className="cdt-services">
									<div className="sblock-icon"><img src="images/businee_mgmt.png" className="img-responsive" alt="businee_mgmt"/></div>
									<h2 className="info-name">Business and management</h2>
									<p className="info-text"> Get every your idea collected and processed in the right way in <strong>[<span className="cls-cdt">mn</span>] CDT</strong>. No loss of valuable information or idea anymore! See on controllable growing your data and generate different business documents using a lot of available templates. Reuse your achievements, use the various models to improve your business with us.
									</p>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4 col-sm-4 col-xs-12">
								<div className="cdt-services">
									<div className="sblock-icon">
										<img src="images/text_editor.png" className="img-responsive" alt="text_editor"/>
									</div>
									<h2 className="info-name">Text editor</h2>
									<p className="info-text"> <strong>[<span className="cls-cdt">mn</span>] CDT</strong> is a rich text editor. You can write documentation in any language. The text can be formatted as <strong>bold</strong>, <em>italic</em>. Links, images, and videos can be easily embedded. </p>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-xs-12">
								<div className="cdt-services">
									<div className="sblock-icon">
										<img src="images/shafety_security.png" className="img-responsive" alt="saftey_security" />
									</div>
									<h2 className="info-name">Safety and security</h2>
									<p className="info-text"> Access to <strong>[<span className="cls-cdt">mn</span>] CDT</strong> is protected by code. All data exchanged is encrypted. Also, you can choose password protection and even set PIN code to prevent any leaks of business-sensitive information.
									</p>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-xs-12">
								<div className="cdt-services">
									<div className="sblock-icon">
										<img src="images/sharing.png" className="img-responsive" alt="sharing"/>
									</div>
									<h2 className="info-name">Simple sharing</h2>
									<p className="info-text"> Share any information with <strong>[<span className="cls-cdt">mn</span>] CDT</strong> in the simplest way, like sharing the link by email or in chat messenger. Assign roles to invited persons to control accessibility.
									</p>
									 </div>
							      </div>

							      </div>
							      <div className="row">
							<div className="col-md-4 col-sm-4 col-xs-12">
								<div className="cdt-services">
									<div className="sblock-icon">
										<img src="" className="img-responsive" alt=""/>
									</div>
									<h2 className="info-name">Designed to work everywhere</h2>
									<p className="info-text">  The application is designed to work on the latest versions of all modern browsers on any device. No need to install anything, just press the demo d button and start working on your next text masterpiece alone or with a group of friends. wich you add easily by sending them a predefined invitation mail wich you, of course, can edit to welcome them individual ore standardized. </p>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-xs-12">
								<div className="cdt-services">
									<div className="sblock-icon">
										<img src="images/" className="img-responsive" alt="" />
									</div>
									<h2 className="info-name">All text together!</h2>
									<p className="info-text"> If you've ever wanted to share the joy of thinking writing planning managing live connected with a friend or with a group of people, privately commercially in organizations or companies, now you can do that using CDT! Create a Project and text together even if you are on the other side of the galaxy.
									</p>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-xs-12">
								<div className="cdt-services">
									<div className="sblock-icon">
										<img src="images/" className="img-responsive" alt=""/>
									</div>
									<h2 className="info-name">Under development</h2>
									<p className="info-text"> The application is currently under development and there are missing a lot of features that will be added as soon as possible. Since it's still in the alpha stage of development, you can notice some bugs and issues that may cause a loss or corruption of your work. Please be sure to save the needed if you want to keep them. We will also maintain a retrievable backup for the worst cases.
									</p>
									 </div>
							      </div>

							      </div>



					</div>
				</div>
				<div id="helps" className="cdt-content-sub">
					<div className="container">
						<div className="feature-block">
							<div className="row">
								<div className="col-md-6 col-sm-6 col-xs-12">
									<div>
										<img src="images/tools_31.jpg" className="img-responsive" alt="sample_img" />
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-xs-12">
									<div className="feature-content">
										<h2 className="help-name">Tips & Tutorials</h2>
										<p className="help-text"> Want to use the only keyboard for input and need to know how to do that fast? Check out our video tutorial that shows how to use keyboard short cuts and built-in command line to increase the speed of your input in the most efficient way! </p>
										<Link to="/help" id="tipsTutorials" className="help-link">Learn more</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="feature-block">
							<div className="row">
								<div className="col-md-6 col-sm-6 col-xs-12">
									<div className="feature-content">
										<h2 className="help-name">Projects management</h2>
										<p className="help-text"> Have a complex projects and need to organize them properly? Want to involve people to work collaborative? See how easy that can be done with <strong>[<span className="cls-cdt">mn</span>] CDT</strong>.
										</p>
										<Link to="/help" id="projectsManagement" className="help-link f-leranmore">Learn more</Link>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-xs-12">
									<div>
										<img src="images/administration.jpg" className="img-responsive" alt="sample_img" />
									</div>
								</div>
							</div>
						</div>
						<div className="feature-block">
							<div className="row">
								<div className="col-md-6 col-sm-6 col-xs-12">
									<div>
										<img src="images/katalog.jpg" className="img-responsive" alt="sample_img" />
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-xs-12">
									<div className="feature-content">
										<h2 className="help-name">Templates and workflows</h2>
										<p className="help-text"> Need to generate a nice and modern looking document by your data? Want to have the process of making a decision fully transparent and standardized? <strong>[<span className="cls-cdt">mn</span>] CDT</strong> offers various templates to do all that.
										</p>
										<Link to="/help" id="templatesWorkflows" className="help-link f-leranmore">Learn more</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<LandingFooter />
			</div>
		)
	}
}
export default Landingcdt;