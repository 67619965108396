const editorjs = {
	nodeClass: 'node',
	nodeNumberClass: 'node-number',
	nodeNameClass: 'node-name',
	nodeDescriptionClass: 'node-description',
	nodeChildsClass: 'nodes',
	userNameClass: 'user-name',
	usersGroupClass: 'group',
	usersClass: 'users',

	nodeChildsFoldClass: 'fold-nodes',
	collapsedChildsIcon: 'chevron_right',
	expandedChildsIcon: 'expand_more',
	
	nodeControlClass: 'node-control',
	nodeFolderClass: 'node-folder',
	nodeMenuClass: 'node-menu',
	nodeStateClass: 'node-state',
	
	nodeDescriptionFoldClass: 'fold-description',
	collapsedDescriptionIcon: 'more_horiz',
	expandedDescriptionIcon: 'more_vert',

	readableFontSize: 18.333,

	isVisible: function(element) {
		if (element.nodeType === 1) {
			if (element.tagName === 'BODY') {
				return true;
			}

			let style = window.getComputedStyle(element);
			return (style.display !== "none") && (style.visibility !== "hidden");
		} 
		else {
			return editorjs.isVisible(element.parentElement);
		}
	},

	isEditable: function(element) {
		if ((element === null) || (element.tagName === 'BODY')) {
			return false;
		} else {
			if ((element.nodeType === 1) && element.hasAttribute('contenteditable')) {
				return element.getAttribute('contenteditable') !== 'false';
			} else {
				return false;
			}
		}
	},

	isReadOnly: function(element) {
		if ((element === null) || (element.tagName === 'BODY')) {
			return false;
		} else {
			if ((element.nodeType === 1) && element.hasAttribute('readonly')) {
				return element.getAttribute('readonly') !== 'false';
			} else {
				return editorjs.isReadOnly(element.parentElement);
			}
		}
	},

	getNode: function(element) {
		if ((element.nodeType === 1) && element.classList.contains(editorjs.nodeClass)) {
			return element;
		} else {
			if ((element === null) || (element.parentElement === null) || (element.parentElement.tagName === 'BODY')) {
				return null; // no nodes above in hierarchy
			} else {
				return editorjs.getNode(element.parentElement); // go to higher level in hierarchy
			}
		}
	},

	getElementIndex: function(element) {
		if (element.parentElement === null) {
			return 0;
		} else {
			var childs = element.parentElement.childNodes;
			for (var i = 0; i < childs.length; i++) {
				if (childs[i] === element) {
					return i;
				}
			}
			return -1;
		}
	},

	childSkipped: function(child) {
		// skip text nodes and invisible nodes
		return (child.nodeType !== 1) || !editorjs.isVisible(child);
	},

	getLastEditable: function(element) {
		var childs = element.childNodes;
		var editable = null;
		var child;

		// find deepest editable starting from last child
		for (var i = childs.length - 1; i >= 0; i--) {
			child = childs[i];
			
			if (editorjs.childSkipped(child)) {
				continue;
			}

			// go deeper
			editable = editorjs.getLastEditable(child);

			if (editable !== null) {
				return editable;
			}
		}

		// no editable childs -> check element itself
		if (editorjs.isEditable(element)) {
			return element;
		} else {
			return null;
		}
	},

	getFirstEditable: function(element) {
		// check element itself
		if (editorjs.isEditable(element)) {
			return element;
		}

		var childs = element.childNodes;
		var editable = null;
		var child;

		// find top editable starting from first child
		for (var i = 0; i < childs.length; i++) {
			child = childs[i];

			if (editorjs.childSkipped(child)) {
				continue;
			}

			// go deeper
			editable = editorjs.getFirstEditable(child);

			if (editable !== null) {
				return editable;
			}
		}

		return null;
	},

	getEditableAbove: function(element) {
		var parent = element.parentElement;

		// root reached
		if ((parent === null) || (parent.tagName === 'BODY')) {
			return null;
		}

		var index = editorjs.getElementIndex(element);
		var childs = parent.childNodes;
		var child = null;
		var editable = null;

		// find editable in siblings above
		for (var i = index - 1; i >= 0; i--) {
			child = childs[i];

			if (editorjs.childSkipped(child)) {
				continue;
			}

			editable = editorjs.getLastEditable(child);
			if (editable !== null) {
				return editable;
			}
		}

		// find editable in parent siblings above
		while ((parent.parentElement !== null) && (parent.parentElement.tagName !== 'BODY')) {
			index = editorjs.getElementIndex(parent);
			childs = parent.parentElement.childNodes;

			for (i = index - 1; i >= 0; i--) {
				child = childs[i];

				if (editorjs.childSkipped(child)) {
					continue;
				}

				editable = editorjs.getLastEditable(child);
				if (editable !== null) {
					return editable;
				}
			}

			// go up in hierarchy
			parent = parent.parentElement;
			if (parent.tagName === 'BODY') {
				break;
			}
		}

		// reached top of hierarchy
		return null;
	},

	getEditableBelow: function(element) {
		var childs = element.childNodes;
		var editable = null;
		var child;
		var i;

		// find top editable starting from first child
		for (i = 0; i < childs.length; i++) {
			child = childs[i];

			if (editorjs.childSkipped(child)) {
				continue;
			}

			editable = editorjs.getFirstEditable(child);

			if (editable !== null) {
				return editable;
			}
		}

		var parent = element.parentElement;

		// root reached
		if ((parent === null) || (parent.tagName === 'BODY')) {
			return null;
		}

		var index = editorjs.getElementIndex(element);
		childs = parent.childNodes;
		editable = null;

		// find editable in siblings below
		for (i = index + 1; i < childs.length; i++) {
			child = childs[i];

			if (editorjs.childSkipped(child)) {
				continue;
			}

			editable = editorjs.getFirstEditable(child);
			if (editable !== null) {
				return editable;
			}
		}

		// find editable in parent siblings below
		while ((parent.parentElement !== null) && (parent.parentElement.tagName !== 'BODY')) {
			index = editorjs.getElementIndex(parent);
			childs = parent.parentElement.childNodes;

			for (i = index + 1; i < childs.length; i++) {
				child = childs[i];

				if (editorjs.childSkipped(child)) {
					continue;
				}

				editable = editorjs.getFirstEditable(child);
				if (editable !== null) {
					return editable;
				}
			}

			// go up in hierarchy
			parent = parent.parentElement;
		}

		// reached top of hierarchy
		return null;
	},

	getEditable: function(element) {
		if ((element.nodeType === 1) && editorjs.isEditable(element)) {
			return element;
		}

		var parent = element.parentElement;

		// root reached
		if ((parent === null) || (parent.tagName === 'BODY')) {
			return null;
		}

		return editorjs.getEditable(parent);
	},

	getTextLength: function(element) {
		var textLength = 0;

		if (element.length !== undefined) {
			textLength += element.length;
		}

		var childs = element.childNodes;
		for (var i = 0; i < childs.length; i++) {
			textLength += editorjs.getTextLength(childs[i]);
		}

		return textLength;
	},

	getStartPosObject: function(element, target, startPos) {
		if (element === target) {
			return { pos: startPos, found: true };
		}

		if (element.length !== undefined) {
			startPos += element.length;
		}

		var childs = element.childNodes;
		for (var i = 0; i < childs.length; i++) {
			var posObject = editorjs.getStartPosObject(childs[i], target, startPos);

			if (posObject.found) {
				return posObject;
			} else {
				startPos = posObject.pos;
			}
		}

		return { pos: startPos, found: false };
	},

	getTextCaretPos: function(element, returnLast) {

		var selection = window.getSelection();

		// locate selection anchor
		var anchorEditabe = editorjs.getEditable(selection.anchorNode);
		var anchorStartPos = 0;
		var posObject = editorjs.getStartPosObject(anchorEditabe, selection.anchorNode, 0);
		if (posObject.found) {
			anchorStartPos = posObject.pos;
		}
		var anchorPos = anchorStartPos + selection.anchorOffset;

		// locate selection focus
		var focusEditabe = editorjs.getEditable(selection.focusNode);
		var focusStartPos = 0;
		posObject = editorjs.getStartPosObject(focusEditabe, selection.focusNode, 0);
		if (posObject.found) {
			focusStartPos = posObject.pos;
		}
		var focusPos = focusStartPos + selection.focusOffset;
		var pos;

		if (returnLast) {
			pos = anchorPos > focusPos ? anchorPos : focusPos;
		} else {
			pos = anchorPos > focusPos ? focusPos : anchorPos;
		}

		return pos;
	},

	getTextObjectByPos: function(element, textLength, pos) {
		var length = textLength;

		if (element.length !== undefined) {
			if ((length + element.length) >= pos) {
				return { element: element, startPos: length, found: true };
			} else {
				length += element.length;
			}
		}
		var textObject;
		var childs = element.childNodes;
		for (var i = 0; i < childs.length; i++) {
			textObject = editorjs.getTextObjectByPos(childs[i], length, pos);
			if (textObject.found) {
				return textObject;
			} else {
				length = textObject.startPos;
			}
		}

		return { element: null, startPos: length, found: false };
	},

	setTextCaretPos: function(element, pos) {
		var textObject = editorjs.getTextObjectByPos(element, 0, pos);
		if (textObject.found) {
			var range = document.createRange();
			var sel = window.getSelection();

			range.setStart(textObject.element, pos - textObject.startPos);
			range.collapse(true);
			sel.removeAllRanges();
			sel.addRange(range);
		}
	},

	nodeOnCut: function(event) {
		if (editorjs.isReadOnly(this)) {
			event.stopPropagation();
			event.preventDefault();
			return false;
		}
	},

	nodeOnPaste: function(event) {
		event.stopPropagation();
		event.preventDefault();

		if (editorjs.isReadOnly(this)) {
			return false;
		}

		/* TODO -oDima: here should be cleaning of HTML before of insertion ... probably using Markdown syntax */

		/*
		var html = event.clipboardData.getData("html");
		var converter = new showdown.Converter();
		text = converter.makeMarkdown(html);
		html = converter.makeHtml(text);
		document.execCommand("insertHTML", false, html);
		*/

		var text = event.clipboardData.getData('Text');
		document.execCommand('insertText', false, text);
	},

	fontSizePixels: function(element) {
		//let style = window.getComputedStyle(element);
		//let fontSizeText = style.fontSize.toString();
		//return Number(fontSizeText.substring(0, fontSizeText.length - 2));
	},

	nodeOnFocus: function(event) {
		if (editorjs.isReadOnly(this)) {
			this.setAttribute('readonly-data', this.innerHTML); // store data
		}

		let element = event.target;
		if (element.classList.contains(editorjs.nodeNameClass) || element.classList.contains(editorjs.nodeDescriptionClass)) {
			element = editorjs.getNode(element);
		}

		let fontSize = editorjs.fontSizePixels(element);
		if (Math.round(fontSize) === editorjs.readableFontSize) {
			return;
		}

		let editor = document.getElementById('editor');
		let fontSizeEditor = editorjs.fontSizePixels(editor);

		editor.style.fontSize = Math.round(((fontSizeEditor / fontSize) * editorjs.readableFontSize)).toString() + 'px';
		editorjs.makeVisible(element);		
	},

	nodeOnInput: function(event) {
		if (editorjs.isReadOnly(this)) {
			var pos = editorjs.getTextCaretPos(this, true);

			this.innerHTML = this.getAttribute('readonly-data'); // restore data

			editorjs.setTextCaretPos(this, pos);

			event.stopPropagation();
			event.preventDefault();
			return false;
		}
	},

	nodeOnBlur: function(event) {
		if (editorjs.isReadOnly(this)) {
			this.removeAttribute('readonly-data'); // remove stored data
		}
	},

	nodeBlockDragDrop: function(event) {
		if (editorjs.isReadOnly(this)) {
			event.dataTransfer.dropEffect = 'none';
			event.stopPropagation();
			event.preventDefault();
			return false;
		}
	},

	makeVisible: function(element) {
		/*element.scrollIntoView({behavior: "smooth", block: "start"});*/
	},

	nodeChildsFold: function(event) {
		let node = editorjs.getNode(event.target);
		
		let childs = node.getElementsByClassName(editorjs.nodeChildsClass);
		if (childs.length === 0) {
			return;
		}

		if (childs[0].childNodes.length === 0) {
			return;
		}

		let nodes = childs[0];

		let foldIcon = node.getElementsByClassName(editorjs.nodeChildsFoldClass)[0];

		if (foldIcon.textContent === editorjs.expandedChildsIcon) {
			foldIcon.textContent = editorjs.collapsedChildsIcon;
			nodes.classList.add('hidden');
		}
		else {
			foldIcon.textContent = editorjs.expandedChildsIcon;
			nodes.classList.remove('hidden');
		}
		editorjs.makeVisible(node);
	},

	nodeDescriptionFold: function(event) {
		let node = editorjs.getNode(event.target);

		let childs = node.getElementsByClassName(editorjs.nodeDescriptionClass);
		if (childs.length === 0) {
			return;
		}

		let desription = childs[0];

		let icon = node.getElementsByClassName(editorjs.nodeDescriptionFoldClass)[0];

		if (icon.textContent === editorjs.expandedDescriptionIcon) {
			icon.textContent = editorjs.collapsedDescriptionIcon
			desription.classList.add('hidden');
		}
		else {
			icon.textContent = editorjs.expandedDescriptionIcon;
			desription.classList.remove('hidden');
		}
		editorjs.makeVisible(node);
	},


	nodeMenuShow: function(event) {
		let node = editorjs.getNode(event.target);
		console.log("node", node)
		event.stopPropagation();
		event.preventDefault();
		alert('Here will be shown menu for node');
	},

	isFirstLineCaret: function(element) {
		if (editorjs.isFirstCharCaret(element)) {
			return true;
		}

		var textObject = editorjs.getTextObjectByPos(element, 0, 0);
		if (textObject.found) {
			var selection = window.getSelection();
			var rangeBad = selection.getRangeAt(0);

			// HOTFIX: select one symbol to get correct position on word-wrapping
			var range = rangeBad.cloneRange();

			if (range.endContainer.length < range.endOffset + 1) {
				range.setEnd(range.endContainer, range.endOffset - 1);
			}
			else
			  range.setEnd(range.endContainer, range.endOffset + 1);

			var caretRect = range.getBoundingClientRect();

			range = document.createRange();
			range.selectNodeContents(textObject.element);
			var firstRect = range.getBoundingClientRect();

			return Math.round(caretRect.top) === Math.round(firstRect.top);
		} else {
			return editorjs.getTextCaretPos(element, false) === 0;
		}
	},

	isFirstCharCaret: function(element) {
		return editorjs.getTextCaretPos(element, false) === 0;
	},

	isLastLineCaret: function(element) {
		if (editorjs.isLastCharCaret(element)) {
			return true;
		}

		var textObject = editorjs.getTextObjectByPos(element, 0, editorjs.getTextLength(element));
		if (textObject.found) {
			var selection = window.getSelection();
			var rangeBad = selection.getRangeAt(0);

			// HOTFIX: select one symbol to get correct position on word-wrapping
			var range = rangeBad.cloneRange();
			if (range.endContainer.length < range.endOffset + 1) {
				return true;
			}
			range.setEnd(range.endContainer, range.endOffset + 1);
			var caretRect = range.getBoundingClientRect();

			// get last text element selection
			range = document.createRange();
			range.selectNodeContents(textObject.element);
			var lastRect = range.getBoundingClientRect();

			const styles = getComputedStyle(element);
			
			return Math.abs((Math.round(caretRect.bottom) - Math.round(lastRect.bottom))) < parseInt(styles.lineHeight) / 2;
		} else {
			return editorjs.getTextCaretPos(element, true) === editorjs.getTextLength(element);
		}
	},

	isLastCharCaret: function(element) {
		return editorjs.getTextCaretPos(element, true) === editorjs.getTextLength(element);
	},

	nodeOnKeyDown: function(event) {
		// don't block CTRL+C
		if ((event.ctrlKey === true) && (event.keyCode === 67 /*C key*/ ) && editorjs.isReadOnly(this)) {
			return;
		}

		// Allowed only navigation keys: TAB, HOME, END, LEFT, UP, RIGHT, DOWN
		if (([9, 35, 36, 37, 38, 39, 40].indexOf(event.keyCode) === -1) && editorjs.isReadOnly(this)) {
			event.stopPropagation();
			event.preventDefault();
			return;
		}

		// do not jump during selection using Shift key
		if ((event.shiftKey === true)) {
			return;
		}

		if ((event.keyCode === 37 /*arrow left*/ ) || (event.keyCode === 38 /*arrow up*/ )) {
			if (
				((event.keyCode === 38) && editorjs.isFirstLineCaret(this)) ||
				((event.keyCode === 37) && editorjs.isFirstCharCaret(this))
			) {
				var editable = editorjs.getEditableAbove(this);

				if (editable !== null) {
					event.stopPropagation();
					event.preventDefault();

					editable.focus();

					editorjs.setTextCaretPos(editable, editorjs.getTextLength(editable));

					// for UP key position is set on the begining on LAST line
					if (event.keyCode === 38) {
						var selection = window.getSelection();
						selection.modify('move', 'backward', 'lineboundary');
					}
				}
			}
		} else {
			if ((event.keyCode === 39 /*arrow right*/ ) || (event.keyCode === 40 /*arrow down*/ )) {
				if (
					((event.keyCode === 40) && editorjs.isLastLineCaret(this)) ||
					((event.keyCode === 39) && editorjs.isLastCharCaret(this))
				) {
					editable = editorjs.getEditableBelow(this);
					if (editable !== null) {
						event.stopPropagation();
						event.preventDefault();

						editable.focus();
						editorjs.setTextCaretPos(editable, 0);
					}
				}
			}
		}
	},
/*
	menuIconDisplay: function(node) {
		let menu = node.getElementsByClassName(editorjs.nodeMenuClass)[0];
		menu.classList.remove('hidden');
	},

	menuIconHide: function(node) {
		let menu = node.getElementsByClassName(editorjs.nodeMenuClass)[0];
		menu.classList.add('hidden');
	},

	nodeIsFocused: function(node) {
		return editorjs.getNode(document.activeElement) == node;
	},

	nodeSelectionMouseOver: function(event) {
		let node = editorjs.getNode(event.target);
		editorjs.menuIconDisplay(node);
	},

	nodeSelectionMouseLeave: function(event) {
		let node = editorjs.getNode(event.target);
		let menu = node.getElementsByClassName(editorjs.nodeMenuClass)[0];

		if (!editorjs.nodeIsFocused(node)) {
			editorjs.menuIconHide(node);
		}
	},
*/
	editableEventsSet: function(element) {
		element.addEventListener('keydown', editorjs.nodeOnKeyDown, true);
		// necessary to prevent any editing of read-only elements
		element.addEventListener('focus', editorjs.nodeOnFocus, false);
		element.addEventListener('input', editorjs.nodeOnInput, false);
		element.addEventListener('blur', editorjs.nodeOnBlur, false);
		element.addEventListener('cut', editorjs.nodeOnCut, false);
		//element.addEventListener('paste', editorjs.nodeOnPaste, true);
		element.addEventListener('drag', editorjs.nodeBlockDragDrop, false);
		element.addEventListener('dragenter', editorjs.nodeBlockDragDrop, false);
		element.addEventListener('dragover', editorjs.nodeBlockDragDrop, false);
		element.addEventListener('drop', editorjs.nodeBlockDragDrop, false);
	},

	editableEventsByClassSet: function(className) {
		var elements = document.getElementsByClassName(className);

		for (var i = 0; i < elements.length; i++) {
			editorjs.editableEventsSet(elements[i]);
		}
	},

	foldChildsEventsByClassSet: function(className) {
		var elements = document.getElementsByClassName(className);

		for (var i = 0; i < elements.length; i++) {
			elements[i].addEventListener('click', editorjs.nodeChildsFold, false);
		}
	},

	foldDescriptionEventsByClassSet: function(className) {
		var elements = document.getElementsByClassName(className);

		for (var i = 0; i < elements.length; i++) {
			elements[i].addEventListener('click', editorjs.nodeDescriptionFold, false);
		}
	},

	nodeControlEventsByClassSet: function(className) {
		var elements = document.getElementsByClassName(className);

		for (var i = 0; i < elements.length; i++) {
			elements[i].addEventListener('click', editorjs.nodeChildsFold, false);
		}
	},

	nodeMenuEventsByClassSet: function(className) {
		var elements = document.getElementsByClassName(className);

		for (var i = 0; i < elements.length; i++) {
			elements[i].addEventListener('click', editorjs.nodeMenuShow, false);
		}
	}

/*	nodeSelectionEventByClassSet: function(className) {
		var elements = document.getElementsByClassName(className);

		for (var i = 0; i < elements.length; i++) {
			elements[i].addEventListener('mouseover', editorjs.nodeSelectionMouseOver, false);
			elements[i].addEventListener('mouseleave', editorjs.nodeSelectionMouseLeave, false);
		}
	}*/
}

export default editorjs;