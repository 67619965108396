
import React,{Component} from "react";
import {Modal} from 'react-bootstrap'
import {PhotoshopPicker} from 'react-color'
import $ from 'jquery';
import '../css/materialicon.css';
var html='';
var commandRelation=[];

class  Editoricons extends Component{

	constructor(props){
		super(props)
		this.state = {
			fColorModal:false,
			commands: [
			{
				"cmd": "bold",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">format_bold</i>,
				"desc": "Toggles bold on/off for the selection or at the insertion point. (Internet Explorer uses the STRONG tag instead of B.)",

			},{
				"cmd": "italic",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">format_italic</i>,
				"desc": "Toggles italics on/off for the selection or at the insertion point. (Internet Explorer uses the EM tag instead of I.)"
			},
			{
				"cmd": "insertImage",
				"cmdtxt": "normal",
				"val": "Enter image URL",
				"icon": <i className="material-icons">image</i>,
				"desc": "Inserts an image at the insertion point (deletes selection). Requires the image SRC URI string to be passed in as a value argument. The URI must contain at least a single character, which may be a white space. (Internet Explorer will create a link with a null URI value.)"
			},
			{
				"cmd": "createLink",
				"cmdtxt": "normal",
				"val": "https://twitter.com/netsi1964",
				"icon": <i className="material-icons">insert_link</i>,
				"desc": "Creates an anchor link from the selection, only if there is a selection. This requires the HREF URI string to be passed in as a value argument. The URI must contain at least a single character, which may be a white space. (Internet Explorer will create a link with a null URI value.)"
			},
			 {
				"cmd": "insertOrderedList",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">format_list_numbered</i>,
				"desc": "Creates a numbered ordered list for the selection or at the insertion point."
			}, {
				"cmd": "insertUnorderedList",				
				"cmdtxt": "normal",
				"icon": <i className="material-icons">format_list_bulleted</i>,
				"desc": "Creates a bulleted unordered list for the selection or at the insertion point."
			},
			{
				"cmd": "underline",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">format_underlined</i>,
				"desc": "Toggles underline on or off for the selection or at the insertion point."
			},{
				"cmd": "justifyFull",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">format_align_justify</i>,
				"desc": "Justifies the selection or insertion point."
			},{
				"cmd": "justifyLeft",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">format_align_left</i>,
				"desc": "Justifies the selection or insertion point to the left."
			},
			/*
			{
				"cmd": "justifyCenter",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">format_align_center</i>,
				"desc": "Centers the selection or insertion point."
			},
			{
				"cmd": "justifyRight",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">format_align_right</i>,
				"desc": "Right-justifies the selection or the insertion point."
			},
			{
				"cmd": "indent",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">format_textdirection_l_to_r</i>,
				"desc": "Indents the line containing the selection or insertion point. In Firefox, if the selection spans multiple lines at different levels of indentation, only the least indented lines in the selection will be indented."
			}, {
				"cmd": "outdent",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">format_textdirection_r_to_l</i>,
				"desc": "Outdents the line containing the selection or insertion point."
			},
			*/
			/*{
				"cmd": "fontName",				
				"cmdtxt": "normal",
				"icon": <Dropdown id="">
					<Dropdown.Toggle bsRole="toggle" noCaret={true}>
						<i className="glyphicon glyphicon-font"></i>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<MenuItem className="node-eCommandList" onClick={()=>this.fontDoCommand('Arial')}> <span className="node-eCommand"> Arial</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fontDoCommand('Roboto')}> <span className="node-eCommand"> Roboto</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fontDoCommand('Times New Roman')}> <span className="node-eCommand"> Times New Roman</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fontDoCommand('Impact')}> <span className="node-eCommand"> Impact</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fontDoCommand('Times')}> <span className="node-eCommand"> Times</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fontDoCommand('Courier New')}> <span className="node-eCommand"> Courier New</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fontDoCommand('Courier')}> <span className="node-eCommand"> Courier</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fontDoCommand('Verdana')}> <span className="node-eCommand"> Verdana</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fontDoCommand('Georgia')}> <span className="node-eCommand"> Georgia</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fontDoCommand('Palatino')}> <span className="node-eCommand"> Palatino</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fontDoCommand('Garamond')}> <span className="node-eCommand"> Garamond</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fontDoCommand('Arial Black')}> <span className="node-eCommand"> Arial Black</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fontDoCommand('Bookman')}> <span className="node-eCommand"> Bookman</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fontDoCommand('Comic Sans MS')}> <span className="node-eCommand"> Comic Sans MS</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fontDoCommand('Candara')}> <span className="node-eCommand"> Candara</span> </MenuItem>
					</Dropdown.Menu>
				</Dropdown>,
				"desc": "Changes the font name for the selection or at the insertion point. This requires a font name string (\"Arial\" for example) to be passed in as a value argument."
			},*//*
			{
				"cmd": "fontSize",
				"cmdtxt": "fontsizes",
				"val": "1-7",
				"icon":<Dropdown id="">
					<Dropdown.Toggle bsRole="toggle" useAnchor={true} noCaret={true} className="button-link">
						<i className="glyphicon glyphicon-text-size"></i>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<MenuItem className="node-eCommandList" onClick={()=>this.fsizeDoCommand('1')}> <span className="node-eCommand"> 1</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fsizeDoCommand('2')}> <span className="node-eCommand"> 2</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fsizeDoCommand('3')}> <span className="node-eCommand"> 3</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fsizeDoCommand('4')}> <span className="node-eCommand"> 4</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fsizeDoCommand('5')}> <span className="node-eCommand"> 5</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fsizeDoCommand('6')}> <span className="node-eCommand"> 6</span> </MenuItem>
						<MenuItem className="node-eCommandList" onClick={()=>this.fsizeDoCommand('7')}> <span className="node-eCommand"> 7</span> </MenuItem>
					</Dropdown.Menu>
				</Dropdown>,
				"desc": "Changes the font size for the selection or at the insertion point. This requires an HTML font size (1-7) to be passed in as a value argument."
			}, {
				"cmd": "foreColor",
				"cmdtxt": "colorpicker",
				"val": "rgba(0,0,0,.5)",
				"icon":<Dropdown id="">
					<Dropdown.Toggle bsRole="toggle"  useAnchor={true}  noCaret={true}  className="button-link">
						<i className="glyphicon glyphicon-text-color"></i>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<MenuItem className="node-eCommandList">
							<SwatchesPicker onChangeComplete={this.handleforecolor} />
						</MenuItem>
					</Dropdown.Menu>
				</Dropdown>
*/
				/*<Dropdown id="">
					<Dropdown.Toggle bsRole="toggle" noCaret={true}>
						<i className="glyphicon glyphicon-text-color"> </i>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<MenuItem className="node-eCommandList">
							<SketchPicker onClick={(event)=>event.preventDefault()} onChangeComplete={this.handleforecolor}/></MenuItem>	
					</Dropdown.Menu>
				</Dropdown>*/

				/*,
				"desc": "Changes a font color for the selection or at the insertion point. This requires a color value string to be passed in as a value argument."
			},{
				"cmd": "backColor",
				"cmdtxt": "colorpicker",
				"icon" :<Dropdown id="">
					<Dropdown.Toggle bsRole="toggle" noCaret={true} useAnchor={true} className="button-link">
						 <i className="glyphicon glyphicon-text-background"></i>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<MenuItem className="node-eCommandList">
							<SwatchesPicker onChangeComplete={this.handleBgcolor} /></MenuItem>	
					</Dropdown.Menu>
				</Dropdown>,
				"val": "glyphicon glyphicon-text-background",
				"desc": "Changes the document background color. In styleWithCss mode, it affects the background color of the containing block instead. This requires a color value string to be passed in as a value argument. (Internet Explorer uses this to set text background color.)"
			}, {
				"cmd": "undo",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">undo</i>,
				"desc": "Undoes the last executed command."
			}, {
				"cmd": "redo",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">redo</i>,
				"desc": "Redoes the previous undo command."
			},{
				"cmd": "copy",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">content_copy</i>,
				"desc": "Copies the current selection to the clipboard. Clipboard capability must be enabled in the user.js preference file. See"
			},{
				"cmd": "cut",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">content_cut</i>,
				"desc": "Cuts the current selection and copies it to the clipboard. Clipboard capability must be enabled in the user.js preference file. See"
			},{
				"cmd": "delete",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">delete</i>,
				"desc": "Deletes the current selection."
			},{
				"cmd": "paste",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">content_paste</i>,
				"desc": "Pastes the clipboard contents at the insertion point (replaces current selection). Clipboard capability must be enabled in the user.js preference file. See"
			},
			{
				"cmd": "insertHorizontalRule",
				"cmdtxt": "normal",
				"icon": <i className="glyphicon glyphicon-option-horizontal"></i>,
				"desc": "Inserts a horizontal rule at the insertion point (deletes selection)."
			}, {
				"cmd": "insertHTML",
				"cmdtxt": "normal",
				"val": "&lt;h3&gt;Life is great!&lt;/h3&gt;",
				"icon": <i className="material-icons">code</i>,
				"desc": "Inserts an HTML string at the insertion point (deletes selection). Requires a valid HTML string to be passed in as a value argument. (Not supported by Internet Explorer.)"
			},  {
				"cmd": "insertParagraph",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">local_parking</i>,
				"desc": "Inserts a paragraph around the selection or the current line. (Internet Explorer inserts a paragraph at the insertion point and deletes the selection.)"
			}, {
				"cmd": "insertText",
				"cmdtxt": "normal",
				"val": new Date(),
				"icon": <i className="material-icons">short_text</i>,
				"desc": "Inserts the given plain text at the insertion point (deletes selection)."
			}, {
				"cmd": "removeFormat",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">format_clear</i>,
				"desc": "Removes all formatting from the current selection."
			}, {
				"cmd": "selectAll",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">select_all</i>,
				"desc": "Selects all of the content of the editable region."
			}, {
				"cmd": "strikeThrough",
				"cmdtxt": "normal",
				"icon": <i className="material-icons">format_strikethrough</i>,
				"desc": "Toggles strikethrough on/off for the selection or at the insertion point."
			},
			
			*/
			
			 {
				"cmd": "subscript",
				"cmdtxt": "normal",
				"icon": <i className="glyphicon glyphicon-subscript"></i>,
				"desc": "Toggles subscript on/off for the selection or at the insertion point."
			}, {
				"cmd": "superscript",
				"cmdtxt": "normal",
				"icon": <i className="glyphicon glyphicon-superscript"></i>,
				"desc": "Toggles superscript on/off for the selection or at the insertion point."
			},
			{
				"cmd" : "insertImage",
				"cmdtxt":"normal",
				"icon": <div className="upload-btn-wrapper">
							<i className="material-icons">insert_photo</i>
    						<input type="file" id="inpfile"  accept="image/jpeg, image/jpg, image/png, image/gif, image/svg+xml" />
						</div>,
				"desc": "select and upload image"
			}
			],
			htmlbuttons:'',			
		}
	}
	componentDidMount = ()=>{
		var self =this;
		$(".content"+this.props.nodeid).focus();
		html = this.state.commands.map(function(command, i) {
			commandRelation[command.cmd] = command;
			var commndWithbutton =  <button className="button-link">{command.icon}</button>
			return (<span key={i}  className="" unselectable="on" title={command.desc} onMouseDown={(event)=>event.preventDefault()} onClick={
				command.cmdtxt==='normal'?()=>self.doCommand(command.cmd):()=>self.icon(command.cmd)}> {command.cmdtxt==='normal'? commndWithbutton: command.icon} </span>);
			});

		this.setState({
			htmlbuttons:html
		});

		$(function (){
			$('#inpfile').on('change', function(){
    var file = $(this).prop('files')[0];
    var reader  = new FileReader();

    reader.addEventListener("load", function () {
        document.execCommand('insertImage', false, reader.result);
    }, false);

    if (file)
        reader.readAsDataURL(file);
});
		})

	}
	uploadimge = ()=>{

	}
	showfColor = ()=>{
		this.setState({
			fColorModal: !this.state.fColorModal
		});
	}

	handleBgcolor = (color, event)=>{
		document.execCommand("backColor", false, color.hex);
	}
	handleforecolor = (color, event)=>{
		document.execCommand("foreColor", false, color.hex);
	}

	supported = (cmd)=> {
		var css = !!document.queryCommandSupported(cmd.cmd) ? "btn-succes" : "btn-error";
		return css
	};

	icon = (cmd)=> {
		return (typeof cmd.icon !== "undefined") ? "fa fa-" + cmd.icon : "";
	};

	doCommand = (cmdKey) => {
		var cmd = commandRelation[cmdKey];

		if (this.supported(cmd) === "btn-error") {
			alert("execCommand(“" + cmd.cmd + "”)\nis not supported in your browser");
			return;
		}
		var val = (typeof cmd.val !== "undefined") ? prompt("Value for " + cmd.cmd + "?", cmd.val) : "";
		document.execCommand(cmd.cmd, false, (val || ""));
	}
	fontDoCommand = (cmdVal) =>{
		document.execCommand("fontName", false, cmdVal);	
	}
	fsizeDoCommand = (cmdVal) =>{
		document.execCommand("fontSize", false, cmdVal);	
	}
	keycheck = (event)=>{
		if(event.keyCode ===13){
			event.target.blur();
			event.preventDefault();
		}
	}
	acceptColor=(color, event)=>{
		console.log(color);
		console.log(event);

		this.setState({
			fColorModal:false
		});
	}
	cancelColor=()=>{
		this.setState({
			fColorModal:false
		});
	}
		
	render(){
		return (			
			<div className="pull-left editorActionIcons">
				<div className={"col-xs-12 col-sm-12 col-md-12 col-lg-12 "}>
					{this.state.htmlbuttons}
					
				</div>
				<Modal show={this.state.fColorModal} unselectable="on">
					<Modal.Body> 
						<PhotoshopPicker onAccept={this.acceptColor} onCancel={this.cancelColor}  /> 
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}
export default Editoricons;