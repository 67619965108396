import React, { Component } from "react";
import axios from "axios";
import $ from 'jquery';
import "../css/nroles.css"
import ServiceURL from '../../../../constants/constants';


class Nroles extends Component {
	constructor(props){
		super(props);
		this.state = {
			rights:[],
			roles:[],
			roles_rights:[],
		}
	}
	 columnDrag=(event)=>{
		}

		columnDragEnter=(event)=>{
		}

		columnDragOver=(event)=>{
		}

		columnDragDrop=(event)=>{
		}

		moveableColumnEventsSet=(element)=> {
			element.addEventListener('drag', this.columnDrag, false);
			element.addEventListener('dragenter', this.columnDragEnter, false);
			element.addEventListener('dragover', this.columnDragOver, false);
			element.addEventListener('drop', this.columnDragDrop, false);
		}

		editableClick=(event)=>{

			event.target.setAttribute('contenteditable', 'true');
			event.target.focus();
		}

		editableFocus=(event)=> {
			event.target.setAttribute('content', event.target.textContent);
		}

		editableBlur=(event)=>{
			var element = event.target;

			element.removeAttribute('contenteditable');

			if (element.hasAttribute('content')) {
				var id;
				var value = element.textContent;
				var valueBefore = element.getAttribute('content');
				element.removeAttribute('content');

				if (value === valueBefore) {
					return; // no changes
				}

				/* TODO: Here should be storing of modified content */

				if (element.className === "right-name") {
					id = this.getRightId(element);
					var rightName={
						id:id,
						name:value
					}
					var URL=ServiceURL+"N_rights_api/right_edit";
					axios.post(URL,rightName)
					console.log('RIGHT id =', id, 'NAME changed to "', value, '"');
				}
				else
				if (element.className === "right-description") {
					id = this.getRightId(element);
					var rightDescription={
						id:id,
						description:value
					}
					 URL=ServiceURL+"N_rights_api/right_edit";
					axios.post(URL,rightDescription)
					console.log('RIGHT id =', id, 'DESCRIPTION changed to "', value, '"');
				}
				else
				if (element.className === "role-name") {
					id = this.getRoleId(element);
					var role = {
						id:id,
						name:value
					}
					URL = ServiceURL+"N_rights_api/role_edit"
					axios.post(URL,role)
					console.log('ROLE id =', id, 'NAME changed to "', value, '"');
				}
			}
		}

		editableKeyDown=(event)=> {
			if (event.keyCode === 13) {
				event.target.blur();
				event.stopPropagation();
				event.preventDefault();
			}
			if (event.keyCode === 27) {
				if (event.target.hasAttribute('content')) {
					event.target.textContent = event.target.getAttribute('content');
				}
				event.target.blur();
				event.stopPropagation();
				event.preventDefault();
			}
		}

		moveableColumnEventsByClassSet=(className)=> {
			var elements = document.getElementsByClassName(className);

			for (var i = 0; i < elements.length; i++) {
				this.moveableColumnEventsSet(elements[i]);
			}
		}

		editableEventsSet=(element)=> {
			console.log(element);
			element.addEventListener('click', this.editableClick, false);
			element.addEventListener('focus', this.editableFocus, false);
			element.addEventListener('blur', this.editableBlur, false);
			element.addEventListener('keydown', this.editableKeyDown, false);
			//console.log(element);
		}

		editableEventsByClassSet=(event)=> {
			var className = $(event.target).attr("class");

			var elements = document.getElementsByClassName(className);
			//console.log(elements);

			for (var i = 0; i < elements.length; i++) {
				//console.log("hello");
				this.editableEventsSet(elements[i]);
			}
		}



		deleteEventByClassSet=(className)=>{
			var elements = document.getElementsByClassName(className);

			for (var i = 0; i < elements.length; i++) {
				elements[i].addEventListener('click', this.deleteRoleClick, false);
			}
		}

		getRoleId=(element)=> {
			if (element.classList.contains('role')) {
				return element.id;
			}

			if (element.hasAttribute('role')){
				return element.getAttribute('role');
			}

			if (element.tagName.toLowerCase() === 'body') {
				return null;
			}

			return this.getRoleId(element.parentElement);
		}

		getRoleName=(element)=> {
			if (element.classList.contains('role')) {
				return element.getElementsByClassName('role-name')[0].textContent;
			}

			if (element.tagName.toLowerCase() === 'body') {
				return null;
			}

			return this.getRoleName(element.parentElement);
		}

		getRightId=(element)=> {
			if (element.classList.contains('right')) {
				return element.id;
			}

			if (element.hasAttribute('right')){
				return element.getAttribute('right');
			}

			if (element.tagName.toLowerCase() === 'body') {
				return null;
			}

			return this.getRightId(element.parentElement);
		}

		roleRightClick=(event)=>{
			var input;
			var params={};

			if (event.target.tagName.toLowerCase() !== 'input') {
				input = event.target.getElementsByTagName('input')[0];
				input.checked = !input.checked;
			}
			else {
				input = event.target;
			}

			var role = this.getRoleId(input);
			var right = this.getRightId(input)
			params={
				role : role,
				right: right
			}
			if(input.checked){

			var URL = ServiceURL+"N_rights_api/roleright_add";
			axios.post(URL,params)
			}
		else{
			 URL = ServiceURL+"N_rights_api/roleright_delete";
			axios.post(URL,params)
		}
		}
			//console.log(input.checked);

		roleRightEventSet=(className)=>{
			var elements = document.getElementsByClassName(className);

			for (var i = 0; i < elements.length; i++) {
				elements[i].addEventListener('click', this.roleRightClick, false);
			}
		}
		getRights = () => {
        var URL = ServiceURL+"N_rights_api/rights";

        axios.get(URL).then(response => {
            var dblist = response.data;
            this.setState({
                rights: dblist
            });
            //console.log(this.state.rights);
        });
    }
    getRoles = () => {
    	var URL = ServiceURL+"N_rights_api/roles";

        axios.get(URL).then(response => {
            var dblist = response.data;
            this.setState({
                roles: dblist
            });
            //console.log(this.state.rights);
        });
    }
    getRolesRights = () => {
    	var URL = ServiceURL+"N_rights_api/rolesrights";

        axios.get(URL).then(response => {
            var dblist = response.data;
            this.setState({
                roles_rights: dblist
            });
            //console.log(this.state.roles_rights);
        });
    }
    editNewRole=(roleid)=>{
    	console.log("here")
    	$("#role_"+roleid).click();
    	/*//var roleid = roleid;
    	var classname = document.getElementById(roleid).className;
    	var elements= document.getElementsByClassName(classname);
    	for (var i = 0; i < elements.length; i++) {
				//console.log("hello");
				this.editableEventsSet(elements[i]);
			}*/

    }
    addRole = () =>{
    	var params={};

    	var URL = ServiceURL+"N_rights_api/role";

    		params = {
    			name: "New role"
    		}
    	var roleobj;
    	axios.post(URL,params).then(response =>{
			var dbdata = response.data;
			//console.log(dbdata.status);
			roleobj={
					"id": dbdata.response.id,
					"name": dbdata.response.name,
					newItem:true
				}
			var allroles =  this.state.roles;
			allroles.push(roleobj);
	    	this.setState({
	    		roles:allroles
	    	});
	    	this.editNewRole(dbdata.response.id)
    	});

    }
    deleteRoleClick=(event)=> {
			var name = this.getRoleName(event.target);

			if (window.confirm('Do you want to delete role "' + name + '"?')) {
				var id = this.getRoleId(event.target);
				alert('Here should be deleted role with id =' + id);
			}
		}
    deleteRole= (event)=>{
    	var name = this.getRoleName(event.target);
    	//console.log($(event.target).attr("data-id"));
    	if(window.confirm('Do you want to delete role "' + name + '"?')){
    		var params = {};
    		var URL = ServiceURL+"N_rights_api/role_delete";
    		params = {
    			id: $(event.target).attr("data-id")
    		};

    		axios.post(URL,params).then(response=>{
    			this.getRoles();

    		});

    	}
    }
    componentDidMount() {
        this.getRights()
        this.getRoles()
        this.getRolesRights()
        this.moveableColumnEventsByClassSet('role');
		this.editableEventsByClassSet('role-name');
		this.deleteEventByClassSet('role-delete');
		this.roleRightEventSet('role-rigth');

    }


		render(){
			return(

			<div id="rolesRightsPopup">
				<div className="roles-rights">
					<div className="rights-container">
						<div className="rights-header noselect">Rights</div>
						<div className="rights">
						{
						this.state.rights.map((right,ri)=>{
							var rightitem =
							<div className="right-flex" key={ri}>
								<div className="right"  id={right.id}>
									<div className="right-name" onClick={this.editableEventsByClassSet}>{right.name}</div>
									<div className="right-description" onClick={this.editableEventsByClassSet}>{right.description}</div>
								</div>
							</div>
							return rightitem;
							})
						}
						</div>
					</div>

					<div className="roles-container">
						<div className="roles-header noselect">Roles</div>
						<div className="roles">
							{
								this.state.roles.map((role,ro)=>{
									var check = false;
									var role_id=role.id;
									var roleitem=
									<div className="role" key={ro} id={role.id}>
										<div className="role-header">
											<div className="role-move noselect">&#8942;</div>
											<div><span className="role-name" id={'role_'+role_id} onClick={this.editableEventsByClassSet}>{role.name}</span></div>
											<div className="role-delete noselect" onClick={this.deleteRole} ><span role="img" data-id={role.id} aria-label="cross mark">&#x274C;</span></div>
										</div>
										{
											this.state.rights.map((iright,iri)=>{
											check = false;
										 	if(role_id in this.state.roles_rights && this.state.roles_rights[role_id].includes(iright.id)){
										 	  check = true;
										 	}
											var checkitem = <div className="role-rigth" key={iri} right={iright.id}><input onClick={this.roleRightClick} defaultChecked={check?true:false} type="checkbox" name="10-1"/></div>
											return checkitem
											})
										}
									</div>
									return roleitem;
								})
							}
						</div>
					</div>
				</div>
				<div id="addRole" onClick={this.addRole} >+ Add new role</div>
			</div>

				);
		}


}
export default Nroles;