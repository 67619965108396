import $ from 'jquery';
import axios from "axios";
import ServiceURL from '../../../../constants/constants';

const ANF = {
	saveNodeName : function (event) {
		var newcontent = $(event.target).text();
		newcontent = newcontent.trim();
		console.log("newcontent",newcontent);
		if (newcontent.substr(-4, 4) === '<br>') {
			newcontent = newcontent.slice(0, -4);
		}
		if (newcontent === "<div><br></div>") {
			newcontent = newcontent.replace("<div><br></div>", "");
		}
		//var uipos = $(event.target).attr("data-position");
		var nodeid = $(event.target).attr("data-id");		
		var nodeContent = ANF.findObjectById(nodeid);
		
		if (String(newcontent) !== String(nodeContent.node_name) && newcontent !== "") {
			console.log("if success")
			var dataid = $(event.target).attr("data-id");
			var parentId = $(event.target).attr("data-parent");
			var nodes = {
				node_name: newcontent,
				node_id: dataid,
				userkey: this.userkey
			}
			console.log("nodes: ",nodes);
			nodeContent.node_name = newcontent;
			ANF.findObjectByIdAndReplace(nodeid,nodeContent);
			
			var CDTURL = ServiceURL+"content_api/update_nodename";
			
			axios.post(CDTURL, nodes).then(response => {
				if (dataid !== response.data.nodeid) {
					var newnode = {
						id: response.data.nodeid,
						node_name: newcontent,
						content: '',
						showcontent:true,
						childsDisplayed:'1',
						subnodes:'0',
						parent:parentId,
						contributors:"No data found"
					}
					console.log("dataid :", dataid, " new node: ", newnode)
					ANF.findObjectByIdAndReplace(dataid,newnode);					
				}
			});

		}
		else{
			console.log("if not success so i am else");
		}
	},

	saveNodeDesc: function(event){
		var newcontent = event.target.innerHTML.trim();
		var nodeid = $(event.target).attr("data-id");
		var nodeContent = ANF.findObjectById(nodeid);
		if (newcontent !== nodeContent.content && newcontent !== "") {
			this.setState({loading:true})
			var dataid = $(event.target).attr("data-id");

			console.log($(event.target))
			var nodes = {
				node_id: dataid,
				userkey: this.userkey,
				content: newcontent,
			}
			console.log("nodes", nodes);
			var CDTURL = ServiceURL+"content_api/update_nodedesc";
			axios.post(CDTURL, nodes).then(response => {
				if (dataid !== response.data.nodeid) {
					var newnode = {
						id: response.data.nodeid,
						node_name:$(".node-name[data-id='"+ dataid +"']") ,
						content: newcontent,
						showcontent:true,
						childsDisplayed:'1',
						subnodes:'0',
						parent:'0',
						contributors:"No data found"
					}
					console.log("dataid :", dataid, " new node: ", newnode)
					ANF.findObjectByIdAndReplace(dataid,newnode,"saveNodeDesc");					
				}
				this.setState({
					loading:false
				})
			}).catch(error=>{
				this.setState({
					loading: false
				})
			});
			if(dataid !== "N0"){
			nodeContent.content = newcontent;
			ANF.findObjectByIdAndReplace(nodeid,nodeContent,"saveNodeDesc");
		}
		}
	},

	findObjectById : function (nodeid){
		var found;
		var allnodes = this.state.aNodes;
		console.log("allnodes",allnodes);
		for(var i=0;i<=allnodes.length;i++){
			if(allnodes[i]){
				if(parseInt(allnodes[i].id) === parseInt(nodeid)){
					return allnodes[i];
				}
				else{
					console.log("i am in else");
					console.log(allnodes[i].subnodes !== "0" && allnodes[i].subnodes !== undefined);
					console.log(allnodes[i].subnodes);
					if(allnodes[i].subnodes !== "0" && allnodes[i].subnodes !== undefined ){
						found = ANF.findObjectByIdsub(allnodes[i].subnodes,nodeid);
						if(found.status === true){
							return found.foundobj;
						}
					}
				}
			}
		}

		//console.log("nothing found for nodeid : ", nodeid);
		return false;
	},

	findObjectByIdsub: function (allnodes,nodeid){
		console.log("i am here in findObjectByIdsub");
		var found = {
			foundobj:{},
			status:false
		};
		for(var i=0;i<=allnodes.length;i++){

			if(allnodes[i]){
				if(parseInt(allnodes[i].id) === parseInt(nodeid)){
					found.status = true;
					found.foundobj = allnodes[i]
					return found;
				}
				else{
					if(allnodes[i].subnodes !== "0" && allnodes[i].subnodes !== undefined){
						found =  ANF.findObjectByIdsub(allnodes[i].subnodes,nodeid);
						if(found.status === true){
						/*	found.foundobj = subfound.foundobj;	
							found.status = subfound.staus;*/	
							return found;
						}
					}
				}
			}
		}
		return found;
	},

	findObjectByIdAndReplace: function (nodeid,nodeobj, area="saveother"){
		var allnodes = this.state.aNodes;
		for(var i=0;i<=allnodes.length;i++){
			if(allnodes[i]){
				if(parseInt(allnodes[i].id) === parseInt(nodeid)){
					allnodes[i] = nodeobj;
				}
				else{
					if(allnodes[i].subnodes !== '0' ){
						allnodes[i].subnodes = ANF.findObjectByIdAndReplacesub(allnodes[i].subnodes,nodeid,nodeobj);
					}
				}
			}
		}
		this.setState({
			aNodes:[]
		})
		this.setState({
			aNodes:allnodes
		})
		if(area === "saveother"){
			$(function (){
				$(".node-name[data-id='"+nodeid+"']").focus();
			})
		}
		return false;
	},

	findObjectByIdAndReplacesub: function (allnodes,nodeid, nodeobj){
		for(var i=0;i<=allnodes.length;i++){
			if(allnodes[i]){
				if(parseInt(allnodes[i].id) === parseInt(nodeid)){
					allnodes[i] = nodeobj;
				}
				else{
					if(allnodes[i].subnodes !== '0'){
						allnodes[i].subnodes = ANF.findObjectByIdAndReplacesub(allnodes[i].subnodes,nodeid, nodeobj);
					}
				}
			}
		}
		return allnodes;
	}
	
}

export default ANF;