import React, { Component } from "react";
import cookie from 'react-cookies';
import { Link } from "react-router-dom";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from 'react-spinners/RingLoader';
import Editoricons from '../body/editor/js/ecommands';
import SignatureCanvas from 'react-signature-canvas';
import { Dropdown, MenuItem} from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import ServiceURL from '../../constants/constants';
import '../../css/notifications.css';
import "../../css/bootstrap2-toggle.css";
/*import $ from 'jquery';*/

class Settings extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading : false,
			notifications : [false,false,false,false,false,false,false,false,false,false]
		}
		this.userkey = cookie.load('userAcc') ? cookie.load('userAcc') : 'nokey';
	}
	componentDidMount = () => {
		var nodes ={ userkey: this.userkey }
		var	CDTURL = ServiceURL+"notifications_api/getnotifications";
		axios.post(CDTURL, nodes).then(response => {
			console.log(response.data);
		var notificationsNew = this.state.notifications;
			for (var i=0 ; i < response.data.length ; i++){
				
					notificationsNew[response.data[i].notification_type-1] = true;
				this.setState({
					notifications : notificationsNew
				})
			}
		})
	}
	handleToggle1 = () => {

		var notificationStatus = this.state.notifications[0] === true ? false : true;
		var nodes = { 
			userkey: this.userkey,
			type: 1,
			value: notificationStatus
		}
		var	CDTURL = ServiceURL+"notifications_api/settings";
		var newNotifications = this.state.notifications;
		newNotifications[0] =  !newNotifications[0];
		axios.post(CDTURL, nodes).then(response => {
			this.setState({ notifications: newNotifications });
		});
	}
	handleToggle2 = () => {
		
		var notificationStatus = this.state.notifications[1] === true ? false : true;
		var nodes = { 
			userkey: this.userkey,
			type: 2,
			value: notificationStatus
		}
		var	CDTURL = ServiceURL+"notifications_api/settings";
		var newNotifications = this.state.notifications;
		newNotifications[1] =  !newNotifications[1];
		axios.post(CDTURL, nodes).then(response => {
			this.setState({ notifications: newNotifications });
		});
	}
	handleToggle3 = () => {

		var notificationStatus = this.state.notifications[2] === true ? false : true;
		
		var nodes = { 
			userkey: this.userkey,
			type: 3,
			value: notificationStatus
		}
		var	CDTURL = ServiceURL+"notifications_api/settings";
		var newNotifications = this.state.notifications;
		newNotifications[2] =  !newNotifications[2];
		axios.post(CDTURL, nodes).then(response => {
			this.setState({ notifications: newNotifications });
		});
	}
	handleToggle4 = () => {

		var notificationStatus = this.state.notifications[3] === true ? false : true;
		
		var nodes = { 
			userkey: this.userkey,
			type: 4,
			value: notificationStatus
		}
		var	CDTURL = ServiceURL+"notifications_api/settings";
		var newNotifications = this.state.notifications;
		newNotifications[3] =  !newNotifications[3];
		axios.post(CDTURL, nodes).then(response => {
			this.setState({ notifications: newNotifications });
		});
	}
	handleToggle5 = () => {
		
		var notificationStatus = this.state.notifications[4] === true ? false : true;
		
		var nodes = { 
			userkey: this.userkey,
			type: 5,
			value: notificationStatus
		}
		var	CDTURL = ServiceURL+"notifications_api/settings";
		var newNotifications = this.state.notifications;
		newNotifications[4] =  !newNotifications[4];
		axios.post(CDTURL, nodes).then(response => {
			this.setState({ notifications: newNotifications });
		});
	}
	handleToggle6 = () => {

		var notificationStatus = this.state.notifications[5] === true ? false : true;
		
		var nodes = { 
			userkey: this.userkey,
			type: 6,
			value: notificationStatus
		}
		var	CDTURL = ServiceURL+"notifications_api/settings";
		var newNotifications = this.state.notifications;
		newNotifications[5] =  !newNotifications[5];
		axios.post(CDTURL, nodes).then(response => {
			this.setState({ notifications: newNotifications });
		});
	}
	handleToggle7 = () => {

		var notificationStatus = this.state.notifications[6] === true ? false : true;
		
		var nodes = { 
			userkey: this.userkey,
			type: 7,
			value: notificationStatus
		}
		var	CDTURL = ServiceURL+"notifications_api/settings";
		var newNotifications = this.state.notifications;
		newNotifications[6] =  !newNotifications[6];
		axios.post(CDTURL, nodes).then(response => {
			this.setState({ notifications: newNotifications });
		});
	}
	handleToggle8 = () => {

		var notificationStatus = this.state.notifications[7] === true ? false : true;
		
		var nodes = { 
			userkey: this.userkey,
			type: 8,
			value: notificationStatus
		}
		var	CDTURL = ServiceURL+"notifications_api/settings";
		var newNotifications = this.state.notifications;
		newNotifications[7] =  !newNotifications[7];
		axios.post(CDTURL, nodes).then(response => {
			this.setState({ notifications: newNotifications });
		});
	}
	handleToggle9 = () => {

		var notificationStatus = this.state.notifications[8] === true ? false : true;
		
		var nodes = { 
			userkey: this.userkey,
			type: 9,
			value: notificationStatus
		}
		var	CDTURL = ServiceURL+"notifications_api/settings";
		var newNotifications = this.state.notifications;
		newNotifications[8] =  !newNotifications[8];
		axios.post(CDTURL, nodes).then(response => {
			this.setState({ notifications: newNotifications });
		});
	}
	handleToggle10 = () => {

		var notificationStatus = this.state.notifications[9] === true ? false : true;
		
		var nodes = { 
			userkey: this.userkey,
			type: 10,
			value: notificationStatus
		}
		var	CDTURL = ServiceURL+"notifications_api/settings";
		var newNotifications = this.state.notifications;
		newNotifications[9] =  !newNotifications[9];
		axios.post(CDTURL, nodes).then(response => {
			this.setState({ notifications: newNotifications });
		});
	}

	render() {
		return (
				<LoadingOverlay active={this.state.loading} spinner={<RingLoader color={'#0000ff'} size="150" />}>
				<div id="editorPage">
					<div className={this.state.canvasShow?"sigCanvas":"sigCanvas hide"}>
						<button type="button" className="btn btn-default" onClick={this.closeCanvas}>Exit</button> &nbsp; &nbsp; &nbsp; &nbsp;
						<button type="button" className="btn btn-default" onClick={this.clearCanvas}>Clear</button>
						<SignatureCanvas ref={(ref) => { this.sigCanvas = ref }} canvasProps={{width: window.innerWidth, height: window.innerHeight}}  penColor="red" />
					</div>
					<div className="c_line-header">
						<div className="pull-left">
							<ul className="nc_ul_menu">
								<li className="nc_ul_list"> Node style : </li>
								<li className="nc_ul_list">
									<select className="form-control nodestyle" onChange={this.changeNodeStyle} >
										<option value="numCounter">1, 2, 3</option>
										<option value="numCounterDisc"> * </option>
										<option value="numCounterBrace">1), 2), 3)</option>
										<option value="numCounterLowerAlpha">a, b, c</option>
										<option value="numCounterUpperAlpha">A, B, C</option>
										<option value="numCounterUpperRoman">I, II, III</option>
									</select>
								</li>
							</ul>
						</div>
						<div className="pull-left">
							<ul className="nc_ul_menu">
								<li className="nc_ul_list">&nbsp;&nbsp;&nbsp;&nbsp;<Editoricons /> </li>
							</ul>
						</div>
						<div className="pull-right">
							<ul className="nc_ul_menu">{
								this.state.isLogged?"":<li className="nc_ul_list">
									<Link id="logIn" to="/login" className="btn btn-primary btn-bold">Log in</Link>
								</li>
							}
							{
								this.state.isLogged?"":<li className="nc_ul_list">
									<Link to="/signup" id="signUp" className="btn btn-warning btn-bold">Sign up</Link>
								</li>
							}
							<li id="search" className="nc_ul_list">
								<span className="nc_alerts" id="searchInactive" onClick={this.handleSearchInactive}>
									<img id="searchView" src="images/command-icon/search.png" alt="eye" width="24" />
								</span>
								<span id="searchActive" className={this.state.searchActiveShow?"":"hide"}>
									<div className="nc-fgroup">
										<div className="form-group">
											<div className="ip-group">
												<input type="text" id="searchLine"  className="form-control ip-input" placeholder="Search text here"/>
												<span className="iga-btn cls-danger" onClick={this.handleSearchActive} id="searchLineHide"><span className="glyphicon glyphicon-remove"></span></span>
											</div>
										</div>
									</div>
								</span>
							</li>
							<li className="nc_ul_list dropdown" id="options">
								<Dropdown id="dropdown-custom-menu">
									<Dropdown.Toggle bsRole="toggle">
										<img src="images/command-icon/settings.png" alt="settings" width="32" />
									</Dropdown.Toggle>
									<Dropdown.Menu  className="dropdown-menu settings-list" id="optionsPopup">
										<MenuItem  id="settings" bsRole="item" href="/#/Settings">
											<button type="button" className="anchor-link set-anchor">
												<img src="images/command-icon/settings.png" alt="settings" className="img-responsive" />
												<p className="node-text"><strong>Settings </strong><br />&nbsp;<span className="n-sub-txt">Your account and preferences</span></p>
											</button>
										</MenuItem>
										<MenuItem  id="upgrade">
											<button type="button" className="anchor-link set-anchor">
												<img src="images/command-icon/upgrade.png" alt="upgrade" className="img-responsive"/>
												<p className="node-text"><strong>Upgrade</strong><br />&nbsp;<span className="n-sub-txt">Migrate to unlimited plan</span></p>
											</button>
										</MenuItem>
										<MenuItem id="trial">
											<button type="button" className="anchor-link set-anchor">
												<img src="images/command-icon/need_time.png" alt="need_time" className="img-responsive"/>
												<p className="node-text">
													<strong>Need more time</strong><br />?&nbsp;
													<span className="n-sub-txt">
													<strong>2</strong> days of trail period left </span>
												</p>
											</button>
										</MenuItem>
											<MenuItem id="help">
												<button type="button" className="anchor-link set-anchor">
													<img src="images/command-icon/question.png" alt="question" className="img-responsive"/>
													<p className="node-text">
														<strong>Help</strong> <br />&nbsp;
														<span className="n-sub-txt">How-to, Tutorials, Support, Contacts</span>
													</p>
												</button>
											</MenuItem>
											{
												this.state.isLogged?<MenuItem className="nc_ul_list">
													<button id="logOut" type="button" className="anchor-link set-anchor redImg" onClick={this.doLogOut}>
														<img src="images/command-icon/logout.png" alt="question" className="img-responsive"/>
														<p className="node-text"><strong className="opt-name">Log out</strong><br />&nbsp;<span className="n-sub-txt opt-description">dmelent@medianet-home.de</span></p>
													</button>
												</MenuItem>: ""
											}
											{
												this.state.isLogged?<MenuItem className="nc_ul_list">
													<button id="unsubscribe" type="button" className="anchor-link set-anchor redImg" onClick={this.doUnsubscribe}>
														<img src="images/command-icon/unsubscribe.png" alt="question" className="img-responsive"/>
														<p className="node-text"><strong>Unsubscribe</strong><br />&nbsp;<span className="n-sub-txt">Delete my account and all my data</span></p>
													</button>
												</MenuItem>: ""
											}
										</Dropdown.Menu>
									</Dropdown>
								</li>
							</ul>
						</div>
						<div className="clearfix"></div>
					</div>

					<div id="breadcrumb">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/home/all/nodes">me</Link>/ settings
								</li>
							</ol>
						</nav>
					</div>
					{console.log(this.state)}
					<div id="settings">
						<div className="notification-table">
							<table className="table table-bordered table-stripped table-hover">
								<thead>
									<tr>
										<th>Notification type</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Application level notifications { console.log("this.state.notifications[0]",this.state.notifications[0])} {this.state.notifications[0]} </td> 
										<td> <Toggle on={"on"} off={"off"}  onClick={this.handleToggle1}  offstyle="danger" active={this.state.notifications[0]} /> </td>
									</tr>
									<tr>
										<td>Node level notifications </td>
										<td> <Toggle on={"on"} off={"off"}  onClick={this.handleToggle2} offstyle="danger"  active={this.state.notifications[1]} /> </td>
									</tr>
									<tr>
										<td>Node Creation </td>
										<td> <Toggle on={"on"} off={"off"}  onClick={this.handleToggle3}  offstyle="danger" active={this.state.notifications[2]} /> </td>
									</tr>
									<tr>
										<td>Node Updation </td>
										<td> <Toggle on={"on"} off={"off"}  onClick={this.handleToggle4}  offstyle="danger" active={this.state.notifications[3]} /> </td>
									</tr>
									<tr>
										<td>Sub Node creation </td>
										<td> <Toggle on={"on"} off={"off"}  onClick={this.handleToggle5}  offstyle="danger" active={this.state.notifications[4]} /> </td>
									</tr>
									<tr>
										<td>Add Attachment </td>
										<td> <Toggle on={"on"} off={"off"}  onClick={this.handleToggle6}  offstyle="danger" active={this.state.notifications[5]} /> </td>
									</tr>
									<tr>
										<td>Delete Attachment </td>
										<td> <Toggle on={"on"} off={"off"}  onClick={this.handleToggle7}  offstyle="danger" active={this.state.notifications[6]} /> </td>
									</tr>
									<tr>
										<td>Add Comment </td>
										<td> <Toggle on={"on"} off={"off"}  onClick={this.handleToggle8} offstyle="danger" active={this.state.notifications[7]} /> </td>
									</tr>
									<tr>
										<td>Reply to Comment </td>
										<td> <Toggle on={"on"} off={"off"}  onClick={this.handleToggle9} offstyle="danger" active={this.state.notifications[8]} /> </td>
									</tr>
									<tr>
										<td>Node deletion </td>
										<td> <Toggle on={"on"} off={"off"}  onClick={this.handleToggle10} offstyle="danger" active={this.state.notifications[9]} /> </td>
									</tr>									
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</LoadingOverlay>
		)
	}
}
export default Settings;