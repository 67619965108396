import React,{Component} from 'react';
import { HashRouter as Router, Route, Switch} from "react-router-dom";
import { Provider } from 'react-redux'
import store from './redux/store'
import Landingcdt from "./components/body/landing/landing";
import Signup 	  from "./components/body/signup/signup";
import Signupkey  from "./components/body/signup/signupkey";
import Test from "./components/body/test";
import Login      from "./components/body/login/login";
import BootTest      from "./components/body/boottest";
import Loginkey   from "./components/body/login/loginkey";
/*import Home       from "./components/body/editor/js/home";*/
import Cdt       from "./components/body/cdt/js/cdt";
import Nroles       from "./components/body/nroles/js/nroles";
import Draft       from "./components/body/draft/draft";
import Users       from "./components/body/Users";
import settingsPopup       from "./components/body/settingsPopup";
import Settings       from "./components/body/settings";
import Pricing    from "./components/body/pricing";
import Help    from "./components/body/help";
import CdtHeader  from "./components/header/header";
import NotFound   from './notFound.js';
import './bootstrap/css/bootstrap.css';
import './App.css';
class App extends Component {
	render(){
		return (
			<Provider store={store}>
				<Router>
					<div className="App">
						<Switch>
							<Route exact strict path="/" 			component={Landingcdt} />
							<Route exact strict path="/Landing" 	component={Landingcdt} />
							<Route exact strict path="/Signupkey" 	component={Signupkey} />
							<Route exact strict path="/Loginkey" 	component={Loginkey} />
							<Route exact strict path="/header" 		component={CdtHeader} />
							<Route  path="/home/:id/nodes" 			component={Cdt} />
							<Route exact strict path="/draft" 		component={Draft} />
							<Route exact strict path="/Login"  		component={Login} />
							<Route exact strict path="/Signup" 		component={Signup} />
							<Route exact strict path="/Pricing" 		component={Pricing} />
							<Route exact strict path="/Help" 		component={Help} />
							<Route exact strict path="/Users" 		component={Users} />
							<Route exact strict path="/test" 		component={Test} />
							<Route exact strict path="/nroles" 		component={Nroles} />
							<Route exact strict path="/Settings" 	component={Settings} />
							<Route exact strict path="/boottest" 	component={BootTest} />
							<Route exact strict path="/settingsPopup" component={settingsPopup}/>
							<Route exact strict path="*" 			component={NotFound} />
						</Switch>
					 </div>
				</Router>
			</Provider>
		);
	}
}
export default App;
