import React, { Component } from "react";
import cookie from 'react-cookies';
import { Link } from "react-router-dom";
import axios from "axios";
import SignatureCanvas from 'react-signature-canvas';
import styled from 'styled-components'
import { Dropdown, MenuItem, Form, FormGroup, InputGroup, Popover} from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from 'react-spinners/RingLoader';
import Editoricons from '../../editor/js/ecommands';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Anodes from '../js/anodes';
import Nodes from '../js/nodes';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ANF from '../js/anodesfunc';
import '../css/cdt.css';
import '../css/react-notifications.css';
import '../css/cdtdom.css';
import ServiceURL from '../../../../constants/constants';
import '../../../../css/style.css';
import '../../../../common.css';
import '../../editor/css/editor.css';
import '../../editor/css/home.css';
import $ from 'jquery';

const StyledPopover = styled(Popover)
`min-width: 600px;`

var prev2Command = '';
var prevCommand = '';
var thisCommand = '';
var previd = 0;

class Cdt extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nodes: [],
			aNodes: [],
			//userslist: [],
			commandActiveshow: false,
			commandInactiveshow: true,
			commandLineHideshow: false,
			eyeActiveShow: false,
			searchActiveShow: false,
			isLogged: cookie.load('userAcc') ? true : false,
			canvasShow: false,
			tempnodeCounter: 0,
			usersStyle: { display: 'none' },
			assignTarget:0,
			//roles:[],
			popupTargetNode:"",
			foundNodeValue:{},
			testCounter:0,
			breadCrumb:[],
			activenode:0,
			notifications:[]
		}
		this.showCommands = false;
		this.userListShow= false;
		this.loading = true;
		this.userslist = [];
		this.roles = [];

		ANF.saveNodeName = ANF.saveNodeName.bind(this);
		ANF.saveNodeDesc = ANF.saveNodeDesc.bind(this);
		ANF.findObjectById = ANF.findObjectById.bind(this);
		ANF.findObjectByIdsub = ANF.findObjectByIdsub.bind(this);
		ANF.findObjectByIdAndReplace = ANF.findObjectByIdAndReplace.bind(this);
		ANF.findObjectByIdAndReplacesub = ANF.findObjectByIdAndReplacesub.bind(this);

		this.userkey = cookie.load('userAcc') ? cookie.load('userAcc') : 'nokey';
		this.useremail = cookie.load('userEmail') ? cookie.load('userEmail') : 'noemail';
		this.name = cookie.load('Name') ? cookie.load('Name') : 'noname';
		this.nickname = cookie.load('nickname') ? cookie.load('nickname') : 'nonickname';


		this.getData();
				
		

		var nodes={userkey:this.userkey}
		var CDTURL = ServiceURL+"nodes_api/getAsignedNodes";
		
		axios.post(CDTURL, nodes).then(response => {
			this.setState({ aNodes: response.data.nodes });
		})

		document.title = "CDT";
		$(function() {
			$(".usersPopup").hide();
		});
		this.displayNotification('info');

	}

	closeCanvas = () => {
		this.setState({
			canvasShow: false
		})
	}

	clearCanvas = () => {
		this.sigCanvas.clear();
	}



	displayNotification = (type) => {
		return () => {
			switch (type) {
				case 'info':
					NotificationManager.info('Info message');
					break;
				case 'success':
					NotificationManager.success('Success message', 'Title here');
					break;
				case 'warning':
					NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
					break;
				case 'error':
				NotificationManager.error('Error message', 'Click me!', 5000, () => {
					alert('callback');
				});
				break;
				default:
				break;
			}
		};
	};


	componentDidUpdate (prevProps, prevState, snapshot){

		if(prevState.userslist !== this.userslist || prevState.nodes !== this.state.nodes){
			return false;
		}

		var patharray = this.props.location.pathname.split("/");
		var self =this;
		var CDTURL,nodes;

		if(this.state.nodes.length === 0){
			nodes = { userkey: this.userkey}
			CDTURL = ServiceURL+"nodes_api/getNodes";
			axios.post(CDTURL, nodes).then(response => {
				this.setState({ nodes: response.data.nodes,breadCrumb: [], });
			});
		}

		if(prevProps.location.pathname!== this.props.location.pathname && patharray[2]!=="all"){
			nodes = {
				userkey:this.userkey,
				nodeid:patharray[2]
			}
			this.setState({
				activenode:patharray[2]
			})
				this.loading = true;

			CDTURL = ServiceURL+"nodes_api/getSingleNode";
			axios.post(CDTURL, nodes).then(response => {
				this.setState({
					nodes:[]
				})
				this.setState({
					nodes:response.data.nodes,
					breadCrumb: response.data.breadcrumb,
				});
					this.loading = false;
			});
		}
		else if(prevProps.location.pathname!== this.props.location.pathname && patharray[2]==="all"){
			nodes = { userkey: this.userkey}
			CDTURL = ServiceURL+"nodes_api/getNodes";
			axios.post(CDTURL, nodes).then(response => {
				self.setState({ nodes: response.data.nodes,breadCrumb: [], });
			});
			CDTURL = ServiceURL+"nodes_api/getAsignedNodes";
			axios.post(CDTURL, nodes).then(response => {
				self.setState({ aNodes: response.data.nodes,breadCrumb: [], });
			});
		}
	}

	getSubnodes = (pos,nodes) => {
		var allnodes = this.state.nodes;
		allnodes[pos].subnodes = nodes;
		this.setState({
			nodes:allnodes
		})
		this.setState({
			nodes:allnodes
		})
	}
	/* mount converted */

	getData = () =>{
		var patharray = this.props.location.pathname.split("/");
		var nodes;
		var CDTURL = ServiceURL+"nodes_api/getInitialData";
		if(this.props.location.pathname && patharray[2]==="all"){
			nodes={userkey:this.userkey}
			
		}
		else{
			nodes={userkey:this.userkey,nodeid:patharray[2]}
			
		}
		axios.post(CDTURL,nodes).then(response => {
			this.userslist = response.data.users;
			this.roles = response.data.roles;
			this.setState({
				//userslist: response.data.users,
				//roles: response.data.roles,
				breadCrumb: response.data.breadcrumb,
				aNodes:response.data.anodes,
				nodes: response.data.nodes,
				notifications:response.data.notifications
			});
				this.loading =  false;
		});
		

	} 
	

	offLoading = () =>{
		this.loading = false;
	}

	
	/* mount converted */
	getSubnodesA = (pos,nodes) => {
		var allnodes = this.state.aNodes;
		allnodes[pos].subnodes = nodes;
		this.setState({
			aNodes:allnodes
		})
		this.setState({
			aNodes:allnodes
		})
	}
	handleCanvas = () => {
		this.setState({
			canvasShow: true
		})
	}

	handleChange = event => {
		if (event.keyCode === 13) {
			event.preventDefault();
			event.currentTarget.blur();
		} else if (event.key === 'Escape') {
			this.setState({
				[event.target.name]: ''
			});
		} else {
			this.setState({
				[event.target.name]: event.target.value
			});
		}
	}
	createNode = (event) => {
		if (thisCommand === 190 && prevCommand === 190) {
		} else if (event.target.value.trim() !== '') {
			var nodes = {
				name: event.target.value.trim(),
				userkey: this.userkey,
				parent: 0
			}
			var CDTURL = ServiceURL+"nodes_api/createNode";
			var self = this
			axios.post(CDTURL, nodes).then(response => {
				var newnode = {
					id: response.data.node_id,
					node_name: nodes.name,
					content: ''
				}
				var allnodes = this.state.nodes;
				allnodes.push(newnode);
				self.setState({
					nodes: allnodes
				})
			});
		}
	}

	doFocusName = (newid,area="id") => {
		$(function() {
			$(".node-name[data-"+ area +"=" + newid + "]").focus();
		});
	}

	doFocusContent = (newid,area="id") => {
		$(function() {
			$(".node-description[data-"+ area +"=" + newid + "]").focus();
		});
	}

	createEmptyNode = (dataposition,parentId,nodeName) => {
		var newid = "N" + this.state.tempnodeCounter;
		this.loading = true;
		var self = this;
		var nodes = {
			node_name: nodeName,
			node_id: newid,
			userkey: this.userkey,
			position: parseInt(dataposition) + 1,
			parent:parentId
		}
		var CDTURL = ServiceURL+"nodes_api/update_nodename";
		axios.post(CDTURL, nodes).then(response => {
			

			this.createNotification(self.userkey,response.data.nodeid,3);
			var newnode = {
				id: response.data.nodeid,
				node_name: nodeName,
				content: '',
				showcontent:true,
				childsDisplayed:'1',
				subnodes:'0',
				position: parseInt(dataposition) + 1,
				parent:parentId,
				contributors:"No data found"
			}
			if(parseInt(parentId) === 0){
				var allnodes = this.state.nodes
				allnodes.splice(parseInt(dataposition) + 1,0,newnode);
				this.setState({nodes:[]})
				this.setState({nodes:allnodes})
				this.doFocusName(response.data.nodeid);
			}
			else if(parseInt(parentId) > 0){
				var parentObj  = this.findObjectById(parentId);
				if(parentObj){
					if(parentObj.subnodes === "0"){
						parentObj.subnodes = [newnode];
					}
					else{
						parentObj.subnodes.splice(parseInt(dataposition) + 1,0,newnode);
					}
					this.findObjectByIdAndReplace(parentId,parentObj);
					this.doFocusName(response.data.nodeid);
				}
			}
			this.loading = false
		}).catch(error=>{
			this.loading = false
		});
		var newCounter = this.state.tempnodeCounter + 1;
		this.setState({
			tempnodeCounter: newCounter
		})
	}
	saveNodeDesc = (event) => {
		var newcontent = event.target.innerHTML.trim();
		var nodeid = $(event.target).attr("data-id");
		var nodeposition = $(event.target).attr("data-position");
		var nodeContent = this.findObjectById(nodeid);

		if (newcontent !== nodeContent.content && newcontent !== "") {
			//this.setState({loading:true})
			this.loading  = true;
			var dataid = $(event.target).attr("data-id");
			var nodes = {
				node_id: dataid,
				userkey: this.userkey,
				content: newcontent,
				position: nodeposition
			}
			var CDTURL = ServiceURL+"nodes_api/update_nodedesc";
			axios.post(CDTURL, nodes).then(response => {
				if (dataid !== response.data.nodeid) {
					var newnode = {
						id: response.data.nodeid,
						node_name:$(".node-name[data-id='"+ dataid +"']") ,
						content: newcontent,
						showcontent:true,
						childsDisplayed:'1',
						subnodes:'0',
						parent:'0',
						contributors:"No data found"
					}
					this.findObjectByIdAndReplace(dataid,newnode,"saveNodeDesc");
				}
				this.loading = false;
			}).catch(error=>{
				this.loading =  false;

			});
			nodeContent.content = newcontent;
			this.createNotification(this.userkey,dataid,4);
			this.findObjectByIdAndReplace(nodeid,nodeContent,"saveNodeDesc");
		}
	}
	saveNodeName = (event) => {
		var newcontent = $(event.target).text();
		newcontent = newcontent.trim();
		if (newcontent.substr(-4, 4) === '<br>') {
			newcontent = newcontent.slice(0, -4);
		}
		if (newcontent === "<div><br></div>") {
			newcontent = newcontent.replace("<div><br></div>", "");
		}
		var uipos = $(event.target).attr("data-position");
		var nodeid = $(event.target).attr("data-id");
		var nodeContent = this.findObjectById(nodeid);
		var dataid = $(event.target).attr("data-id");
		var node_name = "";
		if(nodeContent !== false){
			node_name = nodeContent.node_name;
		}
		if (newcontent.trim() !== node_name.trim() && newcontent !== "") {

			this.loading = true;

			var parentId = $(event.target).attr("data-parent");
			var nodes = {
				node_name: newcontent,
				node_id: dataid,
				userkey: this.userkey,
				position: uipos,
				parent:parentId
			}
			nodeContent.node_name = newcontent;
			this.findObjectByIdAndReplace(nodeid,nodeContent,"saveNodeName");
			var CDTURL = ServiceURL+"nodes_api/update_nodename";
			axios.post(CDTURL, nodes).then(response => {
				if (dataid !== response.data.nodeid) {
					var newnode = {
						id: response.data.nodeid,
						node_name: newcontent,
						content: '',
						showcontent:true,
						childsDisplayed:'1',
						subnodes:'0',
						parent:parentId,
						contributors:"No data found"
					}
					this.findObjectByIdAndReplace(dataid,newnode,"moveTodesc");
				}
				this.loading = false;
			}).catch(error=>{
				this.loading = false;
			});
			this.createNotification(this.userkey,dataid,4);
		}
	}

	changeNodeStyle = () =>{
		var style = $(".nodestyle").val();
		$(function(){
			$(".numaric").removeClass("numCounterDisc");
			$(".numaric").removeClass("numCounter");
			$(".numaric").removeClass("numCounterBrace");
			$(".numaric").removeClass("numCounterLowerAlpha");
			$(".numaric").removeClass("numCounterUpperAlpha");
			$(".numaric").removeClass("numCounterUpperRoman");
			switch(style) {
				case "numCounterDisc":
					$(".numaric").addClass("numCounterDisc");
					break;
				case "numCounter":
					$(".numaric").addClass("numCounter");
					break;
				case "numCounterBrace":
					$(".numaric").addClass("numCounterBrace");
					break;
				case "numCounterLowerAlpha":
					$(".numaric").addClass("numCounterLowerAlpha");
					break;
				case "numCounterUpperAlpha":
					$(".numaric").addClass("numCounterUpperAlpha");
					break;
				case "numCounterUpperRoman":
					$(".numaric").addClass("numCounterUpperRoman");
					break;
				default :
					$(".numaric").addClass("numCounter");
					break;
			}
		});
	}
	
	closePopup = () =>{
		var CDTURL = ServiceURL+"N_rights_api/node_roles_add";
		var role = this.state.selectedRole;
		var users = this.state.selectedUsers;
		var newRoleUsers ="";
		for(var i=0;i<users.length;i++){
			if(users[i].userid === "new"){
				newRoleUsers += users[i].email+"-"+role.id +"," ;
			}
			else{
				newRoleUsers += users[i].userid+"-"+role.id +"," ;
			}
		}
		var nodes = {
			nodeid:parseInt(this.state.assignTarget),
			user_rights:newRoleUsers
		}
		var self = this;
		axios.post(CDTURL,nodes).then(response=>{
			var targetNodeObj = self.findObjectById(parseInt(self.state.assignTarget));
			targetNodeObj.contributors = response.data.response;
			self.findObjectByIdAndReplace(self.state.assignTarget,targetNodeObj);
		});
		var newstyles = { "display": 'none', position: 'absolute' }
		this.setState({
			usersStyle: newstyles,
		})
			this.userListShow =  false
	}

	findObjectById = (nodeid) =>{
		var found;
		var allnodes = this.state.nodes;
		for(var i=0;i<=allnodes.length;i++){
			if(allnodes[i]){
				if(parseInt(allnodes[i].id) === parseInt(nodeid)){
					return allnodes[i];
				}
				else{
					if(allnodes[i].subnodes !== "0" && allnodes[i].subnodes !== undefined ){
						found = this.findObjectByIdsub(allnodes[i].subnodes,nodeid);
						if(found.status === true){
							return found.foundobj;
						}
					}
				}
			}
		}
		return false;
	}

	findObjectByIdsub = (allnodes,nodeid) =>{
		var found = {
			foundobj:{},
			status:false
		};
		for(var i=0;i<=allnodes.length;i++){
			if(allnodes[i]){
				if(parseInt(allnodes[i].id) === parseInt(nodeid)){
					found.status = true;
					found.foundobj = allnodes[i];
					return found;
				}
				else{
					if(allnodes[i].subnodes !== "0" && allnodes[i].subnodes !== undefined){
						found =  this.findObjectByIdsub(allnodes[i].subnodes,nodeid);
						if(found.status === true){
							return found;
						}
					}
				}
			}
		}
		return found;
	}

	swapNodes = (nodeIdA,nodeIdB,nodeAPosition,nodeBPosition) =>{
		var allnodes = this.state.nodes;
		var nodes = {}
		this.loading= true;
		if(allnodes[nodeAPosition]!== undefined && parseInt(allnodes[nodeAPosition].id) === parseInt(nodeIdA)){
			nodes.nodeA = allnodes[nodeAPosition];
			nodes.nodeB = allnodes[nodeBPosition];
			allnodes[nodeAPosition] = nodes.nodeB;
			allnodes[nodeBPosition] = nodes.nodeA;
		}
		else{
			for(var i=0;i<=allnodes.length;i++){
				if( allnodes[i]!== undefined && allnodes[i].subnodes !== "0"){
					allnodes[i].subnodes = this.swapInsubNodes(allnodes[i].subnodes, nodeIdA,nodeIdB,nodeAPosition,nodeBPosition);
				}
			}
		}
		this.setState({
			nodes:[]
		})
		this.setState({
			nodes:allnodes
		})
		$(function (){
			$(".node-name[data-id='"+nodeIdA+"']").focus();
		})

		var nodesA = {
			node_id: nodeIdA,
			userkey: this.userkey,
			position: nodeBPosition
		}

		var CDTURLA = ServiceURL+"nodes_api/update_nodeposition";
		axios.post(CDTURLA, nodesA).then(response => {
		});
		var nodesB = {
			node_id: nodeIdB,
			userkey: this.userkey,
			position: nodeAPosition
		}
		var CDTURLB = ServiceURL+"nodes_api/update_nodeposition";
		axios.post(CDTURLB, nodesB).then(response => {
			this.loading = false
		}).catch(error=>{
			this.loading = false
		});
	}
	swapInsubNodes = (allnodes, nodeIdA,nodeIdB,nodeAPosition,nodeBPosition) =>{
		var nodes = {}
		if(allnodes[nodeAPosition]!== undefined && parseInt(allnodes[nodeAPosition].id) === parseInt(nodeIdA)){
			nodes.nodeA = allnodes[nodeAPosition];
			nodes.nodeB = allnodes[nodeBPosition];
			allnodes[nodeAPosition] = nodes.nodeB;
			allnodes[nodeBPosition] = nodes.nodeA;
		}
		else{
			for(var i=0;i<=allnodes.length;i++){
				if( allnodes[i]!== undefined && allnodes[i].subnodes !== "0"){
					allnodes[i].subnodes = this.swapInsubNodes(allnodes[i].subnodes, nodeIdA,nodeIdB,nodeAPosition,nodeBPosition);
				}
			}
		}
		return allnodes;
	}

	findObjectByIdAndDelete = (nodeid) =>{
		var allnodes = this.state.nodes;
		for(var i=0;i<=allnodes.length;i++){
			if(allnodes[i]){
				if(parseInt(allnodes[i].id) === parseInt(nodeid)){
					allnodes.splice(i,1);
				}
				else{
					if(parseInt(allnodes[i].subnodes) !== 0 ){
						allnodes[i].subnodes = this.findObjectByIdAndDeleteSub(allnodes[i].subnodes,nodeid);
					}
				}
			}
		}
		this.setState({
			nodes:[]
		})
		this.setState({
			nodes:allnodes
		})
		$(function (){
			$(".node-name[data-prevsibling='"+nodeid+"']").focus();
		})
		return false;
	}

	findObjectByIdAndDeleteSub = (allnodes,nodeid) =>{
		for(var i=0;i<=allnodes.length;i++){
			if(allnodes[i]){
				if(parseInt(allnodes[i].id) === parseInt(nodeid)){
					allnodes.splice(i,1);
				}
				else{
					if(parseInt(allnodes[i].subnodes) !== 0){
						allnodes[i].subnodes = this.findObjectByIdAndDeleteSub(allnodes[i].subnodes,nodeid);
					}
				}
			}
		}
		return allnodes;
	}

	findObjectByIdAndReplace = (nodeid,nodeobj, area="saveother") =>{
		var allnodes = this.state.nodes;
		for(var i=0;i<=allnodes.length;i++){
			if(allnodes[i]){
				if(parseInt(allnodes[i].id) === parseInt(nodeid)){
					allnodes[i] = nodeobj;
				}
				else{
					if(parseInt(allnodes[i].subnodes) !== 0 ){
						allnodes[i].subnodes = this.findObjectByIdAndReplacesub(allnodes[i].subnodes,nodeid,nodeobj);
					}
				}
			}
		}
		if(this.state.nodes.length === 0){
			allnodes[0] = nodeobj;
		}
		this.setState({
			nodes:[]
		})

		if(area === "moveTodesc"){
			this.setState({
				nodes:allnodes
			}, () => {
				$(function (){
					$(".node-description[data-id='"+allnodes[0].id+"']").focus();
				})
			})
		}
		else{
			this.setState({
				nodes:allnodes
			})
		}

		if(area === "saveother"){
			$(function (){
				$(".node-name[data-id='"+nodeid+"']").focus();
			})
		}
		return false;
	}

	findObjectByIdAndReplacesub = (allnodes,nodeid, nodeobj) =>{
		for(var i=0;i<=allnodes.length;i++){
			if(allnodes[i]){
				if(parseInt(allnodes[i].id) === parseInt(nodeid)){
					allnodes[i] = nodeobj;
				}
				else{
					if(parseInt(allnodes[i].subnodes) !== 0){
						allnodes[i].subnodes = this.findObjectByIdAndReplacesub(allnodes[i].subnodes,nodeid, nodeobj);
					}
				}
			}
		}
		return allnodes;
	}

	findIdandChangeData = (nodeid, parentId, tabType='tab',position=0)=>{
		this.loading = true;
		var allnodes = this.state.nodes;
		var subresults,foundNodeValue;
		for(var i=0; i < allnodes.length; i++){
			if(parseInt(allnodes[i].id) === parseInt(nodeid)){
				allnodes[i].parent = parentId;
				foundNodeValue = allnodes[i];
				allnodes.splice(i,1);
			}
			else{
				if(allnodes[i].subnodes !== "0" && allnodes[i].subnodes !== undefined){
					subresults = this.checkInSubnodes(allnodes[i].subnodes,nodeid,parentId,tabType);
					if(subresults.status === true){
						allnodes[i].subnodes = subresults.results;
						foundNodeValue = subresults.foundNodeValue;
					}
				}
			}
		}
		if(tabType === 'tab'){
			for( i=0; i < allnodes.length; i++){
				if(parseInt(allnodes[i].id) === parseInt(parentId)){
					if(Array.isArray(allnodes[i].subnodes)){
						allnodes[i].subnodes.push(foundNodeValue);
					}
					else{
						allnodes[i].subnodes = [foundNodeValue];
					}
				}
				else{
					if(allnodes[i].subnodes !== "0" && allnodes[i].subnodes !== undefined && allnodes[i].subnodes.length >0){
						subresults = this.pasteInSubnodes(allnodes[i].subnodes,parentId,foundNodeValue,tabType,position);
						if(subresults.status === true){
							allnodes[i].subnodes = subresults.results;
						}
					}
				}
			}
		}
		else if(tabType === 'shift_tab'){
			if(parseInt(parentId) === 0){
				allnodes.splice(position ,0,foundNodeValue);
			}
			else{
				for( i=0; i < allnodes.length; i++){
					if(parseInt(allnodes[i].id) === parseInt(parentId)){
						allnodes[i].subnodes.splice(position,0,foundNodeValue);
					}
					else if(allnodes[i].subnodes !== "0" && allnodes[i].subnodes !== undefined && allnodes[i].subnodes.length >0){
						subresults = this.pasteInSubnodes(allnodes[i].subnodes,parentId,foundNodeValue,tabType,position);
						if(subresults.status === true){
							allnodes[i].subnodes = subresults.results;
						}
					}
				}
			}
		}
		this.setState({
			nodes:[]
		});
		this.setState({
			nodes:allnodes
		});
		this.loading = false;
		$(function (){
			$(".node-name[data-id='"+nodeid+"']").focus();
		})
	}
	checkInSubnodes = (subnodes,nodeid,parentId,tabType) =>{
		var found = {
			status:false,
			results:subnodes,
			foundNodeValue:{}
		};
		var subresults;
		for(var i=0; i< subnodes.length; i++){
			if(parseInt(subnodes[i].id) === parseInt(nodeid)){
				subnodes[i].parent=parentId;
				found.foundNodeValue = subnodes[i];
				found.status = true;
				subnodes.splice(i,1);
				found.results = subnodes;
				return found;
			}
			else{
				if(subnodes[i].subnodes !== "0" && subnodes[i].subnodes !== undefined){
					subresults = this.checkInSubnodes(subnodes[i].subnodes,nodeid,parentId,tabType);
					if(subresults.status === true){
						subnodes[i].subnodes = subresults.results;
						found.foundNodeValue = subresults.foundNodeValue;
						found.results = subnodes;
						found.status = true;
						return found;
					}
				}
			}
		}
		return found;
	}

	pasteInSubnodes = (subnodes,parentId,foundNodeValue,tabType,position)=>{
		var found = {
			status:false,
			results:subnodes
		};
		var subresults;
		for(var i=0; i< subnodes.length; i++){
			if(parseInt(subnodes[i].id) === parseInt(parentId)){
				if(tabType === "tab"){
					if(Array.isArray(subnodes[i].subnodes)){
						subnodes[i].subnodes.push(foundNodeValue);
					}
					else{
						subnodes[i].subnodes = [foundNodeValue];
					}
				}
				else if(tabType === "shift_tab"){
					subnodes[i].subnodes.splice(position,0,foundNodeValue);
				}
			}
			else{
				if(subnodes[i].subnodes !== "0" && subnodes[i].subnodes !== undefined){
					subresults = this.pasteInSubnodes(subnodes[i].subnodes, parentId, foundNodeValue,tabType,position);
					if(subresults.status === true){
						found.results[i].subnodes = subresults.results;
						found.status = true;
						return found;
					}
				}
			}
		}
		return found;
	}

	usersPopupKeyDown =(event) =>{
		if(event.keyCode === 27){
			this.closePopup();
		}
	}

	openuserPopup = (event) =>{
		var dataid = $(event.target).attr("data-id");
		document.getElementById("userPopupForm").reset();
		this.setState({
			popupTargetNode:dataid
		})
		var newstyles = { "display": 'block', "top": event.clientY + "px", "left": parseInt(event.clientX) + 1 + "px", position: 'absolute' }
		this.setState({
			selectedUsers:[],
			selectedRole:'',
			usersStyle: newstyles,
			assignTarget:dataid
		})
			this.userListShow = true
		var container = document.getElementById("usersFilter");
		var input = container.getElementsByTagName('input')[0];
		$(function (){
			$(input).focus();
		})
	}

	keycheckContent = (event, test = "default") => {
		this.setState({
			selectedUsers:[],
			selectedRole:''
		})
		var newstyles;
		var dataid = $(event.target).attr("data-id");
		var position = $(event.target).attr("data-position");
		var nodes={};
		var CDTURL =  ServiceURL + "nodes_api/";

		/*if(event.key === "@"){
			event.stopPropagation();
			event.preventDefault();
			document.getElementById("userPopupForm").reset();
			this.setState({
				popupTargetNode:dataid
			})
			var eventpos = this.getCaretTopPoint();
			newstyles = { "display": 'block', "top": eventpos.top + "px", "left": parseInt(eventpos.left) + 1 + "px", position: 'absolute' }
			this.setState({
				usersStyle: newstyles,
				assignTarget:dataid
			})
			this.userListShow = true;
			var container = document.getElementById("usersFilter");
			var input = container.getElementsByTagName('input')[0];
			$(function (){
				$(input).focus();
			})
		}*/
		switch (event.which || event.keyCode) {
			case 27:
				newstyles = { "display": 'none',position: 'absolute' }
				this.setState({
					usersStyle: newstyles,
				})
				this.userListShow = false;
				var self = this
				$(function(){
					$(".node-description[data-id='"+ self.state.assignTarget +"']").focus();
				})
				break;
			case 190:
				event.stopPropagation();
				event.preventDefault();
				dataid = $(event.target).attr("data-id");
				var newVisible = $(event.target).attr("data-childsdisplayed") ==="1"?"0":"1";
				if(event.shiftKey && event.ctrlKey){
					nodes = {
						nodeid:dataid,
						childsDisplayed:newVisible
					}
					this.toggleNode(nodes);
				}
				break;
			case 38:
				if(event.ctrlKey){
					var targetNode = $(event.target).attr("data-prevsibling");
					if(parseInt(targetNode) !== 0){
						$(function (){
							$(".node-name[data-id='"+parseInt(targetNode)+"']").focus();
						})
					}
				}
				break;
			case 40:
				if(event.ctrlKey){
					targetNode = $(event.target).attr("data-nextsibling");
					if(parseInt(targetNode) !== 0){
						$(function (){
							$(".node-name[data-id='"+parseInt(targetNode)+"']").focus();
						})
					}
				}
				break;
			case 9: /** Tab key press*/
				event.stopPropagation();
				event.preventDefault();
				if (event.shiftKey) {
					var parentid = $(event.target).attr("data-parent");
					if(parseInt(parentid) !==0){
						var grandParent = $(".node-name[data-id="+ parentid+"]").attr("data-parent");
						var newPosition = parseInt($(".node-name[data-id="+ parentid+"]").attr("data-position"));
						nodes={
							parent:grandParent,
							position:newPosition,
							nodeid:dataid,
							userkey:this.userkey
						}
						this.findIdandChangeData(dataid,grandParent,'shift_tab',newPosition);
						axios.post(CDTURL + "update_parent", nodes);
					}
				}
				else {
					if(parseInt(position) !==0){
						var newParent = $(event.target).attr("data-prevsibling");
						nodes={
							parent:newParent,
							nodeid:dataid,
							position:0,
							userkey:this.userkey
						}
						this.findIdandChangeData(dataid,newParent,'tab',0);
						axios.post(CDTURL + "update_parent", nodes);
					}
				}
				break;
			default:
				break;
		}
	}

	toggleNode = (nodes)=>{
		var CDTURL = ServiceURL+"nodes_api/nodeDisplay";
		axios.post(CDTURL, nodes);
	}

	getCaretTopPoint = () => {
		const sel = document.getSelection();
		const r = sel.getRangeAt(0);
		let rect;
		let r2;
		const node = r.startContainer;
		const offset = r.startOffset;
		if (offset > 0) {
			r2 = document.createRange();
			r2.setStart(node, (offset - 1));
			r2.setEnd(node, offset);
			rect = r2.getBoundingClientRect();
			return { left: rect.right, top: rect.top }
		} else if (offset < node.length) {
			r2 = document.createRange();
			r2.setStart(node, offset);
			r2.setEnd(node, (offset + 1));
			rect = r2.getBoundingClientRect();
			return { left: rect.left, top: rect.top }
		} else {
			rect = node.getBoundingClientRect();
			const styles = getComputedStyle(node);
			const lineHeight = parseInt(styles.lineHeight);
			const fontSize = parseInt(styles.fontSize);
			const delta = (lineHeight - fontSize) / 2;
			return { left: rect.left, top: (rect.top + delta) }
		}
	}

	Deletenode = (event) => {
		var confirmMsg =  window.confirm("do you really want to delete this node?");
		if(confirmMsg){
			var dataid = $(event.target).attr("data-id");
			var CDTURL =  ServiceURL + "nodes_api/";
			var nodes = {
				node_id: dataid,
				status: '0',
				userkey: this.userkey,
			};
			axios.post(CDTURL + "update_nodestatus", nodes);
			var nextSibling = $(event.target).attr("data-prevsibling");
			this.findObjectByIdAndDelete(dataid);
			this.createNotification(this.userkey,dataid,10);
			this.doFocusName(nextSibling);
		}
		else{
			console.log(confirmMsg);
		}
	}

	keycheck = (event, test = "default") => {
		this.displayNotification('info');
		// 50 = @, 8 = backspace, 13= enter, 9=tab
		var dataid = $(event.target).attr("data-id");
		var position = $(event.target).attr("data-position");
		var nodes={};
		var CDTURL =  ServiceURL + "nodes_api/";
		//var allnodes;
		
		switch (event.keyCode) {
			case 64:
				if(event.shiftKey){
					event.stopPropagation();
					event.preventDefault();
					/** on typing @ symbol */
					$(".usersPopup[data-id='" + dataid + "']").removeClass("hide");
					$(".usersPopup[data-id='" + dataid + "']").show();
				}
				break;
			case 8:
				if (event.ctrlKey && event.shiftKey) {
					var confirmMsg =  window.confirm("do you really want to delete this node?");
					if(confirmMsg){
						nodes = {
							node_id: dataid,
							status: '0',
							userkey: this.userkey,
						};
						axios.post(CDTURL + "update_nodestatus", nodes);
						var nextSibling = $(event.target).attr("data-prevsibling");
						this.findObjectByIdAndDelete(dataid);
						this.createNotification(this.userkey,dataid,10);
						this.doFocusName(nextSibling);
					}
					else{
						console.log(confirmMsg);
					}
				}
				break;
			case 13:
				if (event.shiftKey) {
					/** case shift enter */
					event.stopPropagation();
					event.preventDefault();
					var newobj = this.findObjectById(dataid);
					newobj.showcontent = true;
					this.findObjectByIdAndReplace(dataid,newobj);
					this.doFocusContent(dataid);
				} else {
					/** case Normal enter*/
					event.stopPropagation();
					event.preventDefault();
					var nodeName = '';
					if(isNaN(parseInt(dataid))){
						nodeName = $(event.target).text();
						$(event.target).blur();
					}
					else{
						this.saveNodeName(event);
						nodeName = '';
						var parent = $(event.target).attr("data-parent");
						this.createEmptyNode(position,parent, nodeName);
					}
				}
				break;
			case 9: /** Tab key press*/
				event.stopPropagation();
				event.preventDefault();
				this.saveNodeName(event);
				if (event.shiftKey) {
					var parentid = $(event.target).attr("data-parent");
					if(parseInt(parentid) !==0){
						var grandParent = $(".node-name[data-id="+ parentid+"]").attr("data-parent");
						var newPosition = parseInt($(".node-name[data-id="+ parentid+"]").attr("data-position") + 1);
						nodes={
							parent:grandParent,
							position:newPosition,
							nodeid:dataid,
							userkey:this.userkey
						}
						this.findIdandChangeData(dataid,grandParent,'shift_tab',newPosition);
						axios.post(CDTURL + "update_parent", nodes);
					}
				}
				else {
					if(parseInt(position) !==0){
						var newParent = $(event.target).attr("data-prevsibling");
						nodes={
							parent:newParent,
							nodeid:dataid,
							position:0,
							userkey:this.userkey
						}
						this.findIdandChangeData(dataid,newParent,'tab',0);
						axios.post(CDTURL + "update_parent", nodes);
					}
				}
				break;
			case 190:
				event.stopPropagation();
				event.preventDefault();
				var newVisible = $(event.target).attr("data-childsdisplayed") ==="1"?"0":"1";
				if(event.shiftKey && event.ctrlKey){
					nodes = {
						nodeid:dataid,
						childsDisplayed:newVisible
					}
					this.toggleNode(nodes);
				}
				break;
			case 37: /* CTRL + Shit + Left Arrow clicked */
				if(event.shiftKey){
					if(event.ctrlKey){
						event.stopPropagation();
						event.preventDefault();
						parentid = $(event.target).attr("data-parent");
						if(parseInt(parentid) !==0){
							grandParent = $(".node-name[data-id="+ parentid+"]").attr("data-parent");
							newPosition = parseInt($(".node-name[data-id="+ parentid+"]").attr("data-position")) + 1;
							nodes={
								parent:grandParent,
								position:newPosition,
								nodeid:dataid,
								userkey:this.userkey
							}
							this.findIdandChangeData(dataid,grandParent,'shift_tab',newPosition);
							axios.post(CDTURL + "update_parent", nodes);
						}
					}
				}
				break;
			case 38: /** CTRL  + Shift  + down arrow */
				if(event.shiftKey){
					if(event.ctrlKey){
						var nodeA = dataid;
						var nodeB = $(event.target).attr("data-prevsibling");
						if(parseInt(nodeB) !==0){
							var nodeAPosition = $(".node-name[data-id='"+ nodeA +"']").attr("data-position");
							var nodeBPosition = $(".node-name[data-id='"+ nodeB +"']").attr("data-position");
							this.swapNodes(nodeA, nodeB, nodeAPosition,nodeBPosition);
							$(function (){
								$(".node-name[data-id='"+nodeA+"']").focus();
							})
						}
					}
				}
				else if(event.ctrlKey){
					var targetNode = $(event.target).attr("data-prevsibling");
					if(parseInt(targetNode) !== 0){
						$(function (){
							$(".node-name[data-id='"+parseInt(targetNode)+"']").focus();
						})
					}
				}
				break;
			case 39: /* CTRL+ Shift + Right Arrow clicked */
				if (event.shiftKey) {
					if(event.ctrlKey){
						event.stopPropagation();
						event.preventDefault();
						if(parseInt(position) !==0){
							newParent = $(event.target).attr("data-prevsibling");
							nodes={
								parent:newParent,
								nodeid:dataid,
								position:0,
								userkey:this.userkey
							}
							this.findIdandChangeData(dataid,newParent,'tab',0);
							axios.post(CDTURL + "update_parent", nodes);
						}
					}
				}
				break;
			case 40: /* CTRL  + shift + uparrow**/
				if(event.shiftKey){
					if(event.ctrlKey){
						nodeA = dataid;
						nodeB = $(event.target).attr("data-nextsibling");
						if(parseInt(nodeB) !==0){
							nodeAPosition = $(".node-name[data-id='"+ nodeA +"']").attr("data-position");
							nodeBPosition = $(".node-name[data-id='"+ nodeB +"']").attr("data-position");
							this.swapNodes(nodeA, nodeB, nodeAPosition,nodeBPosition);
							$(function (){
								$(".node-name[data-id='"+nodeA+"']").focus();
							})
						}
					}
				}
				else if(event.ctrlKey){
					targetNode = $(event.target).attr("data-nextsibling");
					$(function (){
						$(".node-name[data-id='"+parseInt(targetNode)+"']").focus();
					})
				}
				break;
			default:
				break;
		}
		if (test === "default") {
			prev2Command = prevCommand;
			prevCommand = thisCommand;
			thisCommand = event.keyCode;
			if (thisCommand === 190 && prevCommand === 190 && prev2Command === 190) {
				if (this.showCommands === true) {
					this.setState({
						commandLineHideshow: false,
						commandInactiveshow: true,
						commandActiveshow: false
					});
					this.showCommands =  false;
					return true;
				}
			} else if (thisCommand === 190 && prevCommand === 190) {
				this.setState({
					commandLineHideshow: true,
					commandInactiveshow: false,
					commandActiveshow: true
				})
				this.showCommands = true;
				return true;
			}
		} else if (test === "spanclick") {
			prevCommand = "190";
			thisCommand = "190";
			this.setState({
				commandLineHideshow: true,
				commandInactiveshow: false,
				commandActiveshow: true
			});
			this.showCommands = true;
			return true;
		} else if (test === "spanclose") {
			prevCommand = "190";
			thisCommand = "189";
			this.setState({
				commandLineHideshow: false,
				commandInactiveshow: true,
				commandActiveshow: false
			});
			this.showCommands = false;
		}
		return true;
	}
	assignUser = (event) => {
		var nodes={
			nodeid:this.state.assignTarget,
			userRole:$(event.target).val(),
		}
		axios.post( ServiceURL + "users_api/nodeInvitation", nodes);
	}
	handleInactive = (event) => {
		this.keycheck(event, "spanclick");
		$("#commandLine").focus();
	}
	handleActive = (event) => {
		this.keycheck(event, "spanclose");
		this.setState({
			commandLineHideshow: false,
			commandInactiveshow: true,
			commandActiveshow: false
		})
	}
	doLogOut = () => {
		cookie.remove('userAcc', { path: '/' });
		cookie.remove('userEmail', { path: '/' });
		cookie.remove('Name', { path: '/' });
		cookie.remove('nickname', { path: '/' });
		this.props.history.push({
			pathname: '/landing'
		});
	}
	/* Dima works on users popover*/
	isValidEmail = (email) => {
		var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	usersSearch = (option, searchText) => {
		var user = option.data;
		var text = searchText.toLowerCase();
		if (user.email == null) {
			if (this.isValidEmail(searchText)) {
				var newUser = this.createNewUser(searchText);
				user.email = newUser.email;
				user.name = 'Invite: ' + newUser.name;
				user.nickname = newUser.nickname;
				return true;
			}
			else{
				return false;
			}
		}
		return ((user.name != null) && (user.name.toLowerCase().indexOf(text) !== -1)) || ((user.nickname != null) && (user.nickname.toLowerCase().indexOf(text) !== -1)) || ((user.email != null) && (user.email.toLowerCase().indexOf(text) !== -1));
	}
	getUserLabel = (item) => {
		return (
			<div className="user" id={item.userid}>
				<img className="user-avatar" title="Photo" alt="profilePic" src="images/user1.png"/>
				<div className="user-wrap">
					<div className="user-names-wrap">
						<span className="user-name">{item.name} </span>
						<span className="user-nick">({item.nickname})</span>
					</div>
					<div className="user-email">{item.email}</div>
				</div>
			</div>)
	}
	usersChanged = (users, actionMeta) => {
		if (users == null) {
			this.setState({
				selectedUsers: []
			});
		}
		else {
			this.setState({
				selectedUsers: users
			});
		}
		/* TODO -oDM : Here should be synchronization of selected users and input  */
	}
	createNewUser = (value) => {
		var email = value.toLowerCase();
		var nickname = email.split('@')[0];
		var name = nickname.charAt(0).toUpperCase() + nickname.slice(1);
		return {
			userid:"new",
			email: email,
			name: name,
			nickname: nickname
		}
	}
	userCreate = (inputValue) => {
		var newUser = this.createNewUser(inputValue);
		var selectedUsers = [...this.state.selectedUsers, newUser];
		this.usersChanged(selectedUsers, 'select-option');
		this.setState({
			userslist:[...this.userslist, newUser]
		})
	}
	roleChanged = (inputValue) => {
		this.setState({
			selectedRole:inputValue
		})
	}
	doUnsubscribe =()=>{
		var userkey = this.userkey;
		var self=this;
		axios.post( ServiceURL + 'users_api/deleteUser', { 'userkey':userkey }).then(function (response) {
			if(response.data.status==="success"){
				self.doLogOut();
			}
		});
	}
	CreateNodeWithLink = (event) =>{
		var position = $(event.target).attr("data-position");
		var parent = $(event.target).attr("data-parent");
		var nodeName = '';
		this.createEmptyNode(position,parent, nodeName);
	}
	CreateSubNodeWithLink = (event) =>{
		var position = $(event.target).attr("data-position");
		var parent = $(event.target).attr("data-id");
		var nodeName = '';
		this.createEmptyNode(position,parent, nodeName);
	}
	MoveNodeUpWithLink = (event) =>{
		var CDTURL =  ServiceURL + "nodes_api/";
		var parentid = $(event.target).attr("data-parent");
		var dataid = $(event.target).attr("data-id");
		if(parseInt(parentid) !==0){
			var grandParent = $(".node-name[data-id="+ parentid+"]").attr("data-parent");
			var newPosition = parseInt($(".node-name[data-id="+ parentid+"]").attr("data-position") + 1);
			var nodes={
				parent:grandParent,
				position:newPosition,
				nodeid:dataid,
				userkey:this.userkey
			}
			this.findIdandChangeData(dataid,grandParent,'shift_tab',newPosition);
			axios.post(CDTURL + "update_parent", nodes);
		}
	}
	MoveNodeDownWithLink = (event) =>{
		var CDTURL =  ServiceURL + "nodes_api/";
		var dataid = $(event.target).attr("data-id");
		var position = $(event.target).attr("data-position");
		if(parseInt(position) !==0){
			var newParent = $(event.target).attr("data-prevsibling");
			var nodes={
				parent:newParent,
				nodeid:dataid,
				position:0,
				userkey:this.userkey
			}
			this.findIdandChangeData(dataid,newParent,'tab',0);
			axios.post(CDTURL + "update_parent", nodes);
		}
	}
	createNotification = (userkey,nodeid,notificationType) =>{
		var nodes = {
			userkey : userkey,
			nodeid :nodeid,
			type: notificationType
		}
		var CDTURL = ServiceURL + "notifications_api/createNotification";
		axios.post(CDTURL, nodes).then(response => {
			this.setState({
				notifications:response.data.notifications
			})
			
		});

	}
	render() {
		return (
			<LoadingOverlay active={this.loading} spinner={<RingLoader color={'#0000ff'} size="150" />}>
				<div id="editorPage">
					<div className={this.state.canvasShow?"sigCanvas":"sigCanvas hide"}>
						<button type="button" className="btn btn-default" onClick={this.closeCanvas}>Exit</button> &nbsp; &nbsp; &nbsp; &nbsp;
						<button type="button" className="btn btn-default" onClick={this.clearCanvas}>Clear</button>
						<SignatureCanvas ref={(ref) => { this.sigCanvas = ref }} canvasProps={{width: window.innerWidth, height: window.innerHeight}}  penColor="red" />
					</div>
					<div className="c_line-header">
						<div className="pull-left">
							<ul className="nc_ul_menu">
								<li className="nc_ul_list" onClick={ this.displayNotification('info')}> Node style : </li>
								<li className="nc_ul_list">
									<select className="form-control nodestyle" onChange={this.changeNodeStyle} >
										<option value="numCounter">1, 2, 3</option>
										<option value="numCounterDisc"> * </option>
										<option value="numCounterBrace">1), 2), 3)</option>
										<option value="numCounterLowerAlpha">a, b, c</option>
										<option value="numCounterUpperAlpha">A, B, C</option>
										<option value="numCounterUpperRoman">I, II, III</option>
									</select>
								</li>
							</ul>
						</div>
						<div className="pull-left">
							<ul className="nc_ul_menu">
								<li className="nc_ul_list">&nbsp;&nbsp;&nbsp;&nbsp;<Editoricons /> </li>
							</ul>
						</div>
						<div className="pull-right">
							<ul className="nc_ul_menu">{
								this.state.isLogged?"":<li className="nc_ul_list">
									<Link id="logIn" to="/login" className="btn btn-primary btn-bold">Log in</Link>
								</li>
							}
							{
								this.state.isLogged?"":<li className="nc_ul_list">
									<Link to="/signup" id="signUp" className="btn btn-warning btn-bold">Sign up</Link>
								</li>
							}
							<li id="search" className="nc_ul_list">
								<span className="nc_alerts" id="searchInactive" onClick={this.handleSearchInactive}>
									<img id="searchView" src="images/command-icon/search.png" alt="eye" width="24" />
								</span>
								<span id="searchActive" className={this.state.searchActiveShow?"":"hide"}>
									<div className="nc-fgroup">
										<div className="form-group">
											<div className="ip-group">
												<input type="text" id="searchLine"  className="form-control ip-input" placeholder="Search text here"/>
												<span className="iga-btn cls-danger" onClick={this.handleSearchActive} id="searchLineHide"><span className="glyphicon glyphicon-remove"></span></span>
											</div>
										</div>
									</div>
								</span>
							</li>

							{
							this.state.isLogged?	
							<li  className="nc_ul_list dropdown" id="notifications">
								<Dropdown id="dropdown-custom-menu">
									<Dropdown.Toggle bsRole="toggle">
										<img src="images/command-icon/bell.png" alt="notifications" width="32" />
										<span className="badge">{this.state.notifications.length}</span>
									</Dropdown.Toggle>
									<Dropdown.Menu  className="dropdown-menu settings-list" id="optionsPopup">
										{this.state.notifications.map((notification,i) =>{
										var menu = <MenuItem  id="settings" bsRole="item" href={"/#/home/" +  notification.notification_node +"/nodes"} key={i}>
											<button type="button" className="anchor-link set-anchor">
												<p className="node-text"><strong>{notification.notification_content}  </strong><br />&nbsp;<span className="n-sub-txt"> {notification.notification_time} </span></p>
											</button>
										</MenuItem>
										return menu;
									}) }
									</Dropdown.Menu>
								</Dropdown>

							</li>:""

							}
							<li className="nc_ul_list dropdown" id="options">
								<Dropdown id="dropdown-custom-menu">
									<Dropdown.Toggle bsRole="toggle">
										<img src="images/command-icon/settings.png" alt="settings" width="32" />
									</Dropdown.Toggle>
									<Dropdown.Menu  className="dropdown-menu settings-list" id="optionsPopup">
										<MenuItem  id="settings" bsRole="item" href="/#/Settings">
											<button type="button" className="anchor-link set-anchor">
												<img src="images/command-icon/settings.png" alt="settings" className="img-responsive" />
												<p className="node-text"><strong>Settings </strong><br />&nbsp;<span className="n-sub-txt">Your account and preferences</span></p>
											</button>
										</MenuItem>
										<MenuItem  id="upgrade">
											<button type="button" className="anchor-link set-anchor">
												<img src="images/command-icon/upgrade.png" alt="upgrade" className="img-responsive"/>
												<p className="node-text"><strong>Upgrade</strong><br />&nbsp;<span className="n-sub-txt">Migrate to unlimited plan</span></p>
											</button>
										</MenuItem>
										<MenuItem id="trial">
											<button type="button" className="anchor-link set-anchor">
												<img src="images/command-icon/need_time.png" alt="need_time" className="img-responsive"/>
												<p className="node-text">
													<strong>Need more time</strong><br />?&nbsp;
													<span className="n-sub-txt">
													<strong>2</strong> days of trail period left </span>
												</p>
											</button>
										</MenuItem>
											<MenuItem id="help">
												<button type="button" className="anchor-link set-anchor">
													<img src="images/command-icon/question.png" alt="question" className="img-responsive"/>
													<p className="node-text">
														<strong>Help</strong> <br />&nbsp;
														<span className="n-sub-txt">How-to, Tutorials, Support, Contacts</span>
													</p>
												</button>
											</MenuItem>
											{
												this.state.isLogged?<MenuItem className="nc_ul_list">
													<button id="logOut" type="button" className="anchor-link set-anchor redImg" onClick={this.doLogOut}>
														<img src="images/command-icon/logout.png" alt="question" className="img-responsive"/>
														<p className="node-text"><strong className="opt-name">Log out</strong><br />&nbsp;<span className="n-sub-txt opt-description">dmelent@medianet-home.de</span></p>
													</button>
												</MenuItem>: ""
											}
											{
												this.state.isLogged?<MenuItem className="nc_ul_list">
													<button id="unsubscribe" type="button" className="anchor-link set-anchor redImg" onClick={this.doUnsubscribe}>
														<img src="images/command-icon/unsubscribe.png" alt="question" className="img-responsive"/>
														<p className="node-text"><strong>Unsubscribe</strong><br />&nbsp;<span className="n-sub-txt">Delete my account and all my data</span></p>
													</button>
												</MenuItem>: ""
											}
										</Dropdown.Menu>
									</Dropdown>
								</li>
							</ul>
						</div>
						<div className="clearfix"></div>
					</div>
					<div id="breadcrumb">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/home/all/nodes">me</Link></li>
								{
									this.state.breadCrumb.map((item,i)=>{
										var breadli = <li key={i} className={parseInt(item.id) === parseInt(this.state.activenode)?"breadcrumb-item active":"breadcrumb-item"} aria-current="page">
															<Link to={"/home/"+ item.id +"/nodes"}>{item.name}</Link>
														</li>
										return breadli;
									})
								}
							</ol>
						</nav>
					</div>
					<div id="editor"> 
						{
							
							<div className="node user">
								<div className="node-info noselect">
									<span className="node-folder">
										<span className="fold-nodes noselect material-icons">expand_more</span>
									</span>
									<span className="user-full-name">{this.name!=='noname'? this.name : "ATTENTION: That is only sandbox to play! All data will be LOST!"}</span>
								</div>
								<div className="node-base">
									<span className="node-control">
										<span className="node-menu noselect material-icons">menu</span>
									</span>
									<span className="node-wrapper">
										<div className="node-header">
											<span className="node-body">
												<div className="node-content">
													<span className="node-data">
														<div className="node-name user-nick-name" contentEditable="true" readOnly={true} suppressContentEditableWarning={true} >{ this.nickname!=='nonickname'? '@'+this.nickname : "@DEMO"}</div>
														<div className="node-description hidden" contentEditable="true" readOnly={true} suppressContentEditableWarning={true} >{this.nickname !=='nonickname'?"Under that node you can create any own structure of sub-nodes":"You can do anything here without any restrictions, but do not store any important data here because it is only playground!"}</div>
													</span>
												</div>
											</span>
											<span className="node-state">
												<span className="fold-description noselect material-icons">more_horiz</span>
											</span>
										</div>
										<div className="nodes">
											{
												this.state.nodes.map((item,i)=>{
													previd = i===0?0:previd;
													var nextSibling = this.state.nodes[i+1]!==undefined ?this.state.nodes[i+1].id:"0";
													var nodeComp = 
														<Nodes
															key={item.id}
															position={i}
															id={this.state.nodes[i].id}
															nodeid={this.state.nodes[i].id}
															showNode={this.toggleNode}
															getSubnodes={this.getSubnodes}
															users={this.userslist}
															showcontent={this.state.nodes[i].showcontent?true:false}
															savename={this.saveNodeName}
															savedesc={this.saveNodeDesc}
															childsDisplayed={this.state.nodes[i].childsDisplayed}
															keycheckContent={this.keycheckContent}
															subnodes={this.state.nodes[i].subnodes}
															keycheck={this.keycheck}
															nextSibling={nextSibling}
															parent={this.state.nodes[i].parent}
															prevSibling={previd}
															contributors= {item.contributors? item.contributors: "No data found"}
															nodename={this.state.nodes[i].node_name}
															ncontent={this.state.nodes[i].content}
															userkey={this.userkey}
															createnode = {this.CreateNodeWithLink}
															createsubnode = {this.CreateSubNodeWithLink}
															movenodedown = {this.MoveNodeDownWithLink}
															movenodeup = {this.MoveNodeUpWithLink}
															Deletenode = {this.Deletenode}
															openuserPopup = {this.openuserPopup} />;
													previd = item.id;
													return nodeComp;
												})
											}
										</div>
									</span>
								</div>
							</div>
						}
						{
							this.state.aNodes.length > 0 ?
								<div className="node user">
									<div className="node-info noselect">
										<span className="node-folder">
											<span className="fold-nodes noselect material-icons">expand_more</span>
										</span>
										<span className="user-full-name">Everybody</span>
									</div>
									<div className="node-base">
										<span className="node-control">
											<span className="node-menu noselect material-icons hidden">menu</span>
										</span>
										<span className="node-wrapper">
											<div className="node-header">
												<span className="node-body">
													<div className="node-content">
														<span className="node-data">
															<div className="node-name user-nick-name" contentEditable="true"  suppressContentEditableWarning={true} readOnly={true} spellCheck="true">@Shared Nodes</div>
															<div className="node-description hidden" contentEditable="true"  suppressContentEditableWarning={true} readOnly={true} spellCheck="true">'You can do anything here without any restrictions, but do not store any important data here because it is only playground!</div>
														</span>
													</div>
												</span>
												<span className="node-state">
													<span className="fold-description noselect material-icons">more_horiz</span>
												</span>
											</div>
											<div className="nodes">
												{
													this.state.aNodes.map((item,i)=>{
														previd = i===0?0:previd;
														var nextSibling = this.state.aNodes[i+1]!==undefined ?this.state.aNodes[i+1].id:"0";
														var nodeComp = <Anodes
															 key={item.id}
															position={i}
															id={this.state.aNodes[i].id}
															nodeid={this.state.aNodes[i].id}
															showNode={this.toggleNode}
															getSubnodesA={this.getSubnodesA}
															users={this.userslist}
															showcontent={this.state.aNodes[i].showcontent?true:false}
															savename={ANF.saveNodeName}
															savedesc={ANF.saveNodeDesc}
															childsDisplayed={this.state.aNodes[i].childsDisplayed}
															keycheckContent={this.keycheckContent}
															subnodes={this.state.aNodes[i].subnodes}
															keycheck={this.keycheck}
															nextSibling={nextSibling}
															parent={this.state.aNodes[i].parent}
															prevSibling={previd}
															contributors= {item.contributors? item.contributors: "No data found"}
															nodename={this.state.aNodes[i].node_name}
															ncontent={this.state.aNodes[i].content}
															userkey={this.userkey} />;
														previd = item.id;
														return nodeComp;
													})
												}
											</div>
										</span>
									</div>
								</div>
							: ''
						}
						</div>
					<StyledPopover id="usersPopup" className="dropdown-menu settings-list"  style={this.state.usersStyle} onKeyDown={this.usersPopupKeyDown}>
						<Form id="userPopupForm" inline>
							<FormGroup className="users-popup-header">
								<Select id="usersRole" options={this.roles} placeholder="Roles" defaultValue={this.roles[0]} getOptionLabel={(role)=>role.name}
										getOptionValue={(role)=>role.id} onChange={this.roleChanged} value={this.state.selectedRole} />
								<InputGroup className="users-filter">
									<InputGroup.Addon className="users-at">@</InputGroup.Addon>
									<CreatableSelect
											closeMenuOnSelect={false}
											isClearable={false}
											filterOption={this.usersSearch}
											getOptionLabel={this.getUserLabel}
											getOptionValue={(user)=>user.email}
											id="usersFilter"
											isMulti={true}
											isSearchable={true}
											name="usersFilter"
											onChange={this.usersChanged}
											onCreateOption={this.userCreate}
											options={this.userslist}
											placeholder="Type user name or email here"
											value={this.state.selectedUsers}
										  />
								</InputGroup>
								<div id="usersPopupClose" onClick={this.closePopup} title="Close popup">
									<span className="glyphicon glyphicon-ok"></span>
								</div>
							</FormGroup>
						 </Form>
					</StyledPopover>
					<NotificationContainer />
				</div>				
			</LoadingOverlay>
		)
	}
}
export default Cdt;