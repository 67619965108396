import React,{Component} from "react";
import cookie from 'react-cookies'
import {Modal,Button} from "react-bootstrap";
import '../../common.css';
import '../body/pricing.css';

class BootTest extends Component {

	constructor(props){
		super(props);
		this.state = {
			showModel:false,
			userACC:cookie.load('userAcc')
		}

		console.log(cookie.load('userAcc'));
	}


	handleShow= () =>{
		this.setState({
			showModel:true
		});
	}

	closeShow= () =>{
		this.setState({
			showModel:false
		});
	}

	updateState = ()=> {
		this.setState({
			usrAcc: cookie.load('userAcc')
		})
		this.checkUserAccess();
	}

	setUserAccess = ()=>{
		var newCookie = "this is sivajiabcde";
		cookie.save('userAcc', newCookie, { path: '/' });
		this.setState({
			userACC:newCookie
		})
		this.updateState();
	}

	deleteUserAccess = ()=>{
		cookie.remove('userAcc',{path:'/'});
		this.setState({
			userACC:""
		})

		this.updateState();
	}

	checkUserAccess = ()=>{
		console.log(this.state.userACC);
	}
	compareAcc = ()=>{
		if(cookie.load('userAcc')){
			console.log("cookie is existing in the page");
		}
		else
		{
			console.log("cookie not present in the page");
		}
	}

	render(){
		return (
			<div className="static-modal">
				
				<button type="button" className="btn" onClick={this.setUserAccess}> clickme to set login </button>
				<br /><button className="btn" type="button" onClick={this.deleteUserAccess}> clickme to set logout </button>
				<br /><button className="btn"  type="button" onClick={this.checkUserAccess}> clickme to check session </button>
				<br /><button className="btn" type="button" onClick={this.compareAcc}> clickme to compare session </button>
				
				<Button bsStyle="success" className="sivaji" onClick={this.handleShow}> Launch model</Button>
				
				<Modal show={this.state.showModel}>
					<Modal.Header>
						<Modal.Title>Modal title</Modal.Title>
					</Modal.Header>
					<Modal.Body>One fine body...</Modal.Body>
					<Modal.Footer>
						<Button onClick={this.closeShow}>Close</Button>
						<Button bsStyle="primary">Save changes</Button>
					</Modal.Footer>
				</Modal>
			</div>
			);
	}

}

export default BootTest;