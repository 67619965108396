import React, { Component } from "react";
import axios from "axios";
import '../../editor/css/materialicon.css';
import ServiceURL from '../../../../constants/constants';
import $ from 'jquery';

class Attachments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			attachments:this.props.attachments,
			replyComment:0,
			attachmentsCount:this.props.attachmentsCount,
		}
		this.createNotification=this.props.createNotification;
	}
	componentDidMount = () => {
		/*var  CDTURL = ServiceURL + "attachments_api/attachments";
		var	nodes = {
				nodeid:this.props.nodeid
			}
			axios.post(CDTURL,nodes).then(response=>{
				this.setState({
					attachments:response.data.attachments,
					attachmentsCount:response.data.count
				})
				this.props.countAttachments(response.data.count);
			});*/

    }
    fileupload =(event)=>{
    	var CDTURL = ServiceURL + "attachments_api/upload?node_id="+this.props.nodeid + "&key="+this.props.userkey;
    	var formData,filename;
    	var imagefile = document.querySelector('#file'+this.props.nodeid);

    		console.log(imagefile);
    	
    	Object.entries(imagefile.files).map((singleFile,i)=>{

    		filename = singleFile[1].name;
	    	formData = new FormData();
	    	formData.append("files", singleFile[1]);
	    	axios.post(CDTURL, formData, {
	        headers: {
	          'Content-Type': 'multipart/form-data'
	        }}).then(response=>{
				this.setState({
					attachments:response.data.attachments.attachments
				})
				console.log("upload attachments", response.data.attachments.attachments)
				this.props.countAttachments(response.data.attachments.count);
			});
    		this.props.createNotification(this.props.userkey,this.props.nodeid,6,filename);
			return true;
    	 })


    }
    removeAttachment = (event)=>{

    	var attachid = $(event.target).attr("data-attachid");
    	var encname = $(event.target).attr("data-encname");
    	var filename = $(event.target).attr("data-realname");
    	var CDTURL = ServiceURL + "attachments_api/removeAttachment";
    	var	nodes = {
    		nodeid:this.props.nodeid,
    		attachmentId : attachid,
    		encname : encname
    	}
    	axios.post(CDTURL,nodes).then(response=>{

    		this.setState({
					attachments:response.data.attachments.attachments
				})
				console.log("remove attachments", response.data.attachments.attachments)
			this.props.countAttachments(response.data.attachments.count);
    	})
    	this.props.createNotification(this.props.userkey,this.props.nodeid,7,filename);
    }

    render() {
        return (
        		<div>
        			<div className="row" unselectable="on">
					{
						this.state.attachments!== undefined && this.state.attachments.length > 0 ? this.state.attachments.map((attachment,i) =>{
							var attachmentData = <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 attachment" key={i} unselectable="on"> 
								<div unselectable="on">
									<a href={"http://demo.24qr.de/uploads/" + attachment.file_encname} target="__blank" download >
										<img src={"mimetypes/"+ attachment.file_type.replace("/","-") + ".png"} alt={ attachment.file_type.replace("/","-") }  />
									</a> 
								</div>
								<div className="attach-fileName"> {attachment.file_realname} </div>
								<div className="attach-fileLink">
									<button className="button-link"  unselectable="on" onClick={this.props.createLink} data-link={"http://demo.24qr.de/uploads/"+attachment.file_encname}> Use this file as link </button>
									</div>
								<div className={(attachment.file_type==="image/jpeg" || attachment.file_type==="image/png" || attachment.file_type==="image/svg+xml")? "attach-fileLink":""} >
									<button className="button-link"  unselectable="on" onClick={this.props.insertImage} data-link={"http://demo.24qr.de/uploads/"+attachment.file_encname}> {(attachment.file_type==="image/jpeg" || attachment.file_type==="image/png" || attachment.file_type==="image/svg+xml")? "Insert image":""} </button>
								</div>
								<div className="attach-fileName" > Uploaded on : {attachment.upload_time} </div>
								<div onClick={this.removeAttachment} className="removeAttach" data-encname={attachment.file_encname} data-realname={attachment.file_realname} data-attachid={attachment.attach_id}> Remove attachment </div>
								</div>;
							return attachmentData;
						}): <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2"> No Attachments Found</div>
					}
					</div>
					<br />
					<div className="row center">	
						<label className="btn btn-success btn-lg">
							Upload files
							<input type="file" className="hidden" name={"file"+this.props.nodeid} id={"file"+this.props.nodeid} onChange={this.fileupload} multiple />	
						</label>
					</div>
				</div>
		);
    }
}
export default Attachments;